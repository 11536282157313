import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import log from 'loglevel';
import { todoBoardApi } from '../index';
import { API } from '../api_routes';
import { history } from '../../../navigation/Routing';
import { RootState } from '../../store';
import { User } from '../../../types/user';

export const authenticationApi = todoBoardApi.injectEndpoints({
  endpoints: (build) => ({
    getAccessToken: build.query<string, { oneTimeToken: string }>({
      async queryFn({ oneTimeToken }, queryApi, extraOptions) {
        const state = queryApi.getState() as RootState;
        const { host: apiHost } = state.application.api;

        const tokenBaseQuery = fetchBaseQuery({
          baseUrl: apiHost,
          prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${oneTimeToken}`);
            return headers;
          },
        });
        const { data, error, meta } = await tokenBaseQuery(
          {
            url: API.ACCESS_TOKEN,
            responseHandler: (response) => response.text(),
          },
          queryApi,
          extraOptions
        );
        if (meta?.response?.url.includes('session_missing')) {
          history.replace('/error/token-expired');
          log.error(
            `Couldn't receive access token for the one-time token!`,
            `Failed on request to ${meta?.response?.url}`
          );
        }
        if (error) throw error;
        return { data: data as string };
      },
    }),
    getCurrentUser: build.query<User, void>({
      query: () => ({ url: API.CURRENT_USER_INFO }),
      transformResponse: (response: unknown) => (response as { user: User }).user,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAccessTokenQuery, useGetCurrentUserQuery } = authenticationApi;
