import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '../app/useAppSelector';
import { useGetCourseByIdQuery } from '../../queries/courses';
import { selectCourseId } from '../../selectors/application';

export const useAcademicTerm = () => {
  const courseId = useAppSelector(selectCourseId);
  return useGetCourseByIdQuery(!courseId ? skipToken : courseId, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.academic_term,
    }),
  });
};
