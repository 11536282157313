import jwtDecode, { JwtDecodeOptions } from 'jwt-decode';
import { useMemo } from 'react';

export const useJwtTokenPayload = <T = unknown>(
  token: string,
  options?: JwtDecodeOptions
): T | undefined =>
  useMemo(() => {
    try {
      return jwtDecode<T>(token, options);
    } catch (parsingError) {
      console.error(`Cannot parse JWT token:`, (parsingError as { message: string }).message);
      return undefined;
    }
  }, [options, token]);
