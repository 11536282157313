import { FC } from 'react';
import Note from '../../../../components/Board/Note';
import NoteHeader from '../../../../components/Board/Note/NoteHeader';
import NoteParagraph from '../../../../components/Board/Note/NoteParagraph';

const NewWorkNote: FC = () => {
  return (
    <Note>
      <NoteHeader>Saving students&apos; need for a feedback</NoteHeader>
      <NoteParagraph>
        New works from your students on open-ended questions that would benefit from your
        review and comment.
      </NoteParagraph>
      <NoteParagraph>
        <strong>Review works</strong> to complete this task
      </NoteParagraph>
    </Note>
  );
};

export default NewWorkNote;
