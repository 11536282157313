import { useCallback, useMemo } from 'react';
import pluralize from 'pluralize';
import { NotificationData, PopupNotification } from './index';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { selectCourseId } from '../../../redux/selectors/application';

const getBaseMessagesNotificationData = (courseId: number) => ({
  link: `/courses/${courseId}/#`,
  title: 'New Messages',
  message: `${pluralize('student', 0, true)} have replied to you`,
  isHighlighted: false,
  isAllResolved: false,
  isFetching: false,
});

export const useMessagesNotification = (): PopupNotification => {
  const courseId = useAppSelector(selectCourseId);

  const notification = useMemo<NotificationData | null>(() => {
    const studentsAmount = 0;
    const notificationData = { ...getBaseMessagesNotificationData(courseId) };

    if (studentsAmount) {
      notificationData.message = `${pluralize(
        'student',
        studentsAmount,
        true
      )} have replied to you`;
      return notificationData;
    }

    notificationData.message = `No new messages from students for now`;
    notificationData.isAllResolved = true;
    return notificationData;
  }, [courseId]);

  const handleReset = useCallback(() => {
    console.log('Resetting messages!');
  }, []);

  return [notification, handleReset];
};
