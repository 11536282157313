import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '../app/useAppSelector';
import { selectCourseId } from '../../selectors/application';
import { useCourseSnapshotsDatesIndices } from './useCourseSnapshotsDatesIndices';
import { useAppDispatch } from '../app/useAppDispatch';
import {
  selectIndexedSnapshotsIdsInRange,
  selectSnapshotsInRange,
  selectSnapshotsRequestState,
} from '../../selectors/risks_snapshots';
import { getCourseSnapshotsByIdsThunk } from '../../thunks/risks_snapshots';
import { useEnrollments } from '../courses/useEnrollments';

export const useCourseSnapshots = () => {
  const dispatch = useAppDispatch();

  const courseId = useAppSelector(selectCourseId);
  const snapshotsIdsInRange = useAppSelector(selectIndexedSnapshotsIdsInRange);

  const snapshots = useAppSelector(selectSnapshotsInRange);
  const { isLoading, isFetching, isUninitialized, error } = useAppSelector(
    selectSnapshotsRequestState
  );

  const { data: snapshotsDatedIndices, isFetching: isSnapshotsDatedIndicesFetching } =
    useCourseSnapshotsDatesIndices();
  const isSnapshotsDatedIndicesReady =
    snapshotsDatedIndices && !isSnapshotsDatedIndicesFetching;

  const { data: enrollments, isFetching: isEnrollmentsFetching } = useEnrollments();
  const isEnrollmentsReady = enrollments && !isEnrollmentsFetching;

  useEffect(() => {
    dispatch(
      getCourseSnapshotsByIdsThunk(
        isSnapshotsDatedIndicesReady && isEnrollmentsReady
          ? {
              courseId,
              snapshotsIds: snapshotsIdsInRange,
            }
          : skipToken
      )
    );
  }, [
    courseId,
    dispatch,
    isEnrollmentsReady,
    isSnapshotsDatedIndicesReady,
    snapshotsIdsInRange,
  ]);

  return {
    isLoading,
    isFetching,
    isUninitialized,
    data: snapshots,
    error,
  };
};
