import { createSelector, EntityState } from '@reduxjs/toolkit';
import { ShortCourse } from '../../../types';
import { RootState } from '../../store';
import { coursesApi } from '../../queries/courses';
import {
  currentUserCoursesAdapter,
  currentUserCoursesInitialState,
} from '../../entities_adapters/courses';

export const selectCurrentUserCoursesResult = createSelector<RootState, void, any>([], () =>
  coursesApi.endpoints.getCurrentUserCourses.select()
);

const selectUsersData = createSelector<
  RootState,
  { data?: EntityState<ShortCourse> },
  EntityState<ShortCourse> | undefined
>(
  [selectCurrentUserCoursesResult],
  (currentUserCoursesResult) => currentUserCoursesResult.data
);

export const {
  selectAll: selectAllCurrentUserCourses,
  selectById: selectCurrentUserCourseById,
  selectIds: selectCurrentUserCoursesIds,
} = currentUserCoursesAdapter.getSelectors(
  (state: RootState) => selectUsersData(state) || currentUserCoursesInitialState
);
