import { FC } from 'react';
import clsx from 'clsx';
import styles from './note.module.scss';

interface NoteProps {
  className?: string;
}

const Note: FC<NoteProps> = ({ className, children }) => {
  return <article className={clsx(className, styles.Note)}>{children}</article>;
};

export default Note;
