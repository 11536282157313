import { FC, ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';
import { getRandomInt } from '../../utils/random';

export interface ContentSkeletonProps {
  count?: number;
  className?: string;
  elementClassName?: string;
}

const generateSkeletonLines = ({
  count,
  elementClassName,
}: {
  count: number;
  elementClassName?: string;
}): ReactElement[] => {
  const minWidthPercent = 55;
  const maxWidthPercent = 95;

  const lines: ReactElement[] = [];
  for (let i = 0; i < count; i++) {
    const widthPercent = getRandomInt(minWidthPercent, maxWidthPercent);
    lines.push(<Skeleton key={i} width={`${widthPercent}%`} className={elementClassName} />);
  }
  return lines;
};

const ContentSkeleton: FC<ContentSkeletonProps> = ({
  count = 9,
  className,
  elementClassName,
}) => {
  const skeletonLines = useMemo(
    () => generateSkeletonLines({ count, elementClassName }),
    [count, elementClassName]
  );

  return (
    <div className={clsx(className, styles.ContentSkeleton)}>
      {skeletonLines.map((line) => line)}
    </div>
  );
};

export default ContentSkeleton;
