import { AppThunk } from '../../store';
import { Message } from '../../../types/message';
import { removeMessage, saveMessage } from '../../slices/messages';
import { selectCourseId, selectCoursePivotDate } from '../../selectors/application';

export const sendMessageThunk =
  ({ message }: { message: Omit<Message, 'id' | 'sentAt'> }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const courseId = selectCourseId(state);
    const pivotDate = selectCoursePivotDate(state);
    dispatch(saveMessage({ courseId, message: { ...message, sentAt: pivotDate } }));
  };

export const unsendMessageThunk =
  ({ messageId }: { messageId: number }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const courseId = selectCourseId(state);
    dispatch(removeMessage({ courseId, messageId }));
  };
