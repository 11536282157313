import { FC } from 'react';
import clsx from 'clsx';
import { FaTimes } from 'react-icons/fa';
import { BindingAction } from '../../../../types';
import ActionButton from '../../../Buttons/ActionButton';
import styles from './styles.module.scss';

interface NoteHeaderProps {
  onClose?: BindingAction;
  className?: string;
}

const NoteHeader: FC<NoteHeaderProps> = ({ onClose, className, children }) => (
  <div className={clsx(className, styles.NoteHeader)}>
    <h4>{children}</h4>
    {onClose && (
      <ActionButton onClick={onClose} className={styles.NoteHeader__Close}>
        <FaTimes />
      </ActionButton>
    )}
  </div>
);

export default NoteHeader;
