import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '../app/useAppSelector';
import { useGetSnapshotsDatesIndicesByCourseIdQuery } from '../../queries/risks_snapshots';
import { useAcademicTerm } from '../courses/useAcademicTerm';
import { selectCourseId } from '../../selectors/application';

export const useCourseSnapshotsDatesIndices = () => {
  const courseId = useAppSelector(selectCourseId);

  const { data: academicTerm, isFetching: isAcademicTermFetching } = useAcademicTerm();
  const isAcademicTermReady = academicTerm && !isAcademicTermFetching;

  return useGetSnapshotsDatesIndicesByCourseIdQuery(
    isAcademicTermReady ? courseId : skipToken
  );
};
