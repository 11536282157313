import { FC, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import clsx from 'clsx';
import { BindingCallback1 } from '../../../types';
import styles from './styles.module.scss';

type InputGroupProps = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    placeholder?: string;
    labelPosition?: 'left' | 'top';
    align?: 'start' | 'center';
    leftAddon?: string;
    rightAddon?: string;
    textarea?: boolean;
    inputClassName?: string;
    onTextChange?: BindingCallback1<string>;
  };

const InputGroup: FC<InputGroupProps> = ({
  label,
  labelPosition = 'top',
  align = 'center',
  leftAddon,
  rightAddon,
  textarea,
  className,
  inputClassName,
  value = '',
  rows = 6,
  onTextChange,
  ...inputProps
}) => {
  return (
    <div
      className={clsx(
        className,
        styles.InputGroup,
        align === 'start' && styles.InputGroup_labelStart,
        align === 'center' && styles.InputGroup_labelCenter
      )}
    >
      {label && (
        <label
          htmlFor={label}
          className={clsx(
            styles.InputGroup__Label,
            labelPosition === 'left' && styles.InputGroup__Label_left,
            labelPosition === 'top' && styles.InputGroup__Label_top
          )}
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          styles.InputGroup__Input,
          labelPosition === 'top' && styles.InputGroup__Input_wide
        )}
      >
        {leftAddon && <span>{leftAddon}</span>}
        {textarea ? (
          <textarea
            {...inputProps}
            spellCheck="true"
            value={value}
            rows={rows}
            className={inputClassName}
            onChange={(e) => {
              inputProps.onChange?.(e);
              onTextChange?.(e.target.value);
            }}
          />
        ) : (
          <input
            {...inputProps}
            value={value}
            spellCheck="true"
            className={inputClassName}
            onChange={(e) => {
              inputProps.onChange?.(e);
              onTextChange?.(e.target.value);
            }}
          />
        )}
        {rightAddon && <span>{rightAddon}</span>}
      </div>
    </div>
  );
};

export default InputGroup;
