import { TableInstance } from 'react-table';
import { ColumnInstanceWithRowSpan } from '../../../types/react-table-config';

// Hook, which prepares columns for the rowSpan
export const useRowSpan = <D extends Record<string, unknown> = Record<string, unknown>>(
  instance: TableInstance<D>
): void => {
  const allColumns = instance.allColumns as ColumnInstanceWithRowSpan<D>[];
  allColumns.forEach((column) => {
    if (column.enableRowSpan) {
      column.topCellValue = null;
      column.topCellIndex = 0;
    }
  });
};
