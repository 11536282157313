import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { FaChevronDown, FaChevronUp, FaCogs, FaFilter, FaSortAmountUp } from 'react-icons/fa';
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from 'react-icons/md';
import { IoRadioOutline } from 'react-icons/io5';
import { VscHistory } from 'react-icons/vsc';
import {
  BindingCallback1,
  AssessmentUser,
  MeasurementDimensionKeys,
  RiskCategoryKeys,
} from '../../../../../../types';
import {
  formatDateTogglingCell,
  formatDimensionCell,
  FormattingOptions,
} from './assessments_table_cells_formatter';
import { createSelectionColumn } from '../../../../../../components/ReactTable/hooks/createSelectionColumn';
import Tooltip from '../../../../../../components/Tooltip';
import styles from './styles.module.scss';

interface AssessmentsTableProps {
  pivotDate: Date;
  riskCategory: RiskCategoryKeys;
  rowsData: AssessmentsColumns[];
  onUsersSelection: BindingCallback1<number[]>;
}

export interface AssessmentsColumns {
  user: AssessmentUser;
  dimensionsValues: DimensionsValuesIndex;
}

export type DimensionsValuesIndex = {
  [dimension in MeasurementDimensionKeys]: DimensionValues;
};

export interface DimensionValues {
  snapshot?: string;
  live?: string;
}

type DimensionColumnTypes = keyof DimensionValues;
export type DimensionColumnId = `${MeasurementDimensionKeys}_${DimensionColumnTypes}`;

const AssessmentsTable: FC<AssessmentsTableProps> = ({
  pivotDate,
  riskCategory,
  rowsData,
  onUsersSelection,
}) => {
  const [formattingOptions, setFormattingOptions] = useState<FormattingOptions>({});

  const updateDateFormatting = useCallback(
    (dimension: string, isAbsoluteFormatting: boolean) =>
      setFormattingOptions((prevOptions) => ({
        ...prevOptions,
        [dimension]: { isAbsoluteFormatting },
      })),
    []
  );

  const columns: Column<AssessmentsColumns>[] = useMemo(() => {
    const getColumnIdProps = (
      columnId: DimensionColumnId
    ): { dimension: MeasurementDimensionKeys; columnType: DimensionColumnTypes } => {
      const [dimension, columnType] = columnId.split('_');
      return {
        dimension: dimension as MeasurementDimensionKeys,
        columnType: columnType as DimensionColumnTypes,
      };
    };

    const getCellValue = (
      dimension: MeasurementDimensionKeys,
      columnType: DimensionColumnTypes,
      dimensionsValues: DimensionsValuesIndex
    ): string | undefined => dimensionsValues[dimension][columnType];

    const getFormattedCellValue = (
      dimension: MeasurementDimensionKeys,
      columnType: DimensionColumnTypes,
      dimensionsValues: DimensionsValuesIndex
    ): ReactElement | string => {
      const cellValue = getCellValue(dimension, columnType, dimensionsValues);
      return formatDimensionCell({
        dimension,
        value: cellValue,
        riskCategory,
        options: formattingOptions,
        pivotDate,
      });
    };

    const createDimensionColumn = (
      columnId: DimensionColumnId
    ): Column<AssessmentsColumns> => {
      const { dimension, columnType } = getColumnIdProps(columnId);
      const header =
        columnType === 'snapshot' ? (
          <VscHistory />
        ) : (
          <span className={styles.AssessmentsTable__Header_live}>
            <IoRadioOutline />
          </span>
        );
      return {
        id: columnId,
        Header: () => header,
        accessor: (row) => getCellValue(dimension, columnType, row.dimensionsValues),
        Cell: ({ cell }: { cell: Cell<AssessmentsColumns> }) =>
          getFormattedCellValue(dimension, columnType, cell.row.original.dimensionsValues),
      };
    };

    return [
      {
        id: 'student',
        accessor: (row) => `${row.user.first_name} ${row.user.last_name}`,
        Header: 'Student',
        Cell: ({ cell }: { cell: Cell<AssessmentsColumns> }) => {
          const {
            user: { first_name, last_name },
          } = cell.row.original;
          return `${first_name} ${last_name}`;
        },
      },
      {
        id: 'completion-achieved',
        Header: 'Complete',
        columns: [
          createDimensionColumn('completion-achieved_snapshot'),
          createDimensionColumn('completion-achieved_live'),
        ],
      },
      {
        id: 'score-achieved',
        Header: 'Score',
        columns: [
          createDimensionColumn('score-achieved_snapshot'),
          createDimensionColumn('score-achieved_live'),
        ],
      },
      {
        id: 'time-spent',
        Header: 'Time',
        columns: [
          createDimensionColumn('time-spent_snapshot'),
          createDimensionColumn('time-spent_live'),
        ],
      },
      {
        id: 'last-signin',
        Header: () =>
          formatDateTogglingCell({
            title: 'Last Sign In',
            dimension: 'last-signin',
            options: formattingOptions,
            onToggle: (isAbsoluteFormatting) =>
              updateDateFormatting('last-signin', isAbsoluteFormatting),
          }),
        columns: [
          createDimensionColumn('last-signin_snapshot'),
          createDimensionColumn('last-signin_live'),
        ],
      },
      {
        id: 'last-contact',
        Header: () =>
          formatDateTogglingCell({
            title: 'Last Contact',
            dimension: 'last-contact',
            options: formattingOptions,
            onToggle: (isAbsoluteFormatting) =>
              updateDateFormatting('last-contact', isAbsoluteFormatting),
          }),
        columns: [createDimensionColumn('last-contact_live')],
      },
    ];
  }, [formattingOptions, pivotDate, riskCategory, updateDateFormatting]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable<AssessmentsColumns>(
    {
      columns,
      data: rowsData,
    },
    useSortBy,
    useRowSelect,
    (hooks) =>
      createSelectionColumn({
        hooks,
        toggledIcon: <MdOutlineCheckBox />,
        indeterminateIcon: <MdOutlineIndeterminateCheckBox />,
        untoggledIcon: <MdOutlineCheckBoxOutlineBlank />,
        className: styles.AssessmentsTable__Select,
      })
  );

  const lastSelectionAmount = useRef<number>(0);

  useEffect(() => {
    const selectedUsersId = selectedFlatRows.map((row) => row.original.user.id);
    if (selectedUsersId.length === lastSelectionAmount.current) return;

    lastSelectionAmount.current = selectedUsersId.length;
    onUsersSelection(selectedUsersId);
  }, [onUsersSelection, selectedFlatRows]);

  const renderHeaderRow = useCallback((headerGroup: HeaderGroup<AssessmentsColumns>) => {
    return (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            <div className={styles.AssessmentsTable__Header}>
              {column.render('Header')}
              {column.isSorted && (
                <>{column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />}</>
              )}
            </div>
          </th>
        ))}
      </tr>
    );
  }, []);

  const renderBodyRow = useCallback(
    (row: Row<AssessmentsColumns>) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ))}
        </tr>
      );
    },
    [prepareRow]
  );

  return (
    <div className={styles.AssessmentsTable}>
      <div className={styles.AssessmentsTable__Tools}>
        <Tooltip
          trigger={
            <>
              <FaSortAmountUp /> Sort
            </>
          }
          triggerClassName={styles.AssessmentsTable__ToolsTrigger}
          content={
            <>
              Click on the <strong>snapshot</strong> column icon <VscHistory /> or <br />{' '}
              <strong>live</strong> data column icon
              <IoRadioOutline />
            </>
          }
        />
        <Tooltip
          trigger={
            <>
              <FaFilter /> Filter
            </>
          }
          triggerClassName={styles.AssessmentsTable__ToolsTrigger}
          content={
            <>
              <FaCogs /> Work in progress
            </>
          }
        />
      </div>
      <table {...getTableProps()}>
        <thead>{headerGroups.map(renderHeaderRow)}</thead>
        <tbody {...getTableBodyProps()}>{rows.map(renderBodyRow)}</tbody>
      </table>
    </div>
  );
};

export default AssessmentsTable;
