import {
  convertToDate,
  getDateWithoutTime,
  StringDate,
  WEEK_DAYS_AMOUNT,
} from './dates_comparer';

const roundTimeDifference = (difference: number): number =>
  difference < 0 ? Math.ceil(difference) : Math.floor(difference);

/**
 * Return new date, which will be in daysAmount from the provided date
 * @param date - start date, from which the diff will be calculated
 * @param daysAmount - amount of days to add (if positive) or extract (if negative)
 */
export const getDateInDays = (date: StringDate, daysAmount: number): Date => {
  const dateInDays = new Date(date);
  dateInDays.setDate(dateInDays.getDate() + daysAmount);
  return dateInDays;
};

export const getDateInWeeks = (date: StringDate, weeksAmount: number): Date =>
  getDateInDays(date, weeksAmount * WEEK_DAYS_AMOUNT);

export const getMinutesBetweenDates = (dateA: StringDate, dateB: StringDate): number => {
  const millisecondsPerMinute = 1000 * 60;
  const utc1 = convertToDate(dateA).getTime();
  const utc2 = convertToDate(dateB).getTime();
  const minutesBetween = Math.floor((utc2 - utc1) / millisecondsPerMinute);
  return roundTimeDifference(minutesBetween);
};

export const getHoursBetweenDates = (dateA: StringDate, dateB: StringDate): number => {
  const minutesBetween = getMinutesBetweenDates(dateA, dateB) / 60;
  return roundTimeDifference(minutesBetween);
};

export const getDaysAmountBetweenDates = (dateA: StringDate, dateB: StringDate): number => {
  const dateWithoutTimeA = getDateWithoutTime(dateA);
  const dateWithoutTimeB = getDateWithoutTime(dateB);
  return Math.floor(getHoursBetweenDates(dateWithoutTimeA, dateWithoutTimeB) / 24);
};

export const getDaysAmountBetweenDatesInclusively = (
  dateA: StringDate,
  dateB: StringDate
): number => {
  const dateWithoutTimeA = getDateWithoutTime(dateA);
  const dateWithoutTimeB = getDateWithoutTime(dateB);
  const extendedDateB = getDateInDays(dateWithoutTimeB, 1);
  return Math.floor(getHoursBetweenDates(dateWithoutTimeA, extendedDateB) / 24);
};

export const getWeeksBetweenDates = (dateA: StringDate, dateB: StringDate): number =>
  Math.floor(getDaysAmountBetweenDates(dateA, dateB) / WEEK_DAYS_AMOUNT);

export const getWeeksBetweenDatesInclusively = (
  dateA: StringDate,
  dateB: StringDate
): number => Math.floor(getDaysAmountBetweenDatesInclusively(dateA, dateB) / WEEK_DAYS_AMOUNT);

/**
 * Creates array of Dates with step between adjacent dates.
 * If the last step date is larger than the end date, pick the end date.
 */
export const getDatesInRange = (start: StringDate, end: StringDate, step = 1): Date[] => {
  const startWithoutTime = getDateWithoutTime(start);
  const endWithoutTime = getDateWithoutTime(end);

  if (startWithoutTime.getTime() === endWithoutTime.getTime()) {
    return [startWithoutTime];
  }

  const datesInRange: Date[] = [];
  let iterableDate = new Date(startWithoutTime);

  for (;;) {
    datesInRange.push(iterableDate);
    const newDate = getDateInDays(iterableDate, step);
    if (newDate >= endWithoutTime) {
      datesInRange.push(endWithoutTime);
      break;
    }
    iterableDate = newDate;
  }
  return datesInRange;
};

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export const getDateWeek = (checkDate: StringDate): number => {
  // Copy date so don't modify original
  const date = getDateWithoutTime(checkDate);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  return Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};

export const checkDateInRange = (
  date: StringDate,
  rangeStart: StringDate,
  rangeEnd: StringDate
): boolean => {
  const comparisonDate = convertToDate(date);
  return (
    comparisonDate >= convertToDate(rangeStart) && comparisonDate <= convertToDate(rangeEnd)
  );
};
