import { Course } from '../../../types';

export const transformCourse = (response: unknown): Course => {
  const responseCourse = (response as { course: unknown }).course;
  const course = responseCourse as Course;

  // TODO REMOVE, JUST FOR THE TESTING PURPOSES
  // course.academicTerm = camelizeObject(testAcademicTerm) as AcademicTerm;

  return course;
};
