import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface BoardHeaderProps {
  backwardsLink?: string;
  header: string;
  className?: string;
}

const BoardHeader: FC<BoardHeaderProps> = ({ backwardsLink, header, className }) => {
  return (
    <div className={clsx(className, styles.BoardHeader)}>
      {backwardsLink && (
        <Link to={backwardsLink}>
          <IoMdArrowBack />
        </Link>
      )}
      <h1>{header}</h1>
    </div>
  );
};

export default BoardHeader;
