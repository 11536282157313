import { EntityState } from '@reduxjs/toolkit';
import { ShortCourse } from '../../../types';
import {
  currentUserCoursesAdapter,
  currentUserCoursesInitialState,
} from '../../entities_adapters/courses';

export const transformCurrentUserCourses = (response: unknown): EntityState<ShortCourse> => {
  const responseCourses = (response as { courses: unknown }).courses;
  const currentUserCourses = responseCourses as ShortCourse[];
  return currentUserCoursesAdapter.setAll(currentUserCoursesInitialState, currentUserCourses);
};
