import { createEntityAdapter } from '@reduxjs/toolkit';
import { RisksSnapshot, RisksSnapshotDatesIndex } from '../../../types';
import { sortDatetimeIncrease } from '../../../utils/dates_comparer';

export const risksSnapshotsDatesIndicesAdapter = createEntityAdapter<RisksSnapshotDatesIndex>({
  sortComparer: (snapshotA, snapshotB) =>
    sortDatetimeIncrease(snapshotA.snapshot_ended_at, snapshotB.snapshot_ended_at),
});
export const risksSnapshotsDatesIndicesInitialState =
  risksSnapshotsDatesIndicesAdapter.getInitialState();

export const risksSnapshotsAdapter = createEntityAdapter<RisksSnapshot>({
  sortComparer: (snapshotA, snapshotB) => snapshotA.week - snapshotB.week,
});
export const risksSnapshotsInitialState = risksSnapshotsAdapter.getInitialState({
  requestedSnapshotsIds: [] as number[],
  isFetching: false,
  isLoading: false,
  isUninitialized: true,
});
