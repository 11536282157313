import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { RiskCategoryKeys } from '../../../types';
import { RiskUi, RiskUiState } from '../../slices/risks_ui';

export const selectUiByRisk = createSelector<
  RootState,
  RiskCategoryKeys,
  RiskUiState,
  RiskCategoryKeys,
  RiskUi
>(
  [(state) => state.risksUi, (state, riskCategory) => riskCategory],
  (risksUi, riskCategory) => risksUi[riskCategory]
);
