import { AcademicTerm, RisksSnapshotDatesIndex } from '../../../types';
import { getAcademicTermFirstLastClassDates } from '../academic_terms';
import { getDateWeek } from '../../../utils/dates_ranges_helper';

interface WeeklySnapshotsIndex {
  [week: number]: {
    id: number;
    week: number;
    snapshotEndedAt: string;
  };
}

export const createWeeklySnapshotsIndex = (
  academicTerm: AcademicTerm,
  snapshotsDatesIndices: RisksSnapshotDatesIndex[]
): WeeklySnapshotsIndex => {
  const { firstClassDate } = getAcademicTermFirstLastClassDates(academicTerm);
  const firstClassDateWeek = getDateWeek(firstClassDate);
  return snapshotsDatesIndices.reduce((weeklyIndex, snapshotIndex) => {
    const { id: snapshotId, snapshot_ended_at } = snapshotIndex;
    const snapshotEndedAtWeek = getDateWeek(snapshot_ended_at);
    const firstClassDateDiff = snapshotEndedAtWeek - firstClassDateWeek;

    /**
     * Snapshots weeks should be assigned relative to the firstClassDate.
     * Snapshot always cover a PRIOR WEEK. But we cannot have WEEK 0
     * The weeks distribution should be [-n..-1, 1, 2..n]
     */
    let snapshotCoveredWeek = firstClassDateDiff - 1;
    if (snapshotCoveredWeek >= 0) snapshotCoveredWeek += 1;

    /**
     * Add only the latest snapshots for the week to the index
     */
    const previousWeekIndex = weeklyIndex[snapshotCoveredWeek];
    if (previousWeekIndex?.snapshotEndedAt > snapshot_ended_at) return weeklyIndex;

    weeklyIndex[snapshotCoveredWeek] = {
      id: snapshotId,
      week: snapshotCoveredWeek,
      snapshotEndedAt: snapshot_ended_at,
    };
    return weeklyIndex;
  }, {} as WeeklySnapshotsIndex);
};
