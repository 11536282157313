import { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { BindingCallback1 } from '../../../types';
import styles from './styles.module.scss';

interface IconCheckboxProps {
  value?: boolean;
  toggledIcon: ReactElement;
  untoggledIcon: ReactElement;
  onToggle?: BindingCallback1<boolean>;
  stopPropagation?: boolean;
  className?: string;
  inputClassName?: string;
}

const IconCheckbox: FC<IconCheckboxProps> = ({
  value,
  toggledIcon,
  untoggledIcon,
  onToggle,
  stopPropagation,
  className,
  inputClassName,
}) => (
  <div className={clsx(className, styles.IconCheckbox)}>
    <input
      type="checkbox"
      value={value?.toString()}
      onChange={() => onToggle?.(!value)}
      onClick={(event) => {
        if (!stopPropagation) return;
        event.stopPropagation();
      }}
      className={clsx(inputClassName, styles.IconCheckbox__Input)}
    />
    {value ? toggledIcon : untoggledIcon}
  </div>
);

export default IconCheckbox;
