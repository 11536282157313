import { FC, ReactElement } from 'react';
import Loader from 'react-loader-spinner';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface ScreenLoaderProps {
  title?: string | ReactElement;
  className?: string;
}

const ScreenLoader: FC<ScreenLoaderProps> = ({ title, className }) => {
  return (
    <div className={clsx(className, styles.ScreenLoader)}>
      {title && <h3 className={styles.ScreenLoader__Title}>{title}</h3>}
      <Loader type="Rings" />
    </div>
  );
};

export default ScreenLoader;
