import { RefObject, useRef } from 'react';

const defaultScrollIntoViewOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
  inline: 'nearest',
};

type OffsetOptions = { xOffset: number; yOffset: number };
const defaultOffsetOptions: OffsetOptions = {
  xOffset: 0,
  yOffset: 0,
};

export interface UseScrollProps {
  scrollIntoViewOptions?: Partial<ScrollIntoViewOptions>;
  offsetOptions?: Partial<OffsetOptions>;
}

export const useScroll = <T extends HTMLElement>(
  { scrollIntoViewOptions, offsetOptions }: UseScrollProps = {
    scrollIntoViewOptions: {},
    offsetOptions: {},
  }
): [RefObject<T>, () => void] => {
  const resultScrollIntoViewOptions = {
    ...defaultScrollIntoViewOptions,
    ...scrollIntoViewOptions,
  };

  const resultOffsetOptions = {
    ...defaultOffsetOptions,
    ...offsetOptions,
  };
  const { xOffset, yOffset } = resultOffsetOptions;

  const elementRef = useRef<T>(null);
  const executeScroll = (): void => {
    const element = elementRef.current;
    if (!element) return;

    const { top, left, position } = element.style;
    element.style.position = 'relative';
    element.style.left = `${xOffset}px`;
    element.style.top = `${yOffset}px`;
    element.scrollIntoView(resultScrollIntoViewOptions);
    element.style.left = left;
    element.style.top = top;
    element.style.position = position;
  };
  return [elementRef, executeScroll];
};
