export interface AcademicTerm {
  id: number;
  school_id: number;
  name: string;
  year: string;
  dates: AcademicTermDate[];
}

interface AcademicTermDate {
  id: number;
  academic_term_id: number;
  date_label: AcademicTermDateLabelKeys;
  date_value: string;
}

export enum AcademicTermDateLabel {
  'registration_begins' = 'registration_begins',
  'registration_ends' = 'registration_ends',
  'all_trials_end' = 'all_trials_end',
  'reporting_begins' = 'reporting_begins',
  'sasi_reporting_begins' = 'sasi_reporting_begins',
  'reporting_ends' = 'reporting_ends',
  'internal_reporting_ends' = 'internal_reporting_ends',
  'grade_delivery_ends' = 'grade_delivery_ends',
  'first_day_of_class' = 'first_day_of_class',
  'last_day_of_class' = 'last_day_of_class',
}
export type AcademicTermDateLabelKeys = keyof typeof AcademicTermDateLabel;
