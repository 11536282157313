import { FC } from 'react';
import { TocSettings } from '@soomo/lib/types/WebtextManifest';
import styles from './styles.module.scss';
import Tile from '../../../../components/Board/Tile';
import { BindingAction, Course } from '../../../../types';

type WebtextTileProps = {
  course?: Course;
  apiHost: string;
  resourceSettings?: TocSettings;
  onWebtextAccessClick: BindingAction;
};

const WebtextTile: FC<WebtextTileProps> = ({
  course,
  apiHost,
  resourceSettings,
  onWebtextAccessClick,
}) => (
  <a
    href={`${apiHost}/courses/${course?.id}`}
    target="_blank"
    onClick={onWebtextAccessClick}
    className={styles.WebtextTile}
    rel="noreferrer"
  >
    <Tile isLoading={!course || !resourceSettings}>
      <img
        src={resourceSettings?.webtext_cover_background_image_url}
        alt=""
        className={styles.WebtextTile__Cover}
      />
      <h2 className={styles.WebtextTile__Title}>{course?.institutional_course.name}</h2>
    </Tile>
  </a>
);

export default WebtextTile;
