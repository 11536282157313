import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface BoardProps {
  className?: string;
}

const Board: FC<BoardProps> = ({ className, children }) => {
  return <div className={clsx(className, styles.Board)}>{children}</div>;
};

export default Board;
