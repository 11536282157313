import { FC, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import pluralize from 'pluralize';
import { useAppDispatch } from '../../../../../redux/hooks/app/useAppDispatch';
import Tile from '../../../../../components/Board/Tile';
import TileHeader from '../../../../../components/Board/Tile/TileHeader';
import { RiskCategoryKeys } from '../../../../../types';
import { useAppSelector } from '../../../../../redux/hooks/app/useAppSelector';
import { selectCoursePivotDateObject } from '../../../../../redux/selectors/application';
import { makeSelectOutreachBoardUiByRisk } from '../../../../../redux/selectors/risks_ui';
import { selectUsersLastMessageEntities } from '../../../../../redux/selectors/messages';
import { makeSelectLastSnapshotCategorizedRiskAssessments } from '../../../../../redux/selectors/risks_snapshots';
import { useAssessmentsTableData } from './useAssessmentsTableData';
import { sendMessageThunk } from '../../../../../redux/thunks/messages';
import AssessmentsTable from './AssessmentsTable';
import ActionsGroup from '../../../../../components/Buttons/ActionsGroup';
import ActionButton from '../../../../../components/Buttons/ActionButton';
import styles from './styles.module.scss';
import ActionButtonLink from '../../../../../components/Buttons/ActionButtonLink';
import MessageFormTile from '../MessageFormTile';

interface CorrectionNeededRiskTileProps {
  riskCategory: RiskCategoryKeys;
}

const CorrectionNeededRiskTile: FC<CorrectionNeededRiskTileProps> = ({ riskCategory }) => {
  const dispatch = useAppDispatch();

  const [isFormOpened, setIsFormOpened] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);

  const pivotDate = useAppSelector(selectCoursePivotDateObject);

  const selectLastSnapshotCategorizedRiskAssessments = useMemo(
    makeSelectLastSnapshotCategorizedRiskAssessments,
    []
  );
  const riskAssessments = useAppSelector((state) =>
    selectLastSnapshotCategorizedRiskAssessments(state, riskCategory)
  );

  const selectOutreachBoardUiByRisk = useMemo(makeSelectOutreachBoardUiByRisk, []);
  const riskUi = useAppSelector((state) => selectOutreachBoardUiByRisk(state, riskCategory));

  const assessmentsTableRowsData = useAssessmentsTableData(riskAssessments || []);

  const handleMessageSending = (message: {
    messageSubject: string;
    messageText: string;
  }): void =>
    batch(() =>
      selectedUsersIds.forEach((userId) =>
        dispatch(
          sendMessageThunk({
            message: {
              userId,
              subject: message.messageSubject,
              body: message.messageText,
            },
          })
        )
      )
    );

  return (
    <>
      <Tile key={riskCategory}>
        <TileHeader className={styles.RiskTile__Header}>
          <span style={{ backgroundColor: riskUi?.color }} />
          {pluralize('student', riskAssessments?.length, true)} {riskUi?.label}
        </TileHeader>
        <AssessmentsTable
          riskCategory={riskCategory}
          pivotDate={pivotDate}
          rowsData={assessmentsTableRowsData}
          onUsersSelection={setSelectedUsersIds}
        />
        <ActionsGroup className={styles.RiskTile__Actions}>
          <ActionButtonLink
            to={riskUi?.interventionLink || ''}
            isHref
            variant="secondary"
            size="large"
          >
            Intervention
          </ActionButtonLink>
          <ActionButton
            variant={!isFormOpened ? 'primary' : 'dark'}
            size="large"
            onClick={() => {
              setIsFormOpened((isOpened) => !isOpened);
            }}
          >
            Contact
          </ActionButton>
        </ActionsGroup>
      </Tile>
      {isFormOpened && (
        <MessageFormTile
          key={`${riskCategory}_form`}
          messageSubject={riskUi?.messageSubject || ''}
          selectedUsersIds={selectedUsersIds}
          onSendClick={handleMessageSending}
          onCancelClick={() => setIsFormOpened(false)}
        />
      )}
    </>
  );
};

export default CorrectionNeededRiskTile;
