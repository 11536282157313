import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import log from 'loglevel';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { StatusCode } from 'status-code-enum';
import { RootState } from '../store';
import { API } from './api_routes';
import { history } from '../../navigation/Routing';
import { API_HOST } from '../../constants';

export interface BaseQueryResult {
  isLoading: boolean;
  isFetching: boolean;
  isUninitialized: boolean;
  error?: FetchBaseQueryError | SerializedError;
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_HOST,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).application;
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const isJobResult = (
  result: any
): result is {
  data: { job_id: string };
  error: FetchBaseQueryError | undefined;
} =>
  typeof result === 'object' &&
  typeof result.data === 'object' &&
  typeof result.data.job_id === 'string';

export const baseQueryWithAuthCheck: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { getState } = api;
  const state = getState() as RootState;
  const { courseId } = state.application;
  const { accessToken } = state.application;
  if (!accessToken) {
    log.error(
      'Access token is missing!',
      `Failed on request to ${JSON.stringify(args, null, 2)}`
    );
    history.replace('/error/token-missing', { courseId });
  }

  let result = await baseQuery(args, api, extraOptions);
  /**
   * This is the way we have to check for token expiration at the moment
   */
  const responseUrl = result.meta?.response?.url;
  const currentUrl = window.location.href;
  if (!currentUrl.includes('/error') && responseUrl?.includes('session_missing')) {
    log.error(
      'Access token was expired!',
      `Failed on request to ${result.meta?.response?.url}`
    );
    history.replace('/error/token-expired', { courseId });
  }

  if (result.meta?.response?.status === StatusCode.ClientErrorForbidden) {
    log.error(
      `Access to the course ${courseId} is not permitted!`,
      `Failed on request to ${result.meta?.response?.url}`
    );
    history.replace('/error/no-permissions', { courseId });
  }

  // Try to receive the result of the background job inside the same API call
  if (isJobResult(result)) {
    const jobId = result.data.job_id;
    const jobPromise = new Promise<
      QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
    >((resolve, reject) => {
      const jobPollInterval = setInterval(async () => {
        const jobResult = await baseQuery(API.JOBS_RESULTS(jobId), api, extraOptions);

        if (jobResult.error?.status === StatusCode.ClientErrorNotFound) {
          // Job is still in the processing mode
          return;
        }

        if (jobResult.data && !jobResult.error) {
          // Job result is ready to be consumed
          resolve(jobResult);
          clearInterval(jobPollInterval);
          return;
        }

        // Other error codes should be considered as the hard errors
        reject(jobResult.error);
        clearInterval(jobPollInterval);
      }, 1000);
    });

    try {
      result = await jobPromise;
    } catch (jobError) {
      result.error = jobError as FetchBaseQueryError | undefined;
    }
  }

  return result;
};

export const todoBoardApi = createApi({
  reducerPath: 'todoBoardApi',
  baseQuery: baseQueryWithAuthCheck,
  endpoints: () => ({}),
});
