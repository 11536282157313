import { FC, ReactElement } from 'react';
import clsx from 'clsx';
import Popup from 'reactjs-popup';
import styles from './styles.module.scss';

interface TooltipProps {
  trigger: string | ReactElement;
  content: string | ReactElement;
  triggerClassName?: string;
  contentClassName?: string;
}

const Tooltip: FC<TooltipProps> = ({
  trigger,
  content,
  triggerClassName,
  contentClassName,
}) => {
  return (
    <Popup
      trigger={
        <span className={clsx(triggerClassName, styles.Tooltip__Trigger)}>{trigger}</span>
      }
      on={['focus', 'hover']}
      position={['top right', 'top center', 'top left']}
      closeOnDocumentClick
      keepTooltipInside
      offsetY={3}
      className="tooltip"
    >
      <div className={clsx(contentClassName, styles.Tooltip__Content)}>{content}</div>
    </Popup>
  );
};

export default Tooltip;
