import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  OutreachBoardUi,
  OutreachBoardUiElements,
  RiskUi,
  RiskUiState,
} from '../../slices/risks_ui';
import { RiskCategoryKeys } from '../../../types';
import { selectUiByRisk } from './select_ui_by_risk';

export const selectOutreachBoardUi = createSelector<RootState, RiskUiState, OutreachBoardUi>(
  [(state) => state.risksUi],
  (risksUi) =>
    Object.entries(risksUi).reduce((outreachUi, [riskCategory, riskElements]) => {
      const { outreachBoard: outreachBoardElements } = riskElements;
      if (!outreachBoardElements) return outreachUi;

      outreachUi[riskCategory as RiskCategoryKeys] = outreachBoardElements;
      return outreachUi;
    }, {} as OutreachBoardUi)
);

export const selectOutreachBoardUiByRisk = createSelector<
  RootState,
  RiskCategoryKeys,
  RiskUi,
  OutreachBoardUiElements | undefined
>([selectUiByRisk], ({ outreachBoard }) => outreachBoard);

export const makeSelectOutreachBoardUiByRisk = () => selectOutreachBoardUiByRisk;
