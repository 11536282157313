import { createSelector } from '@reduxjs/toolkit';
import { CourseDecisions } from '@soomo/lib/types/WebtextManifest';
import { RootState } from '../../store';
import { coursesApi } from '../../queries/courses';
import { selectCourseId } from '../application';
import { Chapter, Manifest, Page } from '../../../types';

const selectCourseWebtextManifestResult = createSelector<RootState, number, any>(
  [selectCourseId],
  (courseId) => coursesApi.endpoints.getWebtextManifestByCourseId.select(courseId)
);

const selectWebtextManifestData = createSelector<
  RootState,
  { data?: Manifest },
  Manifest | undefined
>(
  [(state) => selectCourseWebtextManifestResult(state)(state)],
  (webtextManifestResult) => webtextManifestResult.data
);

export const selectChaptersEntities = createSelector<
  RootState,
  Manifest | undefined,
  Record<string, Chapter>
>([selectWebtextManifestData], (manifestData) => manifestData?.chapters || {});

export const selectAllChapters = createSelector<RootState, Record<string, Chapter>, Chapter[]>(
  [selectChaptersEntities],
  (chapters) => Object.values(chapters)
);

export const selectChaptersFamilyIds = createSelector<
  RootState,
  Record<string, Chapter>,
  string[]
>([selectChaptersEntities], (chapters) => Object.keys(chapters));

export const selectChapterByFamilyId = createSelector<
  RootState,
  string,
  Record<string, Chapter>,
  string,
  Chapter | undefined
>(
  [selectChaptersEntities, (state, chapterFamilyId) => chapterFamilyId],
  (chapters, chapterFamilyId) => chapters[chapterFamilyId]
);

export const selectPagesEntities = createSelector<
  RootState,
  Manifest | undefined,
  Record<string, Page>
>([selectWebtextManifestData], (manifestData) => manifestData?.pages || {});

export const selectAllPages = createSelector<RootState, Record<string, Page>, Page[]>(
  [selectPagesEntities],
  (pages) => Object.values(pages)
);

export const selectPagesFamilyIds = createSelector<RootState, Record<string, Page>, string[]>(
  [selectPagesEntities],
  (pages) => Object.keys(pages)
);

export const selectPageByFamilyId = createSelector<
  RootState,
  string,
  Record<string, Page>,
  string,
  Page | undefined
>(
  [selectPagesEntities, (state, pageFamilyId) => pageFamilyId],
  (pages, pageFamilyId) => pages[pageFamilyId]
);

export const selectPagesElementsEntities = createSelector<
  RootState,
  Manifest | undefined,
  Record<string, any>
>([selectWebtextManifestData], (manifestData) => manifestData?.elements || {});

export const selectAllPagesElements = createSelector<RootState, Record<string, any>, any[]>(
  [selectPagesElementsEntities],
  (elements) => Object.values(elements)
);

export const selectPagesElementsFamilyIds = createSelector<
  RootState,
  Record<string, any>,
  string[]
>([selectPagesElementsEntities], (elements) => Object.keys(elements));

export const selectPageElementByFamilyId = createSelector<
  RootState,
  string,
  Record<string, any>,
  string,
  any | undefined
>(
  [selectPagesElementsEntities, (state, elementFamilyId) => elementFamilyId],
  (elements, elementFamilyId) => elements[elementFamilyId]
);

export const selectAllPageElements = createSelector<RootState, string, any[], string, any[]>(
  [selectAllPagesElements, (state, pageFamilyId) => pageFamilyId],
  (pagesElements, pageFamilyId) =>
    pagesElements.filter((element) => element.pageId === pageFamilyId)
);

export const selectPageElementsFamilyIds = createSelector<RootState, string, any[], string[]>(
  [selectAllPageElements],
  (pageElements) => pageElements.map((element) => element.id)
);

export const selectCourseDecisions = createSelector<
  RootState,
  Manifest | undefined,
  CourseDecisions
>(
  [selectWebtextManifestData],
  (manifestData) => manifestData?.toc?.config?.course_decisions || {}
);
