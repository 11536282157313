import { generateAssessmentRisks } from './assessment_risks_generator';
import { CategorizedRisksAssessments, SnapshotStatsIndex } from '../../slices/risks_snapshots';
import { orderedRiskCategories, RiskCategory, RisksSnapshot } from '../../../types';

/**
 * Categorizing logic:<br>
 * Go through each assessment's measurement and calculate risks for them:
 * * If no risk category presented -> user is on track.
 * * If only one risk category is presented -> address user to it.
 * * If multiple categories are presented -> address to the higher in the priorities list.
 *
 * Prioritized risks keys -> Weekly outreach keys mapping:
 * 1. never-signed-in -> notStarted
 * 2. no-sign-in -> stopped
 * 3. low-completion -> lowCompletion
 * 4. low-score -> lowScore
 */
export const categorizeAssessmentsByRisks = (
  snapshot: RisksSnapshot,
  snapshotStatsIndex: SnapshotStatsIndex
): {
  categorizedAssessments: CategorizedRisksAssessments;
  offTrackAssessmentsAmount: number;
} => {
  const {
    snapshot_ended_at,
    assessments: { assessments },
  } = snapshot;
  const { risksCutoffs } = snapshotStatsIndex;

  let offTrackAssessmentsAmount = 0;

  const categorizedAssessments = assessments.reduce(
    (categorizedAssessmentsAcc, assessment) => {
      const risks = generateAssessmentRisks(assessment, risksCutoffs, snapshot_ended_at);
      const risksCategories = risks.map((risk) => risk.categoryKey);

      let riskCategory = RiskCategory['on-track'];
      orderedRiskCategories.some((orderedRiskCategory) => {
        if (risksCategories.includes(orderedRiskCategory)) {
          riskCategory = RiskCategory[orderedRiskCategory];
          offTrackAssessmentsAmount += 1;
          return true;
        }
        return false;
      });

      const categorizedRiskAssessments = categorizedAssessmentsAcc[riskCategory] || {};
      const assessmentsUsersIds = categorizedRiskAssessments.assessmentsUsersIds || [];
      assessmentsUsersIds.push(assessment.user.id);

      categorizedRiskAssessments.riskCategory = riskCategory;
      categorizedRiskAssessments.assessmentsUsersIds = assessmentsUsersIds;
      categorizedAssessmentsAcc[riskCategory] = categorizedRiskAssessments;
      return categorizedAssessmentsAcc;
    },
    {} as CategorizedRisksAssessments
  );

  return { categorizedAssessments, offTrackAssessmentsAmount };
};
