import { createEntityAdapter } from '@reduxjs/toolkit';
import { ShortCourse } from '../../../types';
import { Enrollment } from '../../../types/enrollment';

export const currentUserCoursesAdapter = createEntityAdapter<ShortCourse>();
export const currentUserCoursesInitialState = currentUserCoursesAdapter.getInitialState();

export const enrollmentsAdapter = createEntityAdapter<Enrollment>({
  selectId: (enrollment) => enrollment.user.id,
});
export const enrollmentsInitialState = enrollmentsAdapter.getInitialState();
