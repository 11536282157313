import { FC, useCallback } from 'react';
import log from 'loglevel';
import { saveUiAction } from '../../../../../redux/slices/ui_actions';
import { useAppDispatch } from '../../../../../redux/hooks/app/useAppDispatch';
import { useAppSelector } from '../../../../../redux/hooks/app/useAppSelector';
import { selectChapterByFamilyId } from '../../../../../redux/selectors/course';
import { useNewWorkRows } from './useNewWorkRows';
import Tile from '../../../../../components/Board/Tile';
import TileHeader from '../../../../../components/Board/Tile/TileHeader';
import NewWorkChapterTable from './NewWorkChapterTable';
import Pill from '../../../../../components/Pill';
import styles from './styles.module.scss';

interface NewWorkTileProps {
  chapterFamilyId: string;
}

const NewWorkChapterTile: FC<NewWorkTileProps> = ({ chapterFamilyId }) => {
  const dispatch = useAppDispatch();

  const sectionChapter = useAppSelector((state) =>
    selectChapterByFamilyId(state, chapterFamilyId)
  );
  const newWorkTableRowsData = useNewWorkRows(chapterFamilyId);

  const handleReviewClick = useCallback(
    ({
      questionId,
      pageId,
      usersIds,
    }: {
      questionId: number;
      pageId: string;
      usersIds: number[];
    }) => {
      dispatch(saveUiAction('NEW_WORK_SECTION__REVIEW_WORKS__CLICKED'));

      log.error(
        `You want to review question: ${questionId}, for users: [${usersIds}], for page ${pageId}`
      );
    },
    [dispatch]
  );

  if (!sectionChapter) {
    log.error(`Chapter ${chapterFamilyId} cannot be found for the section`);
    return null;
  }
  if (!newWorkTableRowsData.length) {
    return null;
  }
  return (
    <Tile key={chapterFamilyId}>
      <TileHeader className={styles.ChapterTile__Header}>
        <span>
          {sectionChapter.chapter_human_name} {sectionChapter.chapter_number}
        </span>
        <Pill>({sectionChapter.chapter_name})</Pill>
      </TileHeader>
      <NewWorkChapterTable
        chapterFamilyId={sectionChapter.id}
        rowsData={newWorkTableRowsData}
        onReviewClick={handleReviewClick}
      />
    </Tile>
  );
};

export default NewWorkChapterTile;
