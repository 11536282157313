import { FC, useMemo } from 'react';
import pluralize from 'pluralize';
import clsx from 'clsx';
import SliderInput, { SliderInputProps } from '../SliderInput';
import { BindingFunction1 } from '../../../types';
import styles from './styles.module.scss';

export interface LabeledSliderProps extends SliderInputProps {
  label: string;
  unit?: string;
  explanation?: BindingFunction1<number, string> | string;
  inputGroupClassname?: string;
  explanationClassname?: string;
}

const LabeledSlider: FC<LabeledSliderProps> = ({
  label,
  unit,
  explanation,
  className,
  inputGroupClassname,
  explanationClassname,
  ...sliderProps
}) => {
  const { value, step } = sliderProps;

  const stepDigitsAfterDecimal = String(step).split('.')[1]?.length || 0;
  const numberWithFixedDecimal = useMemo(
    () => value.toFixed(stepDigitsAfterDecimal),
    [stepDigitsAfterDecimal, value]
  );

  const explanationString = useMemo(() => {
    if (typeof explanation === 'function') {
      return explanation(value);
    }
    return explanation;
  }, [explanation, value]);

  return (
    <div className={clsx(className, styles.LabeledSlider)}>
      <div className={clsx(inputGroupClassname, styles.LabeledSlider__InputGroup)}>
        <span>{label}</span>
        <SliderInput {...sliderProps} />
        <span className={styles.LabeledSlider__Value}>
          <strong>{numberWithFixedDecimal}</strong>{' '}
          {unit && <span>{pluralize(unit, +numberWithFixedDecimal)}</span>}
        </span>
      </div>
      {explanation && (
        <div className={clsx(explanationClassname, styles.LabeledSlider__Explanation)}>
          {explanationString}
        </div>
      )}
    </div>
  );
};

export default LabeledSlider;
