import { FC, useCallback, useMemo } from 'react';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import pluralize from 'pluralize';
import clsx from 'clsx';
import { BindingCallback1, PageElementsTypes } from '../../../../../../types';
import { useRowSpan } from '../../../../../../components/ReactTable/hooks/useRowSpan';
import TableRowWithSpannedCells from '../../../../../../components/ReactTable/components/TableRowWithSpannedCells';
import { prepareSpannedRowCells } from '../../../../../../components/ReactTable/utils/spanned_row_cell_preparer';
import { toPercentString } from '../../../../../../utils/strings_formatter';
import ActionButtonLink from '../../../../../../components/Buttons/ActionButtonLink';
import styles from './styles.module.scss';

interface NewWorkTableProps {
  chapterFamilyId: string;
  rowsData: NewWorkColumns[];
  onReviewClick: BindingCallback1<{
    questionId: number;
    pageId: string;
    usersIds: number[];
  }>;
}

export type NewWorkColumns = {
  reviewButton: {
    a2RedirectionLink: string;
  };
  page: {
    id: string;
    name: string;
    number: string;
    humanName: string;
    navLabel: string;
    type: string;
  };
  decision: {
    decisionKey: string;
    optionKey: string;
    optionName: string;
  };
  question: {
    id: number;
    type: PageElementsTypes;
    body: string;
    humanName: string;
    completionAchieved: number;
    answersIds: number[];
    usersIds: number[];
  };
};

const NewWorkChapterTable: FC<NewWorkTableProps> = ({
  chapterFamilyId,
  rowsData,
  onReviewClick,
}) => {
  const columns: Column<NewWorkColumns>[] = useMemo(
    () => [
      {
        id: 'page',
        Header: 'Page',
        accessor: (row) => row.page.id,
        Cell: ({ cell }: { cell: Cell<NewWorkColumns> }) => {
          const {
            page: { number, name, navLabel },
          } = cell.row.original;

          let label = name;
          if (navLabel) label = `<em>${navLabel}:</em> ${label}`;
          if (number) label = `<strong>${number}</strong> <br/> ${label}`;
          return <span dangerouslySetInnerHTML={{ __html: label }} />;
        },
        enableRowSpan: true,
      },
      {
        id: 'decision',
        Header: 'Decision',
        accessor: (row) => row.decision.optionKey,
        Cell: ({ cell }: { cell: Cell<NewWorkColumns> }) => {
          const {
            decision: { optionName },
          } = cell.row.original;
          const displayOptionName = optionName === 'none' ? '∅' : optionName;
          return (
            <span className={clsx(optionName === 'none' && styles.ChapterTable__EmptyCell)}>
              {displayOptionName}
            </span>
          );
        },
        enableRowSpan: true,
      },
      {
        id: 'question',
        Header: 'Question',
        accessor: (row) => row.question.type,
        Cell: ({ cell }: { cell: Cell<NewWorkColumns> }) => {
          const {
            question: { answersIds, humanName, body },
          } = cell.row.original;
          return (
            <div className={styles.ChapterTable__QuestionCell}>
              <strong>
                {humanName} ({answersIds.length} new {pluralize('answer', answersIds.length)})
              </strong>
              <span dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          );
        },
      },
      {
        id: 'completion-achieved',
        Header: 'Complete',
        accessor: (row) => row.question.completionAchieved,
        Cell: ({ cell }: { cell: Cell<NewWorkColumns> }) =>
          toPercentString(cell.row.original.question.completionAchieved),
      },
      {
        Header: '',
        accessor: 'reviewButton',
        Cell: ({ cell }: { cell: Cell<NewWorkColumns> }) => {
          const {
            reviewButton: { a2RedirectionLink },
            page: { id: pageId },
            question: { id: questionId, usersIds },
          } = cell.row.original;

          return (
            <ActionButtonLink
              to={a2RedirectionLink}
              isHref
              onClick={() => onReviewClick({ questionId, pageId, usersIds })}
              className={styles.ChapterTable__ReviewButton}
            >
              Review
            </ActionButtonLink>
          );
        },
      },
    ],
    [onReviewClick]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<NewWorkColumns>(
      {
        columns,
        data: rowsData,
      },
      (hooks) => hooks.useInstance.push(useRowSpan)
    );

  const groupedRowsByPageNumber = useMemo<
    {
      pageNumber: string;
      rowsGroup: Row<NewWorkColumns>[];
    }[]
  >(() => {
    const grouped = rows.reduce(
      (groupedRows, row) => {
        const { number: pageNumber } = row.original.page;
        groupedRows[pageNumber] = groupedRows[pageNumber] || {};
        groupedRows[pageNumber].pageNumber = pageNumber;
        groupedRows[pageNumber].rowsGroup = [
          ...(groupedRows[pageNumber].rowsGroup || []),
          row,
        ];
        return groupedRows;
      },
      {} as {
        [pageNumber: string]: { pageNumber: string; rowsGroup: Row<NewWorkColumns>[] };
      }
    );
    return Object.values(grouped);
  }, [rows]);

  const renderHeaderRow = useCallback((headerGroup: HeaderGroup<NewWorkColumns>) => {
    return (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ))}
      </tr>
    );
  }, []);

  const renderBodyRow = useCallback(
    (row: Row<NewWorkColumns>) => {
      prepareRow(row);
      prepareSpannedRowCells(rows, row);
      return <TableRowWithSpannedCells key={row.id} row={row} />;
    },
    [prepareRow, rows]
  );

  return (
    <div className={styles.ChapterTable}>
      <table {...getTableProps()}>
        <thead>{headerGroups.map(renderHeaderRow)}</thead>
        {groupedRowsByPageNumber.map(({ pageNumber, rowsGroup }) => (
          <tbody {...getTableBodyProps()} key={pageNumber}>
            {rowsGroup.map(renderBodyRow)}
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default NewWorkChapterTable;
