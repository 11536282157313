import { FC } from 'react';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import BoardHeader from '../../components/Board/BoardHeader';
import Board from '../../components/Board';
import { selectCourseId } from '../../redux/selectors/application';
import CorrectionNeededSection from './CorrectionNeededSection';

const OutreachBoard: FC = () => {
  const courseId = useAppSelector(selectCourseId);
  return (
    <Board>
      <BoardHeader backwardsLink={`/courses/${courseId}`} header="Weekly outreach:" />
      <CorrectionNeededSection />
    </Board>
  );
};

export default OutreachBoard;
