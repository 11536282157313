import { FC } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Section from '../../../Board/Section';
import Tile from '../../../Board/Tile';
import TileHeader from '../../../Board/Tile/TileHeader';
import styles from './styles.module.scss';

const EmptyNotification: FC = () => (
  <Section
    tile={
      <Tile className={styles.EmptyNotification__Tile}>
        <TileHeader>
          You don&apos;t have notifications for now
          <AiOutlineCheckCircle />
        </TileHeader>
      </Tile>
    }
  />
);

export default EmptyNotification;
