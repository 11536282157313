import { FC, ReactElement, useMemo } from 'react';
import { BsChatLeft, BsEye } from 'react-icons/bs';
import { GiProgression } from 'react-icons/gi';
import { GrCycle } from 'react-icons/gr';
import clsx from 'clsx';
import { useAnswers } from '../../redux/hooks/answers/useAnswers';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import { selectUnreviewedAnswers } from '../../redux/selectors/answers';
import { selectLastSnapshotCategorizationIndex } from '../../redux/selectors/risks_snapshots';
import { useCourseSnapshots } from '../../redux/hooks/risks_snapshots/useCourseSnapshots';
import ContentSkeleton from '../ContentSkeleton';
import { selectUiActionByKey } from '../../redux/selectors/ui_actions';
import ActionButtonLink from '../Buttons/ActionButtonLink';
import { selectCourseId } from '../../redux/selectors/application';
import styles from './styles.module.scss';

const Sidebar: FC = () => {
  const courseId = useAppSelector(selectCourseId);

  const lastSnapshotCategorizationIndex = useAppSelector(
    selectLastSnapshotCategorizationIndex
  );
  const unreviewedAnswers = useAppSelector(selectUnreviewedAnswers);
  const isControlsBarSticky = useAppSelector((state) =>
    selectUiActionByKey(state, 'CONTROLS_BAR__STICKY__CLICKED')
  );

  const {
    isLoading: isSnapshotsLoading,
    isUninitialized: isSnapshotsUninitialized,
    error: snapshotsError,
  } = useCourseSnapshots();
  const {
    isLoading: isAnswersLoading,
    isUninitialized: isAnswersUninitialized,
    error: answersError,
  } = useAnswers();

  const skeletonElements = useMemo(() => {
    return (
      <ContentSkeleton
        count={4}
        className={clsx(
          styles.Sidebar__List,
          isControlsBarSticky && styles.Sidebar__List_withControls
        )}
        elementClassName={styles.Sidebar__Element_skeleton}
      />
    );
  }, [isControlsBarSticky]);

  const createSidebarElement = ({
    to,
    title,
    icon,
    amount,
    isActive,
  }: {
    to: string;
    title: string;
    icon: ReactElement;
    amount: number | null;
    isActive?: boolean;
  }): ReactElement => {
    return (
      <li
        className={clsx(styles.Sidebar__Element, isActive && styles.Sidebar__Element_active)}
      >
        <ActionButtonLink
          to={to}
          className={clsx(
            styles.Sidebar__ElementButton,
            isActive && styles.Sidebar__ElementButton_active
          )}
          linkClassName={clsx(isActive && styles.Sidebar__ElementLink_active)}
        >
          <span className={styles.Sidebar__ElementLabel}>
            {icon}
            {title}
          </span>
          {amount !== null && (
            <span
              className={clsx(
                styles.Sidebar__ElementBadge,
                amount > 0
                  ? styles.Sidebar__ElementBadge_warning
                  : styles.Sidebar__ElementBadge_success
              )}
            >
              {amount}
            </span>
          )}
        </ActionButtonLink>
      </li>
    );
  };

  const sidebarElements = useMemo(() => {
    return (
      <ul
        className={clsx(
          styles.Sidebar__List,
          isControlsBarSticky && styles.Sidebar__List_withControls
        )}
      >
        {createSidebarElement({
          to: `/courses/${courseId}`,
          title: 'Dashboard',
          icon: <BsEye />,
          amount: null,
          isActive: true,
        })}
        {!snapshotsError &&
          createSidebarElement({
            to: `/courses/${courseId}/#weekly-outreach`,
            title: 'Weekly outreach',
            icon: <GiProgression />,
            amount: lastSnapshotCategorizationIndex?.offTrackAssessmentsAmount ?? null,
          })}
        {!answersError &&
          createSidebarElement({
            to: `/courses/${courseId}/#new-work`,
            title: 'New work',
            icon: <GrCycle />,
            amount: unreviewedAnswers?.length ?? null,
          })}
        {createSidebarElement({
          to: `/courses/${courseId}/#messages`,
          title: 'Messages',
          icon: <BsChatLeft />,
          amount: 0,
        })}
      </ul>
    );
  }, [
    answersError,
    courseId,
    isControlsBarSticky,
    lastSnapshotCategorizationIndex?.offTrackAssessmentsAmount,
    snapshotsError,
    unreviewedAnswers?.length,
  ]);

  return (
    <aside
      className={clsx(styles.Sidebar, isControlsBarSticky && styles.Sidebar_withControls)}
    >
      {isSnapshotsLoading ||
      isSnapshotsUninitialized ||
      isAnswersLoading ||
      isAnswersUninitialized
        ? skeletonElements
        : sidebarElements}
    </aside>
  );
};

export default Sidebar;
