import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { BindingCallback1 } from '../../../types';
import styles from './styles.module.scss';

export interface SliderInputProps {
  value: number;
  min?: string | number;
  max?: string | number;
  step?: string | number;
  className?: string;
  onChange: BindingCallback1<number>;
}

const SliderInput: FC<SliderInputProps> = ({
  value,
  min = 0,
  max = 5,
  step = 0.1,
  className,
  onChange,
}) => {
  const [sliderValue, setSliderValue] = useState(value.toString());

  useEffect(() => {
    setSliderValue(value.toString());
  }, [value]);

  const handleSlide = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const targetValue = e.target.value;
      setSliderValue(targetValue);
      onChange(+targetValue);
    },
    [onChange]
  );

  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={sliderValue}
      onChange={handleSlide}
      className={clsx(className, styles.SliderInput)}
    />
  );
};

export default SliderInput;
