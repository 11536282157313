import { EnrollmentUser } from '../../../types/enrollment';

export const extractUserQuestionCyoKey = (
  question: any | undefined,
  user: EnrollmentUser | undefined
): string | undefined => {
  if (question?.dependsOn && question?.dependsOn?.length && question?.dictionary?.body) {
    return user?.decisions?.[question.dependsOn[0]];
  }
  return undefined;
};
