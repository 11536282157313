import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AnswersTableIndex } from '../../slices/answers';

export const selectIndexedChaptersFamilyIds = createSelector<
  RootState,
  AnswersTableIndex,
  string[]
>([(state) => state.answers.answersTableIndex], (answersTableIndex) =>
  Object.keys(answersTableIndex.chapters)
);

export const selectIndexedChapterPagesFamilyIds = createSelector<
  RootState,
  string,
  AnswersTableIndex,
  string,
  string[]
>(
  [(state) => state.answers.answersTableIndex, (state, chapterFamilyId) => chapterFamilyId],
  (answersTableIndex, chapterFamilyId) => {
    const chapterPages = answersTableIndex.chapters[chapterFamilyId]?.pages;
    return Object.keys(chapterPages || {});
  }
);
