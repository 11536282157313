import { FC, useRef, useState } from 'react';
import Modal from 'react-responsive-modal';
import { BiMessageEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import ActionButton from '../../Buttons/ActionButton';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import {
  selectMessagedUsersIds,
  selectUsersMessagesEntities,
} from '../../../redux/selectors/messages';
import MessagesTable from './components/MessagesTable';
import { useAppDispatch } from '../../../redux/hooks/app/useAppDispatch';
import styles from './styles.module.scss';
import { selectStudentsUsersEntities } from '../../../redux/selectors/course';
import { unsendMessageThunk } from '../../../redux/thunks/messages';
import { selectCourseId } from '../../../redux/selectors/application';

const MessagesResetModal: FC = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setOpen] = useState(false);
  const contactsResetModalRef = useRef(null);

  const courseId = useAppSelector(selectCourseId);

  const messagedUsersIds = useAppSelector(selectMessagedUsersIds);
  const usersMessagesEntities = useAppSelector(selectUsersMessagesEntities);
  const enrollmentsUserEntities = useAppSelector(selectStudentsUsersEntities);

  const handleResetMessageClick = ({ messageId }: { messageId: number }): void => {
    dispatch(unsendMessageThunk({ messageId }));
  };

  return (
    <>
      <ActionButton variant="dark" onClick={() => setOpen(true)}>
        Reset messages
      </ActionButton>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="contacts-reset-modal-title"
        initialFocusRef={contactsResetModalRef}
        center
      >
        {!messagedUsersIds.length ? (
          <div className={styles.MessagesResetModal__Empty}>
            <h3>
              There are no messages to reset for now. <br />
              Contact someone on the{' '}
              <Link to={`/courses/${courseId}/outreach`} onClick={() => setOpen(false)}>
                outreach page
              </Link>{' '}
              first.
            </h3>
            <BiMessageEdit />
          </div>
        ) : (
          <>
            <h2 id="contacts-reset-modal-title">Reset sent messages:</h2>
            <MessagesTable
              usersMessagesEntities={usersMessagesEntities}
              usersEntities={enrollmentsUserEntities}
              onResetClick={handleResetMessageClick}
            />
          </>
        )}
      </Modal>
    </>
  );
};
export default MessagesResetModal;
