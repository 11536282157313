import { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { JwtPayload } from 'jwt-decode';
import { useAppDispatch } from '../../redux/hooks/app/useAppDispatch';
import { useQuery } from '../../hooks/useQuery';
import { useGetAccessTokenQuery } from '../../redux/queries/authentication';
import {
  updateAccessToken,
  updateApi,
  updateCourseId,
  updateUserId,
} from '../../redux/slices/application';
import { useJwtTokenPayload } from '../../hooks/useJwtTokenPayload';
import ScreenLoader from '../../components/ScreenLoader';

interface OneTimeTokenPayload extends JwtPayload {
  extra: {
    analytics_host: string;
  };
}

const JwtAuthGateway: FC = () => {
  const dispatch = useAppDispatch();

  const pathParams = useParams<{ courseId?: string }>();
  const queryParams = useQuery();

  const { courseId } = pathParams;
  const oneTimeToken = queryParams.get('jwt');
  const oneTimeTokenPayload = useJwtTokenPayload<OneTimeTokenPayload>(oneTimeToken || '');
  const userId = oneTimeTokenPayload?.sub;
  const analyticsHost = oneTimeTokenPayload?.extra?.analytics_host;

  const {
    data: accessToken,
    isFetching: isTokenFetching,
    error: tokenError,
  } = useGetAccessTokenQuery(!oneTimeToken ? skipToken : { oneTimeToken });

  useEffect(() => {
    if (isTokenFetching || tokenError) return;
    if (!userId || !accessToken || !courseId || !analyticsHost) return;

    dispatch(updateCourseId({ courseId: +courseId }));
    dispatch(updateUserId({ userId: +userId }));
    dispatch(updateAccessToken({ accessToken }));
    dispatch(updateApi({ analyticsHost }));
  }, [accessToken, analyticsHost, courseId, dispatch, isTokenFetching, tokenError, userId]);

  if (!oneTimeToken || tokenError) {
    return <Redirect to="/error/token-missing" />;
  }

  if (!courseId) {
    return <Redirect to="/error/course-id-missing" />;
  }

  if (!analyticsHost) {
    return <Redirect to="/error/analytics-host-missing" />;
  }

  if (!isTokenFetching) {
    return <Redirect to={`/courses/${courseId}`} />;
  }

  return <ScreenLoader title="Looking for the course data..." />;
};

export default JwtAuthGateway;
