import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '../app/useAppSelector';
import { useGetAnswersByCourseIdQuery } from '../../queries/answers';
import { useWebtextManifest } from '../courses/useWebtextManifest';
import { useEnrollments } from '../courses/useEnrollments';
import { selectCourseId } from '../../selectors/application';

export const useAnswers = () => {
  const courseId = useAppSelector(selectCourseId);

  const { data: webtextManifest, isFetching: isWebtextFetching } = useWebtextManifest();
  const isWebtextManifestReady = webtextManifest && !isWebtextFetching;

  const { data: enrollments, isFetching: isEnrollmentsFetching } = useEnrollments();
  const isEnrollmentsReady = enrollments && !isEnrollmentsFetching;

  return useGetAnswersByCourseIdQuery(
    isWebtextManifestReady && isEnrollmentsReady ? courseId : skipToken
  );
};
