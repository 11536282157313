import { ReactElement } from 'react';
import clsx from 'clsx';
import { IoCalendar, IoCalendarOutline } from 'react-icons/io5';
import { BsCheckCircle } from 'react-icons/bs';
import {
  MeasurementDimensionKeys,
  RiskCategoryKeys,
  BindingCallback1,
} from '../../../../../../types';
import {
  formatRelativeToDate,
  formatSecondsSpent,
  toTimeMonthDateString,
  toTimeMonthDateYearString,
} from '../../../../../../utils/timespans_formatter';
import {
  capitalizeStringFirstLetter,
  toPercentString,
} from '../../../../../../utils/strings_formatter';
import IconCheckbox from '../../../../../../components/Inputs/IconCheckbox';
import styles from './styles.module.scss';
import { isDateLessEqualThanWeeks } from '../../../../../../utils/dates_comparer';

export type FormattingOptions = Partial<
  Record<MeasurementDimensionKeys, Record<string, boolean>>
>;

export const formatDateTogglingCell = ({
  title,
  dimension,
  options,
  onToggle,
}: {
  title: string;
  dimension: MeasurementDimensionKeys;
  options: FormattingOptions;
  onToggle: BindingCallback1<boolean>;
}): ReactElement => (
  <>
    <span>{title}</span>
    <IconCheckbox
      value={options[dimension]?.isAbsoluteFormatting}
      toggledIcon={<IoCalendar />}
      untoggledIcon={<IoCalendarOutline />}
      onToggle={onToggle}
      stopPropagation
    />
  </>
);

const formatDimensionValue = (
  dimension: MeasurementDimensionKeys,
  value?: string,
  dimensionOptions?: Record<string, boolean>,
  pivotDate?: Date
): string => {
  let formatterValue;
  switch (dimension) {
    case 'completion-achieved':
    case 'score-achieved': {
      formatterValue = toPercentString(value);
      break;
    }
    case 'time-spent': {
      formatterValue = value ? formatSecondsSpent(+value) : '0h 0m';
      break;
    }
    case 'last-signin':
    case 'last-contact': {
      if (!value || !pivotDate) {
        formatterValue = 'Never';
        break;
      }

      const measurementDate = new Date(value);
      if (dimensionOptions?.isAbsoluteFormatting) {
        const isCurrentYear = measurementDate.getFullYear() === pivotDate.getFullYear();
        formatterValue = isCurrentYear
          ? toTimeMonthDateString(measurementDate)
          : toTimeMonthDateYearString(measurementDate);
      } else {
        const relativeDays = formatRelativeToDate(pivotDate, measurementDate);
        formatterValue = capitalizeStringFirstLetter(relativeDays);
      }
      break;
    }
  }
  return formatterValue;
};

export const formatDimensionCell = ({
  dimension,
  value,
  options,
  pivotDate,
  riskCategory,
}: {
  dimension: MeasurementDimensionKeys;
  value?: string;
  options?: FormattingOptions;
  pivotDate?: Date;
  riskCategory: RiskCategoryKeys;
}): ReactElement | string => {
  let formatterCell = null;

  const dimensionOptions = options?.[dimension];
  const formattedValue = formatDimensionValue(dimension, value, dimensionOptions, pivotDate);

  switch (dimension) {
    case 'completion-achieved': {
      const isDanger = riskCategory === 'low-completion';
      formatterCell = (
        <span className={clsx(isDanger && styles.AssessmentsTable__Cell_danger)}>
          {formattedValue}
        </span>
      );
      break;
    }
    case 'score-achieved': {
      const isDanger = riskCategory === 'low-score';
      formatterCell = (
        <span className={clsx(isDanger && styles.AssessmentsTable__Cell_danger)}>
          {formattedValue}
        </span>
      );
      break;
    }
    case 'time-spent': {
      formatterCell = formattedValue;
      break;
    }
    case 'last-signin': {
      const isDanger = riskCategory === 'no-sign-in' || riskCategory === 'never-signed-in';
      formatterCell = (
        <span className={clsx(isDanger && styles.AssessmentsTable__Cell_danger)}>
          {formattedValue}
        </span>
      );
      break;
    }
    case 'last-contact': {
      const isRecentlyContacted =
        !value || !pivotDate ? false : isDateLessEqualThanWeeks(value, pivotDate, 2);
      const isDanger = riskCategory === 'no-contact-2-weeks' || !isRecentlyContacted;

      const connectionSymbol = !isDanger && <BsCheckCircle />;
      formatterCell = (
        <span
          className={clsx(
            isDanger && styles.AssessmentsTable__Cell_danger,
            connectionSymbol && styles.AssessmentsTable__Cell_connected
          )}
        >
          {formattedValue}
          {connectionSymbol}
        </span>
      );
      break;
    }
  }

  return formatterCell;
};
