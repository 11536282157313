export interface RisksSnapshotDatesIndex {
  id: number;
  course_id: number;
  week: number;
  snapshot_ended_at: string;
}

export interface RisksSnapshot extends RisksSnapshotDatesIndex {
  assessments: {
    assessments: Assessment[];
  };
}

export interface Assessment {
  user: AssessmentUser;
  measurements: AssessmentMeasurement[];
}

export interface AssessmentUser {
  id: number;
  first_name: string;
  last_name: string;
}

export interface AssessmentMeasurement {
  dimension: MeasurementDimensionKeys;
  value: string | number | null;
  flagged: string;
}

export enum MeasurementDimension {
  'last-signin' = 'last-signin',
  'completion-achieved' = 'completion-achieved',
  'score-achieved' = 'score-achieved',
  'time-spent' = 'time-spent',
  'last-contact' = 'last-contact',
}
export type MeasurementDimensionKeys = keyof typeof MeasurementDimension;

/**
 * Only 'completion-achieved', 'score-achieved', 'time-spent'
 * need to be calculated for statistical metrics
 */
export const statisticalMeasurementDimensions = [
  'completion-achieved',
  'score-achieved',
  'time-spent',
] as MeasurementDimensionKeys[];

export interface AssessmentRisk {
  categoryKey: RiskCategoryKeys;
  categoryRank: number;
}

export enum RiskCategory {
  'on-track' = 'on-track',
  'never-signed-in' = 'never-signed-in',
  'no-sign-in' = 'no-sign-in',
  'low-completion' = 'low-completion',
  'low-score' = 'low-score',
  'no-contact-2-weeks' = 'no-contact-2-weeks',
}
export type RiskCategoryKeys = keyof typeof RiskCategory;

/**
 * Prioritized risks keys -> Weekly outreach keys mapping:
 * 1. never-signed-in -> notStarted
 * 2. no-sign-in -> stopped
 * 3. low-completion -> lowCompletion
 * 4. low-score -> lowScore
 */
export const orderedRiskCategories = [
  'never-signed-in',
  'no-sign-in',
  'low-completion',
  'low-score',
] as RiskCategoryKeys[];

export type WeeklySnapshot = Pick<RisksSnapshot, 'snapshot_ended_at' | 'week'>;
