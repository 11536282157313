import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

const isSerializableError = (
  error: FetchBaseQueryError | SerializedError
): error is SerializedError => 'message' in error || 'stack' in error || 'code' in error;

export const toSerializableError = (
  error?: FetchBaseQueryError | SerializedError
): SerializedError | undefined => {
  if (!error) return undefined;
  if (isSerializableError(error)) return error;

  if (error.status === 'FETCH_ERROR' || error.status === 'CUSTOM_ERROR') {
    return {
      message: error.error,
      code: error.status,
    };
  }
  if (error.status === 'PARSING_ERROR') {
    return {
      message: error.error,
      code: error.originalStatus.toString(),
    };
  }
  return {
    message: String(error.data),
    code: error.status.toString(),
  };
};
