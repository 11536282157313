import { FC, useMemo } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import clsx from 'clsx';
import Tile from '../../../../components/Board/Tile';
import TileHeader from '../../../../components/Board/Tile/TileHeader';
import styles from './styles.module.scss';
import TileParagraph from '../../../../components/Board/Tile/TileParagraph';

interface NewWorkEmptyTile {
  tutorial?: boolean;
}

const NewWorkEmptyTile: FC<NewWorkEmptyTile> = ({ tutorial }) => {
  const tileContent = useMemo(() => {
    if (!tutorial) {
      return (
        <TileParagraph>
          When students start completing work, we’ll list all the students you haven’t yet seen
          here, making it easy to keep up.
        </TileParagraph>
      );
    }

    return (
      <TileHeader>
        You don&apos;t have new answers to review for now
        <AiOutlineCheckCircle />
      </TileHeader>
    );
  }, [tutorial]);

  return (
    <Tile className={clsx(tutorial && styles.NewWorkEmptyTile_success)}>{tileContent}</Tile>
  );
};

export default NewWorkEmptyTile;
