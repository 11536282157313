import { Row } from 'react-table';
import { ReactElement } from 'react';
import clsx from 'clsx';
import { CellWithRowSpan } from '../../../../types/react-table-config';

const TableRowWithSpannedCells = <
  D extends Record<string, unknown> = Record<string, unknown>
>({
  row,
  className,
}: {
  row: Row<D>;
  className?: string;
}): ReactElement => {
  const rowCells = row.cells as CellWithRowSpan<D>[];
  const renderCells = rowCells
    // Render only unique cells, if isRowSpanned set it should be hidden
    .filter((cell) => !cell.isRowSpanned)
    .reduce((cells, cell) => {
      cells.push(
        <td rowSpan={cell.rowSpan} key={cell.column.id}>
          {cell.render('Cell')}
        </td>
      );
      return cells;
    }, [] as ReactElement[]);

  const rowProps = row.getRowProps();
  return (
    <tr {...rowProps} className={clsx(className, rowProps.className)} key={row.id}>
      {renderCells}
    </tr>
  );
};

export default TableRowWithSpannedCells;
