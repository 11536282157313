import { FC } from 'react';
import DatePicker from 'react-datepicker';
import { getDateInDays } from '../../../utils/dates_ranges_helper';
import { BindingCallback1 } from '../../../types';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';
import ActionButton from '../../Buttons/ActionButton';

interface PivotDatePickerProps {
  pivotDate: Date;
  academicTermTimespanDates: Date[];
  onPivotDataChange: BindingCallback1<{ newPivotDate: Date }>;
}

const PivotDatePicker: FC<PivotDatePickerProps> = ({
  pivotDate,
  academicTermTimespanDates,
  onPivotDataChange,
}) => {
  const highlightWithRanges: Record<string, Date[]>[] = [
    { 'react-datepicker__day--highlighted-custom-1': academicTermTimespanDates },
  ];

  const handleToDayClick = (day: 'first' | 'last'): void => {
    const targetDate =
      day === 'first'
        ? academicTermTimespanDates[0]
        : academicTermTimespanDates[academicTermTimespanDates.length - 1];
    const newPivotDate = new Date(targetDate);
    newPivotDate.setHours(pivotDate.getHours());
    newPivotDate.setMinutes(pivotDate.getMinutes());
    onPivotDataChange({ newPivotDate });
  };

  const handleChangeDateByDaysClick = (days: number): void => {
    const newPivotDate = getDateInDays(pivotDate, days);
    onPivotDataChange({ newPivotDate });
  };

  return (
    <div className={styles.PivotDatePicker}>
      <h3 className={styles.PivotDatePicker__Label}>Display as of: </h3>
      <DatePicker
        selected={pivotDate}
        highlightDates={highlightWithRanges}
        calendarStartDay={1}
        showTimeSelect
        timeIntervals={15}
        dateFormat="MM/dd/yyyy h:mm aa"
        showWeekNumbers
        shouldCloseOnSelect={false}
        popperPlacement="right-start"
        onChange={(newPivotDate: Date) => onPivotDataChange({ newPivotDate })}
        className={styles.PivotDatePicker__Input}
        popperClassName={styles.PivotDatePicker__Popper}
        calendarClassName={styles.PivotDatePicker__Calendar}
      />
      <div className={styles.PivotDatePicker__Controls}>
        {academicTermTimespanDates.length > 0 && (
          <ActionButton variant="dark" size="small" onClick={() => handleToDayClick('first')}>
            To first day
          </ActionButton>
        )}
        {academicTermTimespanDates.length > 0 && (
          <ActionButton variant="dark" size="small" onClick={() => handleToDayClick('last')}>
            To last day
          </ActionButton>
        )}
        <ActionButton
          variant="dark"
          size="small"
          onClick={() => handleChangeDateByDaysClick(-7)}
        >
          -1 week
        </ActionButton>
        <ActionButton
          variant="dark"
          size="small"
          onClick={() => handleChangeDateByDaysClick(7)}
        >
          +1 week
        </ActionButton>
        <ActionButton
          variant="dark"
          size="small"
          onClick={() => handleChangeDateByDaysClick(-1)}
        >
          -1 day
        </ActionButton>
        <ActionButton
          variant="dark"
          size="small"
          onClick={() => handleChangeDateByDaysClick(1)}
        >
          +1 day
        </ActionButton>
      </div>
    </div>
  );
};
export default PivotDatePicker;
