import { forwardRef, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import WeeklyOutreachTile from './WeeklyOutreachTile';
import WeeklyOutreachNote from './WeeklyOutreachNote';
import { selectUiActionByKey } from '../../../redux/selectors/ui_actions';
import { selectSnapshotsInRange } from '../../../redux/selectors/risks_snapshots';
import Section from '../../../components/Board/Section';
import SectionHeader from '../../../components/Board/Section/SectionHeader';
import { useCourseSnapshots } from '../../../redux/hooks/risks_snapshots/useCourseSnapshots';

const WeeklyOutreachSection = forwardRef<HTMLElement>((_, ref) => {
  const [isHidden, setHidden] = useState(false);

  const snapshots = useAppSelector(selectSnapshotsInRange);
  const isReachOutClicked = useAppSelector((state) =>
    selectUiActionByKey(state, 'OUTREACH_SECTION__REACH_OUT__CLICKED')
  );

  const {
    isLoading: isSnapshotsLoading,
    isFetching: isSnapshotsFetching,
    error: snapshotsError,
  } = useCourseSnapshots();

  const noteElement = useMemo(() => {
    const isSnapshotsAvailable = snapshots && snapshots.length > 0;
    return isSnapshotsAvailable && !isReachOutClicked ? <WeeklyOutreachNote /> : undefined;
  }, [snapshots, isReachOutClicked]);

  return (
    <>
      {!isHidden && (
        <Section
          header={
            <SectionHeader onClose={snapshotsError ? () => setHidden(true) : undefined}>
              Weekly outreach
            </SectionHeader>
          }
          tile={
            <WeeklyOutreachTile
              isLoading={isSnapshotsLoading}
              isFetching={isSnapshotsFetching}
              error={snapshotsError}
            />
          }
          note={noteElement}
          ref={ref}
        />
      )}
    </>
  );
});

export default WeeklyOutreachSection;
