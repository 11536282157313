import { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ActionButton, { ActionButtonProps } from '../ActionButton';
import styles from './styles.module.scss';

interface ActionButtonLinkProps extends ActionButtonProps {
  to: string;
  isHref?: boolean;
  target?: '_blank' | '_self';
  linkClassName?: string;
}

const ActionButtonLink: FC<ActionButtonLinkProps> = ({
  to,
  isHref = false,
  target = '_blank',
  linkClassName,
  children,
  ...buttonProps
}) => {
  const actionButton = <ActionButton {...buttonProps}>{children}</ActionButton>;
  const linkElementClassName = clsx(linkClassName, styles.ActionButtonLink);

  return isHref ? (
    <a href={to} target={target} className={linkElementClassName}>
      {actionButton}
    </a>
  ) : (
    <Link to={to} className={linkElementClassName}>
      {actionButton}
    </Link>
  );
};

export default ActionButtonLink;
