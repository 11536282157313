import { FC } from 'react';
import { ImUser } from 'react-icons/all';
import styles from './styles.module.scss';
import { useGetCurrentUserQuery } from '../../../redux/queries/authentication';

const User: FC = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  return (
    <div className={styles.User}>
      <ImUser />
      <span className={styles.User__Name}>{currentUser?.name}</span>
    </div>
  );
};

export default User;
