import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface TileParagraphProps {
  className?: string;
}

const TileParagraph: FC<TileParagraphProps> = ({ className, children }) => (
  <p className={clsx(className, styles.TileParagraph)}>{children}</p>
);

export default TileParagraph;
