import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface ActionsGroupProps {
  align?: 'left' | 'center' | 'right';
  className?: string;
}

const ActionsGroup: FC<ActionsGroupProps> = ({ align = 'left', className, children }) => (
  <div
    className={clsx(
      className,
      styles.ActionsGroup,
      align === 'left' && styles.ActionsGroup_left,
      align === 'center' && styles.ActionsGroup_center,
      align === 'right' && styles.ActionsGroup_right
    )}
  >
    {children}
  </div>
);

export default ActionsGroup;
