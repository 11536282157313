import { createSelector, EntityState } from '@reduxjs/toolkit';
import { messagesAdapter } from '../../entities_adapters/messages';
import { RootState } from '../../store';
import { Message } from '../../../types/message';
import { selectCourseId, selectCoursePivotDate } from '../application';
import { MessagesCoursesIndex } from '../../slices/messages';

export const selectCourseMessagesData = createSelector<
  RootState,
  number,
  MessagesCoursesIndex,
  EntityState<Message>
>(
  [selectCourseId, (state) => state.messages.coursesIndex],
  (courseId, messagesCourseIndex) => messagesCourseIndex[courseId].messagesEntities
);

export const {
  selectAll: selectAllMessages,
  selectById: selectMessageById,
  selectIds: selectMessagesIds,
} = messagesAdapter.getSelectors(selectCourseMessagesData);

// TODO Add selection of messages in range
export const selectMessagesBeforePivotDate = createSelector<
  RootState,
  Message[],
  string,
  Message[]
>([selectAllMessages, selectCoursePivotDate], (messages, pivotDate) =>
  messages.filter((message) => message.sentAt <= pivotDate)
);

export const selectMessagedUsersIds = createSelector<RootState, Message[], number[]>(
  [selectMessagesBeforePivotDate],
  (messages) => [...new Set(messages.map((message) => message.userId))]
);

export type UsersMessages = Record<number, Message[]>;
export const selectUsersMessagesEntities = createSelector<
  RootState,
  number[],
  Message[],
  UsersMessages
>([selectMessagedUsersIds, selectMessagesBeforePivotDate], (usersIds, messages) =>
  usersIds.reduce((usersMessages, userId) => {
    usersMessages[userId] = messages.filter((message) => message.userId === userId);
    return usersMessages;
  }, {} as UsersMessages)
);

export type UsersLastMessage = Record<number, Message>;
export const selectUsersLastMessageEntities = createSelector<
  RootState,
  UsersMessages,
  UsersLastMessage
>([selectUsersMessagesEntities], (usersMessages) =>
  Object.entries(usersMessages).reduce((lastMessages, [userId, messages]) => {
    lastMessages[+userId] = messages[messages.length - 1];
    return lastMessages;
  }, {} as UsersLastMessage)
);
