import { FC, useEffect, useMemo } from 'react';
import NewWorkChapterTile from './NewWorkChapterTile';
import { useAppSelector } from '../../../../redux/hooks/app/useAppSelector';
import {
  selectIndexedChaptersFamilyIds,
  selectUnreviewedAnswers,
} from '../../../../redux/selectors/answers';
import Tiles from '../../../../components/Board/Tiles';
import NewWorkEmptyTile from '../NewWorkEmptyTile';
import { TileLoadingProps } from '../../../../components/Board/Tile';
import { selectUiActionByKey } from '../../../../redux/selectors/ui_actions';
import { useAppDispatch } from '../../../../redux/hooks/app/useAppDispatch';
import { saveUiAction } from '../../../../redux/slices/ui_actions';

type NewWorkTilesProps = TileLoadingProps;

const NewWorkTiles: FC<NewWorkTilesProps> = ({ isLoading, isFetching, error }) => {
  const dispatch = useAppDispatch();

  const unreviewedAnswers = useAppSelector(selectUnreviewedAnswers);
  const indexedChaptersFamilyIds = useAppSelector(selectIndexedChaptersFamilyIds);

  const isAnswersSeen = useAppSelector((state) =>
    selectUiActionByKey(state, 'NEW_WORK_SECTION__ANSWERS__SEEN')
  );

  const chaptersTiles = useMemo(
    () =>
      indexedChaptersFamilyIds.map((chapterFamilyId) => (
        <NewWorkChapterTile key={chapterFamilyId} chapterFamilyId={chapterFamilyId} />
      )),
    [indexedChaptersFamilyIds]
  );

  useEffect(() => {
    /**
     * Save the fact that we've already seen answers in the tile
     */
    if (isAnswersSeen) return;
    if (!unreviewedAnswers.length || !indexedChaptersFamilyIds.length) return;
    dispatch(saveUiAction('NEW_WORK_SECTION__ANSWERS__SEEN'));
  }, [dispatch, indexedChaptersFamilyIds.length, isAnswersSeen, unreviewedAnswers.length]);

  return (
    <Tiles isLoading={isLoading} isFetching={isFetching} error={error}>
      {!unreviewedAnswers.length || !indexedChaptersFamilyIds.length ? (
        <NewWorkEmptyTile tutorial={isAnswersSeen} />
      ) : (
        chaptersTiles
      )}
    </Tiles>
  );
};

export default NewWorkTiles;
