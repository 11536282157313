import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface TileHeaderProps {
  className?: string;
}

const TileHeader: FC<TileHeaderProps> = ({ className, children }) => {
  return (
    <div className={clsx(className, styles.TileHeader)}>
      <h3>{children}</h3>
    </div>
  );
};

export default TileHeader;
