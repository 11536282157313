import { FC, Ref, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { FaBell } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import Loader from 'react-loader-spinner';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import ActionButton from '../../Buttons/ActionButton';
import Board from '../../Board';
import NotificationSection from './NotificationSection';
import { useWeeklyOutreachNotification } from './useWeeklyOutreachNotification';
import { useNewWorkNotification } from './useNewWorkNotification';
import { useMessagesNotification } from './useMessagesNotification';
import EmptyNotificationSection from './EmptyNotificationSection';
import { BindingAction } from '../../../types';
import styles from './styles.module.scss';

export interface NotificationData {
  link: string;
  title: string;
  message: string;
  isHighlighted: boolean;
  isAllResolved: boolean;
  isFetching: boolean;
  error?: FetchBaseQueryError | SerializedError;
}

export type PopupNotification = [NotificationData | null, BindingAction];

const Notifications: FC = () => {
  const popupRef = useRef<PopupActions | null>();
  const closePopup = (): void => popupRef?.current?.close();

  useEffect(() => {
    window.addEventListener('scroll', closePopup);
    return () => window.removeEventListener('scroll', closePopup);
  }, []);

  const handleNotificationClick =
    (originalHandler: BindingAction): BindingAction =>
    () => {
      closePopup();
      originalHandler();
    };

  const [weeklyOutreachNotificationData, handleWeeklyOutreachReset] =
    useWeeklyOutreachNotification();
  const [newWorkNotificationData, handleNewWorkReset] = useNewWorkNotification();
  const [messagesNotificationData, handleMessagesReset] = useMessagesNotification();

  const isBadgeFetching =
    weeklyOutreachNotificationData?.isFetching || newWorkNotificationData?.isFetching;
  const isBadgeHighlighted =
    weeklyOutreachNotificationData?.isHighlighted || newWorkNotificationData?.isHighlighted;

  const createTriggerElement = useCallback(
    (isOpened: boolean) => (
      <ActionButton
        className={clsx(
          styles.Notifications__Trigger,
          isOpened && styles.Notifications__Trigger_open
        )}
      >
        {isBadgeFetching && (
          <span className={styles.Notifications__Loader}>
            <Loader type="Oval" />
          </span>
        )}
        {isBadgeHighlighted && <span className={styles.Notifications__Badge} />}
        <FaBell />
      </ActionButton>
    ),
    [isBadgeHighlighted, isBadgeFetching]
  );

  return (
    <Popup
      trigger={createTriggerElement}
      offsetY={18}
      ref={popupRef as Ref<PopupActions> | undefined}
      disabled={isBadgeFetching}
      className="notifications"
    >
      <Board className={styles.Notifications__Board}>
        {weeklyOutreachNotificationData && (
          <NotificationSection
            notificationData={weeklyOutreachNotificationData}
            onNotificationReset={handleNotificationClick(handleWeeklyOutreachReset)}
          />
        )}
        {newWorkNotificationData && (
          <NotificationSection
            notificationData={newWorkNotificationData}
            onNotificationReset={handleNotificationClick(handleNewWorkReset)}
          />
        )}
        {messagesNotificationData && (
          <NotificationSection
            notificationData={messagesNotificationData}
            onNotificationReset={handleNotificationClick(handleMessagesReset)}
            showSeparator={false}
          />
        )}
        {!weeklyOutreachNotificationData &&
          !newWorkNotificationData &&
          !messagesNotificationData && <EmptyNotificationSection />}
      </Board>
    </Popup>
  );
};

export default Notifications;
