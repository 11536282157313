import { FC, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { BindingAction, BindingCallback1 } from '../../../../../types';
import { useAppSelector } from '../../../../../redux/hooks/app/useAppSelector';
import { selectStudentsUsersEntities } from '../../../../../redux/selectors/course';
import { generateMailtoLink } from '../../../../../utils/mailto_generator';
import InputGroup from '../../../../../components/Inputs/InputGroup';
import ActionsGroup from '../../../../../components/Buttons/ActionsGroup';
import ActionButton from '../../../../../components/Buttons/ActionButton';
import Tile from '../../../../../components/Board/Tile';

interface OutreachMessageFormProps {
  messageSubject: string;
  selectedUsersIds: number[];
  onSendClick: BindingCallback1<{ messageSubject: string; messageText: string }>;
  onCancelClick: BindingAction;
}

const MessageFormTile: FC<OutreachMessageFormProps> = ({
  messageSubject,
  selectedUsersIds,
  onSendClick,
  onCancelClick,
}) => {
  const [messageText, setMessageText] = useState('');

  const enrollmentsUsersEntities = useAppSelector(selectStudentsUsersEntities);
  const selectedUsers = useMemo(
    () =>
      selectedUsersIds
        .map((selectedUserId) => enrollmentsUsersEntities[selectedUserId])
        .filter((user) => !!user),
    [enrollmentsUsersEntities, selectedUsersIds]
  );

  const usersNames = useMemo(
    () => selectedUsers.map((user) => `${user.first_name} ${user.last_name}`),
    [selectedUsers]
  );

  // const userEmails = useMemo(() => selectedUsers.map((user) => user.email),
  // [selectedUsers]);
  //
  // const mailtoLink = useMemo(
  //   () =>
  //     generateMailtoLink({
  //       bcc: userEmails,
  //       subject: messageSubject,
  //       body: messageText,
  //     }),
  //   [messageSubject, messageText, userEmails]
  // );

  const handleSendClick = useCallback(() => {
    setMessageText('');
    onSendClick({ messageSubject, messageText });
  }, [messageSubject, messageText, onSendClick]);

  return (
    <Tile className={styles.MessageFormTile}>
      <form className={styles.MessageFormTile__Form}>
        {usersNames.length > 0 && (
          <InputGroup
            value={usersNames.join(', ')}
            disabled
            className={styles.MessageFormTile__Names}
          />
        )}
        <InputGroup
          textarea
          align="start"
          placeholder={
            !usersNames.length
              ? 'Please select an addressee from the table above first'
              : 'Write your message here...'
          }
          disabled={!usersNames.length}
          required
          value={messageText}
          onTextChange={(text) => setMessageText(text)}
          className={clsx(!usersNames.length && styles.MessageFormTile__Message_disabled)}
        />

        <ActionsGroup align="right">
          <ActionButton variant="secondary" size="large" onClick={onCancelClick}>
            Cancel
          </ActionButton>
          {usersNames.length > 0 && (
            <ActionButton variant="primary" size="large" onClick={handleSendClick}>
              Post comment
            </ActionButton>
          )}
        </ActionsGroup>
      </form>
    </Tile>
  );
};

export default MessageFormTile;
