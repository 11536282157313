import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large' | 'huge';
  variant?: 'primary' | 'secondary' | 'dark' | 'none';
  weight?: 'normal' | 'bold';
}

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      size = 'medium',
      variant = 'none',
      weight = 'normal',
      className,
      children,
      type = 'button',
      ...buttonProps
    },
    ref
  ) => {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...buttonProps}
        ref={ref}
        className={clsx(
          className,
          styles.ActionButton,
          size === 'small' && styles.ActionButton_small,
          size === 'medium' && styles.ActionButton_medium,
          size === 'large' && styles.ActionButton_large,
          size === 'huge' && styles.ActionButton_huge,
          weight === 'bold' && styles.ActionButton_bold,
          variant === 'primary' && styles.ActionButton_primary,
          variant === 'secondary' && styles.ActionButton_secondary,
          variant === 'dark' && styles.ActionButton_dark,
          variant === 'none' && styles.ActionButton_none
        )}
      >
        {children}
      </button>
    );
  }
);

export default ActionButton;
