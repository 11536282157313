import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { API_HOST } from '../../../constants';

export interface ApplicationState {
  /**
   * Defines currently viewing course id
   */
  courseId: number;

  /**
   * Defines currently authenticated user id
   */
  userId: number;

  /**
   * Defines long-living JWT token which is used for the requests against the apiHost
   */
  accessToken: string;

  /**
   * Defines urls for the Core API and required applications
   */
  api: ApplicationApi;

  /**
   * Defines course-specific information which should be persisted separately
   */
  coursesIndex: ApplicationCoursesIndex;
}

export interface ApplicationApi {
  host: string;
  analyticsHost: string;
}

export interface ApplicationCoursesIndex {
  [courseId: number]: {
    /**
     * Defines current date for the instructor, can be changed from outside
     */
    pivotDate: string;
  };
}

const initialState = {
  coursesIndex: {},
  api: {
    host: API_HOST,
  },
} as ApplicationState;

const httpsApiUrl = (url: string): string => {
  const normalizedUrl = url.replace(/^(https?:\/\/)/, '');
  return `https://${normalizedUrl}`;
};

export const applicationsSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateCourseId: (state, action: PayloadAction<{ courseId: number }>) => {
      const { courseId } = action.payload;
      state.courseId = courseId;
      state.coursesIndex[courseId] = state.coursesIndex[courseId] || {};
    },
    updateUserId: (state, action: PayloadAction<{ userId: number }>) => {
      state.userId = action.payload.userId;
    },
    updatePivotDate: (
      state,
      action: PayloadAction<{
        pivotDate: string;
      }>
    ) => {
      const { pivotDate } = action.payload;
      const { courseId, coursesIndex } = state;
      coursesIndex[courseId].pivotDate = pivotDate;
    },
    updateAccessToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken;
    },
    updateApi: (state, action: PayloadAction<Partial<ApplicationApi>>) => {
      const { host, analyticsHost } = action.payload;
      state.api.host = host ? httpsApiUrl(host) : state.api.host;
      state.api.analyticsHost = analyticsHost
        ? httpsApiUrl(analyticsHost)
        : state.api.analyticsHost;
    },
  },
});

export const { updateCourseId, updateUserId, updatePivotDate, updateAccessToken, updateApi } =
  applicationsSlice.actions;

export const saveApplicationReducer: any = createBlacklistFilter('application', [
  'courseId',
  'api.host',
]);

// Preserve initial state of the nested object
export const loadApplicationReducer = createTransform<any, any>(
  null,
  (out) => ({
    ...initialState,
    ...out,
    api: { ...initialState.api, ...out.api },
  }),
  { whitelist: ['application'] }
);

export default applicationsSlice.reducer;
