import { EntityState } from '@reduxjs/toolkit';
import { RisksSnapshot, RisksSnapshotDatesIndex } from '../../../types';
import {
  risksSnapshotsDatesIndicesAdapter,
  risksSnapshotsDatesIndicesInitialState,
} from '../../entities_adapters/risks_snapshots';

export const transformSnapshotsDatesIndices = (
  response: unknown
): EntityState<RisksSnapshotDatesIndex> => {
  const responseHistories = (response as { histories: unknown }).histories;
  const snapshotsDatesIndices = responseHistories as RisksSnapshotDatesIndex[];
  return risksSnapshotsDatesIndicesAdapter.setMany(
    risksSnapshotsDatesIndicesInitialState,
    snapshotsDatesIndices
  );
};

export const transformSnapshot = (response: unknown): RisksSnapshot => {
  const responseSnapshot = (response as { snapshot: unknown }).snapshot;
  return responseSnapshot as RisksSnapshot;
};
