import {
  createSlice,
  Dictionary,
  EntityState,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { MeasurementDimensionKeys, RiskCategoryKeys, RisksSnapshot } from '../../../types';
import {
  createSnapshotRisksCutoffs,
  createSnapshotsCategorizationIndex,
  createSnapshotsStatsIndex,
} from '../../services/risks_assessments';
import {
  risksSnapshotsAdapter,
  risksSnapshotsInitialState,
} from '../../entities_adapters/risks_snapshots';
import { isDateGreater } from '../../../utils/dates_comparer';
import { getDateInWeeks } from '../../../utils/dates_ranges_helper';
import { getCourseSnapshotsByIdsThunk } from '../../thunks/risks_snapshots';
import { toSerializableError } from '../../../utils/error_converter';
import { Enrollment } from '../../../types/enrollment';

export interface RisksSnapshotsState {
  /**
   * Threshold expressed in the {@link https://en.wikipedia.org/wiki/Standard_score Z-SCORE}
   * for risk low/high cutoff
   */
  risksThresholds: RisksThresholds;

  /**
   * Stores visible range for the snapshots.
   * Used in the selection of the snapshots and their fetching
   */
  snapshotsRange: SnapshotsRange;

  /**
   * Stores mapping between snapshots and their statistical measurements.
   * Calculated only once and then statistics are reused to recategorize assessments.
   * All snapshots are between current academic term start and end dates.
   */
  snapshotsStatsIndex: SnapshotsStatsIndex;

  /**
   * Stores mapping between snapshots and their assessments grouped by risks.
   * All snapshots are between current academic term start and end dates
   */
  snapshotsCategorizationIndex: SnapshotsCategorizationIndex;

  snapshotsEntities: SnapshotsEntitiesState;
}

export interface SnapshotsRange {
  rangeStart: string;
  rangeEnd: string;
}

export interface SnapshotsEntitiesState extends EntityState<RisksSnapshot> {
  requestedSnapshotsIds: number[];
  isLoading: boolean;
  isFetching: boolean;
  isUninitialized: boolean;
  error?: SerializedError;
}

export interface SnapshotsStatsIndex {
  [snapshotId: number]: SnapshotStatsIndex;
}

export interface SnapshotStatsIndex {
  snapshotId: number;
  assessmentsAmount: number;
  dimensionsStats: DimensionsStats;
  risksCutoffs: RisksCutoffs;
}

export type DimensionsStats = {
  [dimensionKey in MeasurementDimensionKeys]: DimensionStats;
};

export interface DimensionStats {
  dimension: MeasurementDimensionKeys;
  measurementsAmount: number;
  mean: number; // EXPRESSED AS LOG10 VALUE
  stddev: number; // EXPRESSED AS LOG10 VALUE
}

export type RisksCutoffs = {
  [riskCategory in RiskCategoryKeys]: RiskCutoffs;
};

export interface RiskCutoffs {
  lowCutoff?: number;
  highCutoff?: number;
}

export interface SnapshotsCategorizationIndex {
  [snapshotId: number]: SnapshotCategorizationIndex;
}

export interface SnapshotCategorizationIndex {
  snapshotId: number;
  categorizedAssessments: CategorizedRisksAssessments;
  assessmentsAmount: number;
  offTrackAssessmentsAmount: number;
}

export type CategorizedRisksAssessments = {
  [riskCategoryKey in RiskCategoryKeys]: CategorizedRiskAssessments;
};

export interface CategorizedRiskAssessments {
  riskCategory: RiskCategoryKeys;
  assessmentsUsersIds: number[];
}

export interface SnapshotsCategorizationAmountsIndex {
  [snapshotId: number]: SnapshotCategorizationAmountsIndex;
}

export interface SnapshotCategorizationAmountsIndex {
  snapshotId: number;
  categorizedAssessmentsAmounts: CategorizedRisksAssessmentsAmounts;
  assessmentsAmount: number;
  offTrackAssessmentsAmount: number;
}

export type CategorizedRisksAssessmentsAmounts = {
  [riskCategoryKey in RiskCategoryKeys]: number;
};

export type RisksThresholds = {
  [riskCategory in RiskCategoryKeys]: RiskThresholds;
};

export interface RiskThresholds {
  low?: number;
  high?: number;
}

const initialState = {
  snapshotsEntities: risksSnapshotsInitialState,
  snapshotsRange: {},
  snapshotsStatsIndex: {},
  snapshotsCategorizationIndex: {},
  risksThresholds: {
    'low-completion': {
      low: 1,
    },
    'low-score': {
      low: 1,
    },
    'no-sign-in': {
      low: 7,
    },
  },
} as RisksSnapshotsState;

export const risksSnapshotsSlice = createSlice({
  name: 'risksSnapshots',
  initialState,
  reducers: {
    updateSnapshotsRange: (
      state,
      action: PayloadAction<{
        pivotDate: string;
        firstClassDate: string;
        lastClassDate: string;
      }>
    ) => {
      const { pivotDate, firstClassDate, lastClassDate } = action.payload;

      /**
       * When the last class date is on Sunday, we cannot see the full report for the AT.
       * Therefore, we should be able to navigate beyond the lastClassDate for a week
       */
      const lastViewableWeekDate = getDateInWeeks(lastClassDate, 1);
      const rangeEnd = isDateGreater(pivotDate, lastViewableWeekDate)
        ? lastViewableWeekDate.toISOString()
        : pivotDate;
      state.snapshotsRange = {
        rangeStart: firstClassDate,
        rangeEnd,
      };
    },
    appendRequestedSnapshotsIds: (
      state,
      action: PayloadAction<{
        snapshotsIds: number[];
      }>
    ) => {
      const { snapshotsIds } = action.payload;
      const { snapshotsEntities } = state;
      const { requestedSnapshotsIds } = snapshotsEntities;
      snapshotsEntities.requestedSnapshotsIds = [
        ...new Set([...requestedSnapshotsIds, ...snapshotsIds]),
      ];
    },
    updateRiskCategoryThresholds: (
      state,
      action: PayloadAction<{
        riskCategory: RiskCategoryKeys;
        riskThresholds: RiskThresholds;
      }>
    ) => {
      if (!Object.keys(state.snapshotsStatsIndex).length) return;

      const { riskCategory, riskThresholds } = action.payload;

      state.risksThresholds[riskCategory] = riskThresholds;

      Object.values(state.snapshotsStatsIndex).forEach((snapshotStatsIndex) => {
        snapshotStatsIndex.risksCutoffs = createSnapshotRisksCutoffs(
          snapshotStatsIndex.dimensionsStats,
          state.risksThresholds
        );
      });

      const snapshots = Object.values(state.snapshotsEntities.entities).filter(
        (snapshot): snapshot is RisksSnapshot => !!snapshot
      );

      state.snapshotsCategorizationIndex = createSnapshotsCategorizationIndex(
        snapshots,
        state.snapshotsStatsIndex
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseSnapshotsByIdsThunk.pending, (state) => {
        const { snapshotsEntities } = state;
        snapshotsEntities.isFetching = true;
        if (snapshotsEntities.isUninitialized) {
          snapshotsEntities.isLoading = true;
        }
        snapshotsEntities.isUninitialized = false;
      })

      /**
       * WARNING
       * CATEGORIZATION WILL ONLY PROPERLY WORK FOR ADDING SNAPSHOTS IN THE CHRONOLOGICAL ORDER
       * E.g. Now we cannot load week 1 after the week 2 was loaded because it will require
       * to recalculate the stats and categorization for all already added snapshots
       */
      .addCase(
        getCourseSnapshotsByIdsThunk.fulfilled,
        (
          state,
          action: PayloadAction<{
            snapshots: RisksSnapshot[];
            enrollments: Dictionary<Enrollment>;
          }>
        ) => {
          const { snapshotsEntities, snapshotsStatsIndex, risksThresholds } = state;

          snapshotsEntities.isLoading = false;
          snapshotsEntities.isFetching = false;

          const { snapshots: newSnapshots, enrollments } = action.payload;

          // Includes only the assessments which have user id available in the enrollments
          const newRosterSnapshots = newSnapshots.map(
            (snapshot) =>
              ({
                ...snapshot,
                assessments: {
                  assessments: snapshot.assessments.assessments.filter(
                    (assessment) => enrollments[assessment.user.id]
                  ),
                },
              } as RisksSnapshot)
          );

          risksSnapshotsAdapter.setMany(state.snapshotsEntities, newRosterSnapshots);

          const newSnapshotsStatsIndex = createSnapshotsStatsIndex({
            snapshots: newRosterSnapshots,
            snapshotsStatsIndex,
            risksThresholds,
          });

          state.snapshotsStatsIndex = {
            ...state.snapshotsStatsIndex,
            ...newSnapshotsStatsIndex,
          };

          const newSnapshotsCategorizationIndex = createSnapshotsCategorizationIndex(
            newRosterSnapshots,
            newSnapshotsStatsIndex
          );

          state.snapshotsCategorizationIndex = {
            ...state.snapshotsCategorizationIndex,
            ...newSnapshotsCategorizationIndex,
          };
        }
      )
      .addCase(getCourseSnapshotsByIdsThunk.rejected, (state, action) => {
        const { snapshotsEntities } = state;

        snapshotsEntities.isLoading = false;
        snapshotsEntities.isFetching = false;

        const serializableError = toSerializableError(action.payload);
        const { message } = action.error;

        snapshotsEntities.error = {
          name: message,
          message: serializableError?.message,
          code: serializableError?.code,
        };
      });
  },
});

export const {
  updateSnapshotsRange,
  appendRequestedSnapshotsIds,
  updateRiskCategoryThresholds,
} = risksSnapshotsSlice.actions;
export default risksSnapshotsSlice.reducer;
