import { createSelector, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Answer } from '../../../types';
import { NewWorkReaction } from '../../slices/notifications';
import { selectCourseNewWorkReaction } from '../notifications';
import { answersApi } from '../../queries/answers';
import { answersAdapter, answersInitialState } from '../../entities_adapters/answers';
import { AnswersStateThresholds } from '../../slices/answers';
import { isDateLessThanDays } from '../../../utils/dates_comparer';
import { selectCourseId, selectCoursePivotDate } from '../application';

export * from './select_answers_table_index';

const selectCourseAnswersResult = createSelector<RootState, number, any>(
  [selectCourseId],
  (courseId) => answersApi.endpoints.getAnswersByCourseId.select(courseId)
);

const selectAnswersData = createSelector<
  RootState,
  { data?: EntityState<Answer> },
  EntityState<Answer> | undefined
>(
  [(state) => selectCourseAnswersResult(state)(state)],
  (courseNewAnswersResult) => courseNewAnswersResult.data
);

export const {
  selectAll: selectAllAnswers,
  selectById: selectAnswerById,
  selectIds: selectAnswersIds,
} = answersAdapter.getSelectors(
  (state: RootState) => selectAnswersData(state) || answersInitialState
);

export const selectAnswersBeforePivotDate = createSelector<
  RootState,
  Answer[],
  string,
  Answer[]
>([selectAllAnswers, selectCoursePivotDate], (answers, pivotDate) =>
  answers.filter((answer) => answer.created_at <= pivotDate)
);

// TODO REMOVE CHECK FOR THE CREATION DATE, IT'S ONLY FOR THE PROTOTYPE
export const selectUnreviewedAnswers = createSelector<
  RootState,
  Answer[],
  string,
  AnswersStateThresholds,
  Answer[]
>(
  [
    selectAnswersBeforePivotDate,
    selectCoursePivotDate,
    (state) => state.answers.answersStateThresholds,
  ],
  (answers, pivotDate, answersStateThresholds) =>
    answers.filter((answer) => {
      return isDateLessThanDays(
        answer.created_at,
        pivotDate,
        answersStateThresholds.reviewedInDays
      );
    })
);

export const selectUnreviewedAnswersIds = createSelector<RootState, Answer[], number[]>(
  [selectUnreviewedAnswers],
  (unreviewedAnswers) => unreviewedAnswers.map((answer) => answer.id)
);

// TODO REMOVE CHECK FOR THE CREATION DATE, IT'S ONLY FOR THE PROTOTYPE
export const selectUnseenAnswers = createSelector<
  RootState,
  Answer[],
  string,
  AnswersStateThresholds,
  Answer[]
>(
  [
    selectAnswersBeforePivotDate,
    selectCoursePivotDate,
    (state) => state.answers.answersStateThresholds,
  ],
  (answers, pivotDate, answersStateThresholds) =>
    answers.filter((answer) => {
      return isDateLessThanDays(
        answer.created_at,
        pivotDate,
        answersStateThresholds.seenInDays
      );
    })
);

export const selectUnseenAnswersIds = createSelector<RootState, Answer[], number[]>(
  [selectUnseenAnswers],
  (unseenAnswers) => unseenAnswers.map((answer) => answer.id)
);

export const selectUnseenNotReactedAnswers = createSelector<
  RootState,
  Answer[],
  NewWorkReaction | undefined,
  Answer[]
>([selectUnseenAnswers, selectCourseNewWorkReaction], (answers, courseNewWorkReaction) =>
  answers.filter((answer) => !courseNewWorkReaction?.answersIds.includes(answer.id))
);

export const selectUnseenNotReactedAnswersIds = createSelector<RootState, Answer[], number[]>(
  [selectUnseenNotReactedAnswers],
  (unseenNotReactedAnswers) => unseenNotReactedAnswers.map((answer) => answer.id)
);
