export const API = {
  ACCESS_TOKEN: `/api/auth/v1/token`,
  CURRENT_USER_INFO: `/api/users/v1/info`,
  CURRENT_USER_COURSES: `/outreach/courses`,
  COURSE: (courseId: number): string => `/outreach/courses/${courseId}`,
  WEBTEXT_MANIFEST: `/api/courses/v1/webtext/manifest`,
  COMPLETION: `/api/courses/v1/page_completions/summary`,
  RISKS_SNAPSHOTS_DATES_INDICES: (courseId: number): string =>
    `/outreach/courses/${courseId}/risk_histories`,
  RISKS_SNAPSHOT: (courseId: number, snapshotId: number): string =>
    `/outreach/courses/${courseId}/risk_histories/${snapshotId}`,
  ANSWERS: (courseId: number): string => `/outreach/courses/${courseId}/answers`,
  ANSWER: (answerId: number): string => `/answers/${answerId}`,
  ACADEMIC_TERM: (academicTermId: number): string =>
    `/api/schools/v1/academic_terms/${academicTermId}`,
  SCHOOL: (schoolId: number): string => `/api/schools/v1/schools/${schoolId}`,
  ENROLLMENTS: `/api/courses/v1/enrollments`,
  JOBS_RESULTS: (jobId: string): string => `/jobs/${jobId}/results`,
  ANALYTICS_JWT_AUTH: (courseId: number): string => `/courses/${courseId}/analytics2_jwt`,
};
