import { createSelector, Dictionary, EntityId, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { coursesApi } from '../../queries/courses';
import { Enrollment, EnrollmentUser } from '../../../types/enrollment';
import { enrollmentsAdapter, enrollmentsInitialState } from '../../entities_adapters/courses';
import { selectCourseId } from '../application';

const selectCourseEnrollmentsResult = createSelector<RootState, number, any>(
  [selectCourseId],
  (courseId) => coursesApi.endpoints.getEnrollmentsByCourseId.select(courseId)
);

const selectEnrollmentsData = createSelector<
  RootState,
  { data?: EntityState<Enrollment> },
  EntityState<Enrollment> | undefined
>(
  [(state) => selectCourseEnrollmentsResult(state)(state)],
  (courseNewAnswersResult) => courseNewAnswersResult.data
);

export const {
  selectAll: selectAllEnrollments,
  selectById: selectEnrollmentById,
  selectIds: selectEnrollmentsIds,
  selectEntities: selectEnrollmentsEntities,
} = enrollmentsAdapter.getSelectors(
  (state: RootState) => selectEnrollmentsData(state) || enrollmentsInitialState
);

export const selectAllEnrollmentsUsers = createSelector<
  RootState,
  Enrollment[],
  EnrollmentUser[]
>([selectAllEnrollments], (enrollments) => enrollments.map((enrollment) => enrollment.user));

export const selectEnrollmentsUsersEntities = createSelector<
  RootState,
  Dictionary<Enrollment>,
  Record<number, EnrollmentUser>
>([selectEnrollmentsEntities], (enrollmentsEntities) =>
  Object.values(enrollmentsEntities).reduce((usersEntities, enrollmentEntity) => {
    if (!enrollmentEntity?.user) return usersEntities;

    const { user } = enrollmentEntity;
    usersEntities[user.id] = user;
    return usersEntities;
  }, {} as Record<string, EnrollmentUser>)
);

export const selectEnrollmentUserById = createSelector<
  RootState,
  EntityId,
  Enrollment | undefined,
  EnrollmentUser | undefined
>([selectEnrollmentById], (enrollment) => enrollment?.user);

export const selectStudentsEnrollmentsIds = createSelector<RootState, Enrollment[], number[]>(
  [selectAllEnrollments],
  (enrollments) =>
    enrollments
      .filter((enrollment) => enrollment.roles.includes('student'))
      .map((enrollment) => enrollment.user.id)
);

export const selectAllStudentsUsers = createSelector<
  RootState,
  Record<number, EnrollmentUser>,
  number[],
  EnrollmentUser[]
>(
  [selectEnrollmentsUsersEntities, selectStudentsEnrollmentsIds],
  (enrollmentsUsersEntities, studentsEnrollmentsIds) =>
    studentsEnrollmentsIds.map((enrollmentId) => enrollmentsUsersEntities[enrollmentId])
);

export const selectStudentsUsersEntities = createSelector<
  RootState,
  Record<number, EnrollmentUser>,
  number[],
  Record<number, EnrollmentUser>
>(
  [selectEnrollmentsUsersEntities, selectStudentsEnrollmentsIds],
  (enrollmentsUsersEntities, studentsEnrollmentsIds) =>
    Object.fromEntries(
      studentsEnrollmentsIds.map((enrollmentId) => [
        enrollmentId,
        enrollmentsUsersEntities[enrollmentId],
      ])
    )
);
