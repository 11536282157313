import {
  SnapshotCategorizationIndex,
  SnapshotsCategorizationIndex,
  SnapshotsStatsIndex,
  SnapshotStatsIndex,
} from '../../slices/risks_snapshots';
import { RisksSnapshot } from '../../../types';
import { categorizeAssessmentsByRisks } from './assessments_risks_categorizer';

export const aggregateSnapshotCategorizationIndex = (
  snapshot: RisksSnapshot,
  snapshotStatsIndex: SnapshotStatsIndex
): SnapshotCategorizationIndex => {
  const {
    id: snapshotId,
    assessments: { assessments },
  } = snapshot;

  const { categorizedAssessments, offTrackAssessmentsAmount } = categorizeAssessmentsByRisks(
    snapshot,
    snapshotStatsIndex
  );

  return {
    snapshotId,
    categorizedAssessments,
    assessmentsAmount: assessments.length,
    offTrackAssessmentsAmount,
  };
};

export const createSnapshotsCategorizationIndex = (
  snapshots: RisksSnapshot[],
  snapshotsStatsIndex: SnapshotsStatsIndex
): SnapshotsCategorizationIndex =>
  snapshots.reduce((categorizationIndex, snapshot) => {
    const { id: snapshotId } = snapshot;
    categorizationIndex[snapshotId] = aggregateSnapshotCategorizationIndex(
      snapshot,
      snapshotsStatsIndex[snapshotId]
    );
    return categorizationIndex;
  }, {} as SnapshotsCategorizationIndex);
