import { useCallback, useMemo } from 'react';
import log from 'loglevel';
import { getAcademicTermClassWeeks } from '../../../../redux/services/academic_terms';
import { AssessmentsGraphBarsStack } from './AssessmentsGraph';
import { useAppSelector } from '../../../../redux/hooks/app/useAppSelector';
import {
  selectAllStudentsUsers,
  selectCourseAcademicTerm,
} from '../../../../redux/selectors/course';
import {
  selectSnapshotsCategorizationAmountsIndex,
  selectSnapshotsInRange,
} from '../../../../redux/selectors/risks_snapshots';

export const useAssessmentsGraphData = (): AssessmentsGraphBarsStack[] => {
  const academicTerm = useAppSelector(selectCourseAcademicTerm);
  const snapshots = useAppSelector(selectSnapshotsInRange);
  const snapshotsCategorizationAmountsIndex = useAppSelector(
    selectSnapshotsCategorizationAmountsIndex
  );
  const allEnrollmentsUsers = useAppSelector(selectAllStudentsUsers);

  const weeksInAcademicTerm = useMemo(
    () => (!academicTerm ? 0 : getAcademicTermClassWeeks(academicTerm)),
    [academicTerm]
  );

  const generatePastSnapshotsAssessmentsStacks =
    useCallback((): AssessmentsGraphBarsStack[] => {
      if (!Object.keys(snapshotsCategorizationAmountsIndex).length) return [];

      return snapshots.map((snapshot) => {
        const { id: snapshotId, week: snapshotWeek, snapshot_ended_at } = snapshot;

        const snapshotCategorizationIndex = snapshotsCategorizationAmountsIndex[snapshot.id];
        if (!snapshotCategorizationIndex) {
          log.warn(
            `No categorization index found for the snapshot ${snapshotId} on week ${snapshotWeek}`
          );
        }

        return {
          week: snapshotWeek,
          snapshot_ended_at,
          ...(snapshotCategorizationIndex?.categorizedAssessmentsAmounts || {}),
        };
      });
    }, [snapshots, snapshotsCategorizationAmountsIndex]);

  /**
   * Generates empty bars for the future academic term class weeks.
   * Puts all enrolled students as the 'never-signed-in'
   */
  const generateFutureSnapshotsAssessmentsStacks = useCallback(
    (pastBarsStacksAmount: number): AssessmentsGraphBarsStack[] => {
      const futureSnapshots = [];
      for (
        let futureWeek = pastBarsStacksAmount;
        futureWeek < weeksInAcademicTerm;
        futureWeek++
      ) {
        futureSnapshots.push({
          week: futureWeek + 1,
          snapshot_ended_at: '',
          'never-signed-in': allEnrollmentsUsers.length,
        });
      }
      return futureSnapshots;
    },
    [allEnrollmentsUsers.length, weeksInAcademicTerm]
  );

  return useMemo(() => {
    const pastBarsStacks = generatePastSnapshotsAssessmentsStacks();
    const pastBarsStacksAmount = pastBarsStacks.length;
    const futureBarsStacks =
      pastBarsStacksAmount > 0
        ? generateFutureSnapshotsAssessmentsStacks(pastBarsStacks.length)
        : [];

    return [...pastBarsStacks, ...futureBarsStacks];
  }, [generateFutureSnapshotsAssessmentsStacks, generatePastSnapshotsAssessmentsStacks]);
};
