import { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import { selectCourseId } from '../../redux/selectors/application';
import { updateCourseId } from '../../redux/slices/application';
import { useAppDispatch } from '../../redux/hooks/app/useAppDispatch';
import { useCourse } from '../../redux/hooks/courses/useCourse';
import ScreenLoader from '../../components/ScreenLoader';
import { useWebtextManifest } from '../../redux/hooks/courses/useWebtextManifest';
import { useEnrollments } from '../../redux/hooks/courses/useEnrollments';
import { useCourseSnapshotsDatesIndices } from '../../redux/hooks/risks_snapshots/useCourseSnapshotsDatesIndices';
import ControlsBar from '../../components/ControlsBar';
import { useGetCurrentUserQuery } from '../../redux/queries/authentication';
import Sidebar from '../../components/Sidebar';
import styles from './styles.module.scss';
import ScreenError from '../../components/ScreenError';

interface TodoAppProps {
  includeNavbar?: boolean;
  includeSidebar?: boolean;
}

const TodoApp: FC<TodoAppProps> = ({
  includeNavbar = true,
  includeSidebar = true,
  children,
}) => {
  const dispatch = useAppDispatch();

  const courseId = useAppSelector(selectCourseId);
  const { isLoading: isCourseLoading, error: courseError } = useCourse();
  const { isLoading: isUserLoading, error: userError } = useGetCurrentUserQuery();
  const { isLoading: isManifestLoading, error: manifestError } = useWebtextManifest();
  const { isLoading: isEnrollmentsLoading, error: enrollmentsError } = useEnrollments();
  const { isLoading: isSnapshotsDatesLoading, error: snapshotsDatesError } =
    useCourseSnapshotsDatesIndices();

  const { courseId: paramsCourseId } = useParams<{ courseId?: string }>();
  const isParamsCourseIdValid = paramsCourseId && Number.isInteger(+paramsCourseId);
  useEffect(() => {
    if (isParamsCourseIdValid && !courseId) {
      dispatch(updateCourseId({ courseId: +paramsCourseId }));
    }
  }, [courseId, dispatch, isParamsCourseIdValid, paramsCourseId]);

  if (!isParamsCourseIdValid && !courseId) {
    return <Redirect to="/error/course-id-missing" />;
  }

  const isLoading =
    isCourseLoading ||
    isUserLoading ||
    isManifestLoading ||
    isEnrollmentsLoading ||
    isSnapshotsDatesLoading;
  if (isLoading) {
    return <ScreenLoader />;
  }

  const error =
    courseError || userError || manifestError || enrollmentsError || snapshotsDatesError;
  if (error) {
    return (
      <ScreenError
        errorTitle={
          <>
            Site content cannot be loaded right now <br /> Please contact the administration
          </>
        }
        error={error}
        reload
      />
    );
  }

  return (
    <>
      {courseId && (
        <>
          {process.env.REACT_APP_SHOW_CONTROLS && <ControlsBar />}
          {includeNavbar && <Header />}
          <div className={styles.TodoApp__ContentContainer}>
            {includeSidebar && <Sidebar />}
            <main className={styles.TodoApp__Content}>{children}</main>
          </div>
        </>
      )}
    </>
  );
};

export default TodoApp;
