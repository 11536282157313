import { FC } from 'react';
import WelcomeTile from './WelcomeTile';
import { useAppDispatch } from '../../../redux/hooks/app/useAppDispatch';
import { saveUiAction } from '../../../redux/slices/ui_actions';
import Section from '../../../components/Board/Section';
import SectionHeader from '../../../components/Board/Section/SectionHeader';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { selectUiActionByKey } from '../../../redux/selectors/ui_actions';

const WelcomeSection: FC = () => {
  const dispatch = useAppDispatch();

  const isWelcomeSectionDismissed = useAppSelector((state) =>
    selectUiActionByKey(state, 'WELCOME_SECTION__DISMISSED')
  );

  const handleDismissClick = (): void => {
    dispatch(saveUiAction('WELCOME_SECTION__DISMISSED'));
  };

  return (
    <>
      {!isWelcomeSectionDismissed && (
        <Section
          header={<SectionHeader onClose={handleDismissClick}>Welcome to Soomo</SectionHeader>}
          tile={<WelcomeTile />}
        />
      )}
    </>
  );
};

export default WelcomeSection;
