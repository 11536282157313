import { FC } from 'react';
import clsx from 'clsx';
import { IoReloadCircleOutline } from 'react-icons/io5';
import Tile, { TileProps } from '../Board/Tile';
import ActionButtonLink from '../Buttons/ActionButtonLink';
import styles from './styles.module.scss';

interface ScreenErrorProps {
  errorSize?: TileProps['errorSize'];
  errorTitle: TileProps['errorTitle'];
  error: TileProps['error'];
  reload?: boolean;
  className?: string;
}

const ScreenError: FC<ScreenErrorProps> = ({
  errorSize = 'huge',
  errorTitle,
  error,
  reload,
  className,
}) => {
  return (
    <div className={clsx(className, styles.ScreenError)}>
      <Tile
        errorSize={errorSize}
        errorTitle={errorTitle}
        error={error}
        className={styles.ScreenError__Tile}
      />
      {reload && (
        <ActionButtonLink to="" isHref target="_self" variant="none">
          <IoReloadCircleOutline />
          Reload
        </ActionButtonLink>
      )}
    </div>
  );
};

export default ScreenError;
