import { FC } from 'react';
import clsx from 'clsx';
import { FaTimes } from 'react-icons/fa';
import { BindingAction } from '../../../../types';
import ActionButton from '../../../Buttons/ActionButton';
import styles from './styles.module.scss';

interface SectionHeaderProps {
  onClose?: BindingAction;
  className?: string;
  buttonClassName?: string;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  onClose,
  className,
  buttonClassName,
  children,
}) => {
  return (
    <div className={clsx(className, styles.SectionHeader)}>
      <h3>{children}</h3>
      {onClose && (
        <ActionButton
          className={clsx(buttonClassName, styles.SectionHeader__Close)}
          onClick={onClose}
        >
          <FaTimes />
        </ActionButton>
      )}
    </div>
  );
};

export default SectionHeader;
