import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

// TODO Add support for multiple variants
export interface PillProps {
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'success' | 'none';
  weight?: 'normal' | 'bold';
  className?: string;
}

const Pill: FC<PillProps> = ({
  size = 'medium',
  variant = 'secondary',
  weight = 'normal',
  className,
  children,
}) => {
  return (
    <span
      className={clsx(
        className,
        styles.Pill,
        size === 'small' && styles.Pill_small,
        size === 'medium' && styles.Pill_medium,
        size === 'large' && styles.Pill_large,
        weight === 'bold' && styles.Pill_bold,
        variant === 'primary' && styles.Pill_primary,
        variant === 'secondary' && styles.Pill_secondary,
        variant === 'success' && styles.Pill_success,
        variant === 'none' && styles.Pill_none
      )}
    >
      {children}
    </span>
  );
};

export default Pill;
