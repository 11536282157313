import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface NoteParagraphProps {
  className?: string;
}

const NoteParagraph: FC<NoteParagraphProps> = ({ className, children }) => (
  <p className={clsx(className, styles.NoteParagraph)}>{children}</p>
);

export default NoteParagraph;
