import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { RisksSnapshot } from '../../../types';
import { selectSnapshotsInRange } from './select_snapshots';

export const selectLastSnapshot = createSelector<
  RootState,
  RisksSnapshot[],
  RisksSnapshot | undefined
>([selectSnapshotsInRange], (snapshots) => snapshots[snapshots.length - 1]);

export const selectLastSnapshotId = createSelector<
  RootState,
  RisksSnapshot | undefined,
  number | undefined
>([selectLastSnapshot], (lastSnapshot) => lastSnapshot?.id);

/**
 * Defines the snapshot week number, which corresponds to the previously selected pivotDate.
 * Possible values:
 *  2. null → pivotDate is before the first snapshot is available
 *  3. number → pivotDate is after the last snapshot end date
 */
export const selectLastSnapshotWeek = createSelector<
  RootState,
  RisksSnapshot | undefined,
  number | null
>([selectLastSnapshot], (lastSnapshot) => lastSnapshot?.week ?? null);
