import { useCallback, useMemo } from 'react';
import pluralize from 'pluralize';
import log from 'loglevel';
import { useAnswers } from '../../../redux/hooks/answers/useAnswers';
import { NotificationData, PopupNotification } from './index';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { useAppDispatch } from '../../../redux/hooks/app/useAppDispatch';
import { saveNewWorkReaction } from '../../../redux/slices/notifications';
import {
  selectUnreviewedAnswers,
  selectUnseenNotReactedAnswers,
} from '../../../redux/selectors/answers';
import { selectCourseId } from '../../../redux/selectors/application';

const getBaseNewWorkNotificationData = (courseId: number) => ({
  link: `/courses/${courseId}/#new-work`,
  title: 'New Work',
  message: '',
  isHighlighted: false,
  isAllResolved: false,
  isFetching: false,
});

export const useNewWorkNotification = (): PopupNotification => {
  const dispatch = useAppDispatch();

  const courseId = useAppSelector(selectCourseId);

  const {
    data: answers,
    isFetching: isAnswersFetching,
    isUninitialized: isAnswersUninitialized,
    error: answersError,
  } = useAnswers();

  const unreviewedAnswers = useAppSelector(selectUnreviewedAnswers); // TODO REMOVE, CONTROLS SUPPORT
  const unseenNotificationAnswers = useAppSelector(selectUnseenNotReactedAnswers);

  const notification = useMemo<NotificationData | null>(() => {
    const notificationData = { ...getBaseNewWorkNotificationData(courseId) };

    if (isAnswersFetching || isAnswersUninitialized) {
      notificationData.isFetching = true;
      return notificationData;
    }
    if (answersError || !answers) {
      log.warn('Answers data for the notification is not available');
      return null;
    }

    const unreviewedAnswersAmount = unreviewedAnswers.length;
    const unseenNotificationAnswersAmount = unseenNotificationAnswers.length;
    const unreviewedQuestionsIds = unreviewedAnswers
      .map((answer) => answer.question_family_id)
      .filter((questionId, index, array) => array.indexOf(questionId) === index);
    const unreviewedQuestionsAmount = unreviewedQuestionsIds.length;

    if (unseenNotificationAnswersAmount) {
      notificationData.message = `${unseenNotificationAnswersAmount} new ${pluralize(
        'answer',
        unseenNotificationAnswersAmount
      )} to review`;
      notificationData.isHighlighted = true;
      return notificationData;
    }

    if (unreviewedAnswersAmount) {
      notificationData.message = `${pluralize(
        'question',
        unreviewedQuestionsAmount,
        true
      )} have ${pluralize('answer', unreviewedAnswersAmount, true)} ready to review`;
      return notificationData;
    }

    notificationData.message = `No new answers to review for now`;
    notificationData.isAllResolved = true;
    return notificationData;
  }, [
    answers,
    answersError,
    courseId,
    isAnswersFetching,
    isAnswersUninitialized,
    unreviewedAnswers,
    unseenNotificationAnswers.length,
  ]);

  const handleReset = useCallback(() => {
    const unseenNotificationAnswersIds = unseenNotificationAnswers.map((answer) => answer.id);
    if (!unseenNotificationAnswersIds.length) return; // No unseen answers to react on

    dispatch(
      saveNewWorkReaction({ courseId, reaction: { answersIds: unseenNotificationAnswersIds } })
    );
  }, [courseId, dispatch, unseenNotificationAnswers]);

  return [notification, handleReset];
};
