import { FC, isValidElement, ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import ContentSkeleton from '../../ContentSkeleton';
import { toSerializableError } from '../../../utils/error_converter';
import Dimmer from '../../Dimmer';
import ErrorMessage, { ErrorMessageSizes } from '../../ErrorMessage';
import styles from './styles.module.scss';

export interface TileLoadingProps {
  isLoading?: boolean;
  isFetching?: boolean;
  error?: string | ReactElement | FetchBaseQueryError | SerializedError;
}

export interface TileProps extends TileLoadingProps {
  errorTitle?: string | ReactElement;
  errorSize?: ErrorMessageSizes;
  className?: string;
  errorClassName?: string;
}

const Tile: FC<TileProps> = ({
  isLoading,
  isFetching,
  className,
  error,
  errorTitle = 'Tile is temporary deactivated',
  errorSize = 'small',
  errorClassName,
  children,
}) => {
  const errorMessageElement = useMemo(() => {
    if (!error) return null;

    let errorMessage: string | ReactElement;
    if (typeof error === 'string' || isValidElement(error)) {
      errorMessage = error;
    } else {
      const serializableError = toSerializableError(error);
      if (!serializableError) return null;

      const { code, message } = serializableError;
      errorMessage = `${code} ${message}`;
    }

    return (
      <ErrorMessage
        iconSize={errorSize}
        textSize={errorSize}
        title={errorTitle}
        message={errorMessage}
        className={errorClassName}
      />
    );
  }, [error, errorClassName, errorSize, errorTitle]);

  return (
    <article className={clsx(className, styles.Tile, error && styles.Tile_error)}>
      {isLoading && <ContentSkeleton className={styles.Tile__Skeleton} />}
      {!isLoading && (
        <>
          {isFetching && <Dimmer loading />}
          {error && (
            <>
              <Dimmer className={styles.Tile__Dimmer_background} />
              <Dimmer className={styles.Tile__Dimmer_error}>{errorMessageElement}</Dimmer>
            </>
          )}
          {!error && (
            <div className={clsx(isFetching && styles.Tile__Content_blurred)}>{children}</div>
          )}
        </>
      )}
    </article>
  );
};

export default Tile;
