import { FC, useRef, useState } from 'react';
import Modal from 'react-responsive-modal';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import ActionButton from '../../Buttons/ActionButton';
import { toggleUiAction, UiActions, UiActionsKeys } from '../../../redux/slices/ui_actions';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { useAppDispatch } from '../../../redux/hooks/app/useAppDispatch';
import IconCheckbox from '../../Inputs/IconCheckbox';
import styles from './styles.module.scss';

const UiActionsToggleModal: FC = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setOpen] = useState(false);
  const uiActionsModalRef = useRef(null);

  const uiActionsKeys = Object.keys(UiActions) as UiActionsKeys[];
  const uiActions = useAppSelector((state) => state.uiActions);

  const handleActionToggle = (actionKey: UiActionsKeys): void => {
    dispatch(toggleUiAction(actionKey));
  };

  return (
    <>
      <ActionButton variant="dark" onClick={() => setOpen(true)}>
        Toggle UI actions
      </ActionButton>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="ui-actions-toggle-modal-title"
        initialFocusRef={uiActionsModalRef}
        center
      >
        <h2 id="ui-actions-toggle-modal-title">Toggle UI actions:</h2>
        <ul className={styles.UiActionsToggleModal__Actions}>
          {uiActionsKeys.map((actionKey) => (
            <li key={actionKey}>
              <button
                type="button"
                onClick={() => handleActionToggle(actionKey)}
                className={styles.UiActionsToggleModal__Action}
              >
                <IconCheckbox
                  value={uiActions[actionKey]}
                  toggledIcon={<FaToggleOn />}
                  untoggledIcon={<FaToggleOff />}
                />{' '}
                {actionKey}
              </button>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};
export default UiActionsToggleModal;
