import { FC, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import log from 'loglevel';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import { selectApplicationApi, selectCourseId } from '../../redux/selectors/application';
import ScreenError from '../../components/ScreenError';

export enum ErrorType {
  'token-expired' = 'token-expired',
  'token-missing' = 'token-missing',
  'course-id-missing' = 'course-id-missing',
  'course-missing' = 'course-missing',
  'analytics-host-missing' = 'analytics-host-missing',
  'no-permissions' = 'no-permissions',
}
export type ErrorTypeKeys = keyof typeof ErrorType;

interface ErrorPageUrlParams {
  errorType: ErrorTypeKeys;
}

const Error: FC = () => {
  const history = useHistory();
  const location = useLocation<{ courseId: string }>();
  const { errorType } = useParams<ErrorPageUrlParams>();

  const reduxCourseId = useAppSelector(selectCourseId);
  const { host: apiHost, analyticsHost } = useAppSelector(selectApplicationApi);
  const accessToken = useAppSelector((state) => state.application.accessToken);

  const courseId = location.state?.courseId || reduxCourseId;

  useEffect(() => {
    log.error(`Exception happened: ${errorType}`);
  }, [errorType]);

  /**
   * Restoration hook. Should forward to the valid page if the conditions are met
   */
  useEffect(() => {
    switch (errorType) {
      case 'token-missing': {
        if (accessToken) {
          history.push(`/courses/${courseId}`);
        }
        break;
      }
      case 'course-id-missing': {
        if (courseId) {
          history.push(`/courses/${courseId}`);
        }
        break;
      }
      case 'course-missing': {
        if (!courseId) {
          history.push('/error/course-id-missing');
        }
        break;
      }
      case 'analytics-host-missing': {
        if (!analyticsHost) {
          history.push(`/courses/${courseId}`);
        }
        break;
      }
      case 'no-permissions': {
        if (!courseId) {
          history.push('/error/course-id-missing');
        }
        break;
      }
    }
  }, [accessToken, analyticsHost, courseId, errorType, history]);

  const { errorTitle, error } = useMemo(() => {
    switch (errorType) {
      case 'token-expired': {
        return {
          errorTitle: 'Seems like your token has expired',
          error: (
            <>
              Please sign in again on <a href={apiHost}>{apiHost}</a>
            </>
          ),
        };
      }
      case 'token-missing': {
        return {
          errorTitle: 'Seems like your token is missing',
          error: (
            <>
              Please sign in on <a href={apiHost}>{apiHost}</a> and return to the site later
            </>
          ),
        };
      }
      case 'course-id-missing': {
        return {
          errorTitle: "Your course wasn't selected",
          error: (
            <>
              Please select the course on <a href={apiHost}>{apiHost}</a> and return to the
              site later
            </>
          ),
        };
      }
      case 'analytics-host-missing': {
        return {
          errorTitle: 'Cannot find link for the Analytics application',
          error: (
            <>
              Please select the course again on <a href={apiHost}>{apiHost}</a> and return to
              the site later
            </>
          ),
        };
      }
      case 'no-permissions': {
        return {
          errorTitle: `You're not permitted to view the course #${courseId} data`,
          error: (
            <>
              Please select the appropriate course on <a href={apiHost}>{apiHost}</a> and
              return to the site later
            </>
          ),
        };
      }
      default: {
        return {
          errorTitle: 'Unknown exception happened',
          error: (
            <>
              Please retry to visit this page from <a href={apiHost}>{apiHost}</a>
            </>
          ),
        };
      }
    }
  }, [apiHost, courseId, errorType]);

  return <ScreenError errorTitle={errorTitle} error={error} />;
};

export default Error;
