import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { coursesApi } from '../../queries/courses';
import { AcademicTerm, Course, School } from '../../../types';
import { selectCourseId } from '../application';

export * from './select_current_user_courses';
export * from './select_enrollments';
export * from './select_webtext_manifest';

const selectCourseResult = createSelector<RootState, number, any>(
  [selectCourseId],
  (courseId) => coursesApi.endpoints.getCourseById.select(courseId)
);

export const selectCourseData = createSelector<
  RootState,
  { data?: Course },
  Course | undefined
>([(state) => selectCourseResult(state)(state)], (courseResult) => courseResult.data);

export const selectCourseAcademicTerm = createSelector<
  RootState,
  Course | undefined,
  AcademicTerm | undefined
>([selectCourseData], (courseData) => courseData?.academic_term);

export const selectCourseSchool = createSelector<
  RootState,
  Course | undefined,
  School | undefined
>([selectCourseData], (courseData) => courseData?.school);
