import {
  Assessment,
  AssessmentRisk,
  MeasurementDimension,
  RiskCategoryKeys,
} from '../../../types';
import { RisksCutoffs } from '../../slices/risks_snapshots';
import {
  convertToDate,
  isDateMoreThanDays,
  isDateMoreThanWeeks,
  StringDate,
} from '../../../utils/dates_comparer';

const createRisk = (riskCategory: RiskCategoryKeys, categoryRank: number): AssessmentRisk => ({
  categoryKey: riskCategory,
  categoryRank,
});

export const generateAssessmentRisks = (
  snapshotAssessment: Assessment,
  snapshotRisksCutoffs: RisksCutoffs,
  snapshotEndedAt: StringDate
): AssessmentRisk[] => {
  const comparisonDate = convertToDate(snapshotEndedAt);

  const createLastSigninRisks = (lastSignin: string | number | null): AssessmentRisk[] => {
    if (lastSignin === null) {
      return [createRisk('never-signed-in', 1)];
    }

    const lastSigninDate = new Date(lastSignin);
    const lowCutoff = snapshotRisksCutoffs['no-sign-in']?.lowCutoff;
    if (isDateMoreThanDays(lastSigninDate, comparisonDate, lowCutoff)) {
      const categoryRank = 1 / (comparisonDate.getTime() - lastSigninDate.getTime());
      return [createRisk('no-sign-in', categoryRank)];
    }

    return [];
  };

  const createCompletionAchievedRisks = (
    completionAchieved: string | number | null
  ): AssessmentRisk[] => {
    if (completionAchieved === null) {
      return [createRisk('low-completion', 0)];
    }

    const lowCutoff = snapshotRisksCutoffs['low-completion']?.lowCutoff || 0;
    if (completionAchieved < lowCutoff) {
      return [createRisk('low-completion', +completionAchieved)];
    }

    return [];
  };

  const createScoreAchievedRisks = (
    scoreAchieved: string | number | null
  ): AssessmentRisk[] => {
    if (scoreAchieved === null) {
      return [createRisk('low-score', 0)];
    }

    const lowCutoff = snapshotRisksCutoffs['low-score']?.lowCutoff || 0;
    if (scoreAchieved < lowCutoff) {
      return [createRisk('low-score', +scoreAchieved)];
    }

    return [];
  };

  const createLastContactRisks = (lastContact: string | number | null): AssessmentRisk[] => {
    if (lastContact === null) {
      return [createRisk('no-contact-2-weeks', 0)];
    }

    const lastContactDate = new Date(lastContact);
    if (isDateMoreThanWeeks(lastContactDate, comparisonDate, 2)) {
      const categoryRank = 1 / (comparisonDate.getTime() - lastContactDate.getTime());
      return [createRisk('no-contact-2-weeks', categoryRank)];
    }

    return [];
  };

  return snapshotAssessment.measurements.reduce((risks, measurement) => {
    const { dimension, value } = measurement;
    let createdRisks: AssessmentRisk[] = [];
    switch (dimension) {
      case MeasurementDimension['last-signin']: {
        createdRisks = createLastSigninRisks(value);
        break;
      }
      case MeasurementDimension['completion-achieved']: {
        createdRisks = createCompletionAchievedRisks(value);
        break;
      }
      case MeasurementDimension['score-achieved']: {
        createdRisks = createScoreAchievedRisks(value);
        break;
      }
      case MeasurementDimension['last-contact']: {
        createdRisks = createLastContactRisks(value);
        break;
      }
    }
    risks.push(...createdRisks);
    return risks;
  }, [] as AssessmentRisk[]);
};
