import { FC, useCallback } from 'react';
import WebtextTile from './WebtextTile';
import { useAppDispatch } from '../../../redux/hooks/app/useAppDispatch';
import { useCourse } from '../../../redux/hooks/courses/useCourse';
import { saveUiAction } from '../../../redux/slices/ui_actions';
import { useWebtextManifest } from '../../../redux/hooks/courses/useWebtextManifest';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { selectApplicationApi } from '../../../redux/selectors/application';
import { selectUiActionByKey } from '../../../redux/selectors/ui_actions';
import Section from '../../../components/Board/Section';
import SectionHeader from '../../../components/Board/Section/SectionHeader';
import WebtextNote from './WebtextNote';

const WebtextSection: FC = () => {
  const dispatch = useAppDispatch();

  const appApi = useAppSelector(selectApplicationApi);
  const isWebtextSectionAccessed = useAppSelector((state) =>
    selectUiActionByKey(state, 'WEBTEXT_SECTION__ACCESSED')
  );

  const { data: course } = useCourse();
  const { data: webtextManifest } = useWebtextManifest();

  const handleWebtextAccessClick = useCallback((): void => {
    dispatch(saveUiAction('WEBTEXT_SECTION__ACCESSED'));
  }, [dispatch]);

  return (
    <>
      {!isWebtextSectionAccessed && (
        <Section
          header={
            <SectionHeader onClose={handleWebtextAccessClick}>
              Access your webtexts
            </SectionHeader>
          }
          tile={
            <WebtextTile
              course={course}
              apiHost={appApi.host}
              resourceSettings={webtextManifest?.toc?.config?.settings}
              onWebtextAccessClick={handleWebtextAccessClick}
            />
          }
          note={<WebtextNote />}
        />
      )}
    </>
  );
};

export default WebtextSection;
