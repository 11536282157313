import {
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import clsx from 'clsx';
import { useCombinedRefs } from '../../../../hooks/useCombinedRefs';
import styles from './styles.module.scss';

type IndeterminateInputProps = InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean;
};

interface TableSelectCheckboxProps extends IndeterminateInputProps {
  toggledIcon: ReactElement;
  indeterminateIcon: ReactElement;
  untoggledIcon: ReactElement;
  className?: string;
  inputClassName?: string;
}

const TableSelectCheckbox = forwardRef<HTMLInputElement, TableSelectCheckboxProps>(
  (
    { toggledIcon, indeterminateIcon, untoggledIcon, className, inputClassName, ...rest },
    ref
  ) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = useCombinedRefs(ref, defaultRef);

    const { indeterminate, ...sanitizedInputProps } = rest as IndeterminateInputProps;
    const { checked } = sanitizedInputProps;

    const icon = useMemo(() => {
      if (checked) return toggledIcon;
      if (indeterminate) return indeterminateIcon;
      return untoggledIcon;
    }, [checked, indeterminate, indeterminateIcon, toggledIcon, untoggledIcon]);

    useEffect(() => {
      if (resolvedRef?.current) {
        resolvedRef.current.indeterminate = indeterminate ? 1 : undefined;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <div className={clsx(className, styles.TableSelectCheckbox)}>
        <input
          type="checkbox"
          ref={resolvedRef}
          className={clsx(inputClassName, styles.IconCheckbox__Input)}
          {...sanitizedInputProps}
        />
        {icon}
      </div>
    );
  }
);

export default TableSelectCheckbox;
