import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'debounce';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import styles from './styles.module.scss';
import { BindingCallback1, BindingFunction1 } from '../../../../../types';
import LabeledSlider, { LabeledSliderProps } from '../../../../Inputs/LabeledSlider';
import { RiskThresholds } from '../../../../../redux/slices/risks_snapshots';

interface RiskThresholdsSlidersProps extends Omit<LabeledSliderProps, 'value' | 'onChange'> {
  lowValue?: number;
  highValue?: number;
  explanationLink?: string;
  explanationLow?: BindingFunction1<number, string>;
  explanationHigh?: BindingFunction1<number, string>;
  onChange: BindingCallback1<RiskThresholds>;
}

const RiskThresholdsSliders: FC<RiskThresholdsSlidersProps> = ({
  label,
  explanationLink,
  explanationLow,
  explanationHigh,
  lowValue,
  highValue,
  onChange,
  ...sliderProps
}) => {
  const [low, setLow] = useState(lowValue);
  const [high, setHigh] = useState(highValue);

  const debouncedOnChange = useMemo(() => debounce(onChange, 150), [onChange]);
  const handleThresholdsChange = useCallback(
    (thresholdCategory: keyof RiskThresholds, value: number): void => {
      switch (thresholdCategory) {
        case 'low':
          setLow(value);
          break;
        case 'high':
          setHigh(value);
          break;
      }

      debouncedOnChange({
        low,
        high,
        [thresholdCategory]: value,
      });
    },
    [debouncedOnChange, high, low]
  );

  useEffect(() => {
    if (lowValue) {
      setLow(lowValue);
    }
  }, [lowValue]);

  useEffect(() => {
    if (highValue) {
      setHigh(highValue);
    }
  }, [highValue]);

  return (
    <div className={styles.ZScoreSlider}>
      <div className={styles.ZScoreSlider__LabelGroup}>
        <h4 className={styles.ZScoreSlider__Label}>{label}</h4>
        {explanationLink && (
          <a target="_blank" href={explanationLink} rel="noreferrer">
            <BsFillQuestionCircleFill />
          </a>
        )}
      </div>
      <div className={styles.ZScoreSlider__InputGroup}>
        {low !== undefined && (
          <LabeledSlider
            {...sliderProps}
            label="low:"
            value={low}
            explanation={explanationLow}
            onChange={(value) => handleThresholdsChange('low', value)}
          />
        )}
        {high !== undefined && (
          <LabeledSlider
            {...sliderProps}
            label="high:"
            value={high}
            explanation={explanationHigh}
            onChange={(value) => handleThresholdsChange('high', value)}
          />
        )}
      </div>
    </div>
  );
};

export default RiskThresholdsSliders;
