import {
  getDaysAmountBetweenDates,
  getHoursBetweenDates,
  getMinutesBetweenDates,
} from './dates_ranges_helper';
import { convertToDate, StringDate } from './dates_comparer';
import { LOCALE } from '../constants';

export const toTimeMonthDateString = (date: StringDate): string =>
  convertToDate(date).toLocaleString(LOCALE, {
    hour: '2-digit',
    minute: '2-digit',
    month: 'long',
    day: '2-digit',
  });

export const toTimeMonthDateYearString = (date: StringDate): string =>
  convertToDate(date).toLocaleString(LOCALE, {
    hour: '2-digit',
    minute: '2-digit',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

export const toMonthDateYearString = (date: StringDate): string =>
  convertToDate(date).toLocaleString(LOCALE, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

export const toMonthDateString = (date: StringDate): string =>
  convertToDate(date).toLocaleString(LOCALE, {
    month: 'long',
    day: '2-digit',
  });

const formatDifferentYearsDates = (dateA: StringDate, dateB: StringDate): string => {
  const firstSnapshotPartition = toMonthDateYearString(dateA);
  const lastSnapshotPartition = toMonthDateYearString(dateB);
  return `${firstSnapshotPartition} - ${lastSnapshotPartition}`;
};

const formatSameMonthsDates = (dateA: Date, dateB: Date): string => {
  const year = dateA.getFullYear();
  const month = dateA.toLocaleString(LOCALE, { month: 'long' });
  const firstDate = dateA.getDate();
  const lastDate = dateB.getDate();
  return `${month} ${firstDate} - ${lastDate}, ${year}`;
};

const formatDifferentMonthsDates = (dateA: Date, dateB: Date): string => {
  const year = dateA.getFullYear();
  const firstMonthDate = toMonthDateString(dateA);
  const lastMonthDate = toMonthDateString(dateB);
  return `${firstMonthDate} - ${lastMonthDate}, ${year}`;
};

export const formatDatesRange = (dateA: Date, dateB: Date): string => {
  if (dateA.getFullYear() !== dateB.getFullYear()) {
    return formatDifferentYearsDates(dateA, dateB);
  }
  if (dateA.getMonth() === dateB.getMonth()) {
    return formatSameMonthsDates(dateA, dateB);
  }
  return formatDifferentMonthsDates(dateA, dateB);
};

export const formatSecondsSpent = (spentSeconds: number): string => {
  // multiply by 1000 because Date() requires milliseconds
  const date = new Date(spentSeconds * 1000);
  const [hours, minutes] = date
    .toLocaleTimeString(LOCALE, {
      hour12: false,
      hour: 'numeric',
      minute: '2-digit',
    })
    .split(':');
  return `${hours}h ${minutes}m`;
};

// Add 'about' part when only hours presented
const formatRelativeHourTime = (hourString: string): string => {
  const prefixIndex = hourString.indexOf('in');
  if (prefixIndex === -1) return `about ${hourString}`;

  const afterPrefix = prefixIndex + 2;
  return `${hourString.slice(0, afterPrefix)} about ${hourString.slice(afterPrefix)}`;
};

const relativeTimeFormatter = new Intl.RelativeTimeFormat(LOCALE, {
  localeMatcher: 'best fit',
  numeric: 'auto',
  style: 'long',
});
export const formatRelativeToDate = (relativeDate: Date, date: Date): string => {
  const minutesBetween = getMinutesBetweenDates(relativeDate, date);
  const hoursBetween = getHoursBetweenDates(relativeDate, date);
  const daysBetween = getDaysAmountBetweenDates(relativeDate, date);

  if (daysBetween !== 0) {
    return relativeTimeFormatter.format(daysBetween, 'days');
  }
  if (hoursBetween !== 0) {
    const hourString = relativeTimeFormatter.format(hoursBetween, 'hours');
    return formatRelativeHourTime(hourString);
  }
  return relativeTimeFormatter.format(minutesBetween, 'minutes');
};

export const formatLocaleDatetimeString = (date: StringDate): string => {
  const convertedDate = convertToDate(date);
  return convertedDate.toLocaleString();
};
