import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  CategorizedRisksAssessmentsAmounts,
  SnapshotCategorizationAmountsIndex,
  SnapshotCategorizationIndex,
  SnapshotsCategorizationAmountsIndex,
  SnapshotsCategorizationIndex,
} from '../../slices/risks_snapshots';
import { Assessment, RiskCategoryKeys, RisksSnapshot } from '../../../types';
import { selectLastSnapshot, selectLastSnapshotId } from './select_last_snapshot';

export const selectSnapshotsCategorizationIndex = (
  state: RootState
): SnapshotsCategorizationIndex => state.risksSnapshots.snapshotsCategorizationIndex;

export const selectSnapshotCategorizationIndex = createSelector<
  RootState,
  number,
  SnapshotsCategorizationIndex,
  number,
  SnapshotCategorizationIndex | undefined
>(
  [selectSnapshotsCategorizationIndex, (state, snapshotId) => snapshotId],
  (snapshotsCategorizationIndex, snapshotId) => snapshotsCategorizationIndex[snapshotId]
);

export const selectLastSnapshotCategorizationIndex = createSelector<
  RootState,
  SnapshotsCategorizationIndex,
  number | undefined,
  SnapshotCategorizationIndex | undefined
>(
  [selectSnapshotsCategorizationIndex, selectLastSnapshotId],
  (snapshotsCategorizationIndex, snapshotId) => snapshotsCategorizationIndex[snapshotId ?? -1]
);

export const selectSnapshotsCategorizationAmountsIndex = createSelector<
  RootState,
  SnapshotsCategorizationIndex,
  SnapshotsCategorizationAmountsIndex
>([selectSnapshotsCategorizationIndex], (snapshotsCategorizationIndex) =>
  Object.values(snapshotsCategorizationIndex).reduce(
    (snapshotsCategorizationIndexAmounts, snapshotCategorizationIndex) => {
      const {
        snapshotId,
        assessmentsAmount,
        offTrackAssessmentsAmount,
        categorizedAssessments,
      } = snapshotCategorizationIndex as SnapshotCategorizationIndex;

      const categorizedAssessmentsAmounts = Object.values(categorizedAssessments).reduce(
        (assessmentsAmounts, { riskCategory, assessmentsUsersIds }) => {
          assessmentsAmounts[riskCategory] = assessmentsUsersIds.length;
          return assessmentsAmounts;
        },
        {} as CategorizedRisksAssessmentsAmounts
      );

      snapshotsCategorizationIndexAmounts[snapshotId] = {
        snapshotId,
        categorizedAssessmentsAmounts,
        assessmentsAmount,
        offTrackAssessmentsAmount,
      };
      return snapshotsCategorizationIndexAmounts;
    },
    {} as SnapshotsCategorizationAmountsIndex
  )
);

export const selectSnapshotCategorizationAmountsIndex = createSelector<
  RootState,
  number,
  SnapshotsCategorizationAmountsIndex,
  number,
  SnapshotCategorizationAmountsIndex | undefined
>(
  [selectSnapshotsCategorizationAmountsIndex, (state, snapshotId) => snapshotId],
  (snapshotsCategorizationAmountsIndex, snapshotId) =>
    snapshotsCategorizationAmountsIndex[snapshotId]
);

export const selectLastSnapshotCategorizationAmountIndex = createSelector<
  RootState,
  SnapshotsCategorizationAmountsIndex,
  number | undefined,
  SnapshotCategorizationAmountsIndex | undefined
>(
  [selectSnapshotsCategorizationAmountsIndex, selectLastSnapshotId],
  (snapshotsCategorizationAmountsIndex, snapshotId) =>
    snapshotsCategorizationAmountsIndex[snapshotId ?? -1]
);

export const selectLastSnapshotCategorizedRiskAssessments = createSelector<
  RootState,
  RiskCategoryKeys,
  RisksSnapshot | undefined,
  SnapshotCategorizationIndex | undefined,
  RiskCategoryKeys,
  Assessment[] | undefined
>(
  [
    selectLastSnapshot,
    selectLastSnapshotCategorizationIndex,
    (state, riskCategory) => riskCategory,
  ],
  (lastSnapshot, lastSnapshotCategorizationIndex, riskCategory) => {
    if (!lastSnapshot || !lastSnapshotCategorizationIndex) return undefined;

    const {
      assessments: { assessments },
    } = lastSnapshot;

    const { categorizedAssessments } = lastSnapshotCategorizationIndex;
    const categorizedRiskAssessments = categorizedAssessments[riskCategory];
    if (!categorizedRiskAssessments) return undefined;

    const riskAssessmentsUsersIds = categorizedRiskAssessments.assessmentsUsersIds;
    return assessments.filter((assessment) =>
      riskAssessmentsUsersIds.includes(assessment.user.id)
    );
  }
);

export const makeSelectLastSnapshotCategorizedRiskAssessments = () =>
  selectLastSnapshotCategorizedRiskAssessments;
