import { createSelector, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { selectCourseId } from '../application';
import { risksSnapshotsApi } from '../../queries/risks_snapshots';
import { RisksSnapshotDatesIndex } from '../../../types';
import {
  risksSnapshotsDatesIndicesAdapter,
  risksSnapshotsDatesIndicesInitialState,
} from '../../entities_adapters/risks_snapshots';
import { SnapshotsRange } from '../../slices/risks_snapshots';
import { isDateGreater } from '../../../utils/dates_comparer';

export const selectSnapshotsRange = (state: RootState): SnapshotsRange =>
  state.risksSnapshots.snapshotsRange;

const selectCourseSnapshotsDatesIndicesResult = createSelector<RootState, number, any>(
  [selectCourseId],
  (courseId) => risksSnapshotsApi.endpoints.getSnapshotsDatesIndicesByCourseId.select(courseId)
);

const selectSnapshotsDatesIndicesData = createSelector<
  RootState,
  { data?: EntityState<RisksSnapshotDatesIndex> },
  EntityState<RisksSnapshotDatesIndex> | undefined
>(
  [(state) => selectCourseSnapshotsDatesIndicesResult(state)(state)],
  (courseSnapshotsResult) => courseSnapshotsResult.data
);

export const {
  selectAll: selectAllSnapshotsDatesIndices,
  selectById: selectSnapshotDateIndexById,
  selectIds: selectSnapshotsIndicesIds,
  selectEntities: selectSnapshotsIndicesEntities,
} = risksSnapshotsDatesIndicesAdapter.getSelectors(
  (state: RootState) =>
    selectSnapshotsDatesIndicesData(state) || risksSnapshotsDatesIndicesInitialState
);

export const selectIndexedSnapshotsIdsInRange = createSelector<
  RootState,
  RisksSnapshotDatesIndex[],
  SnapshotsRange,
  number[]
>(
  [selectAllSnapshotsDatesIndices, selectSnapshotsRange],
  (snapshotsDatesIndices, { rangeStart, rangeEnd }) =>
    snapshotsDatesIndices
      .filter((snapshotIndex) => {
        /**
         * TODO REVISIT
         * Now it allows to ignore the -1 week snapshot when the snapshot's creation date
         * matches with the AT class start date
         */
        const { snapshot_ended_at } = snapshotIndex;
        return isDateGreater(snapshot_ended_at, rangeStart) && snapshot_ended_at <= rangeEnd;
      })
      .map((snapshot) => snapshot.id)
);
