import { EntityState } from '@reduxjs/toolkit';
import { answersApi } from './index';
import { Answer } from '../../../types';
import { answersAdapter, answersInitialState } from '../../entities_adapters/answers';

export const transformAnswers = (response: unknown): EntityState<Answer> => {
  const responseAnswers = (response as { answers: unknown }).answers;
  const answers = responseAnswers as Answer[];
  return answersAdapter.setMany(answersInitialState, answers);
};

export const transformAnswer = (response: unknown): Answer => {
  const responseAnswer = (response as { answer: unknown }).answer;
  return responseAnswer as Answer;
};

export const upsertAnswer = (courseId: number, answer: Answer) =>
  answersApi.util.updateQueryData('getAnswersByCourseId', courseId, (draftAnswers) => {
    answersAdapter.upsertOne(draftAnswers || answersInitialState, answer);
  });
