import { useCallback, useEffect, useMemo } from 'react';
import pluralize from 'pluralize';
import log from 'loglevel';
import { toMonthDateString } from '../../../utils/timespans_formatter';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { NotificationData, PopupNotification } from './index';
import { useAppDispatch } from '../../../redux/hooks/app/useAppDispatch';
import {
  saveInitialWeeklyOutreachReaction,
  saveWeeklyOutreachReaction,
} from '../../../redux/slices/notifications';
import { formatOrdinals } from '../../../utils/strings_formatter';
import { selectCourseWeeklyOutreachReaction } from '../../../redux/selectors/notifications';
import { selectCourseId, selectCoursePivotDate } from '../../../redux/selectors/application';
import {
  selectLastSnapshot,
  selectLastSnapshotCategorizationIndex,
} from '../../../redux/selectors/risks_snapshots';
import { useCourseSnapshots } from '../../../redux/hooks/risks_snapshots/useCourseSnapshots';

const getBaseWeeklyOutreachNotificationData = (courseId: number) => ({
  link: `/courses/${courseId}/outreach`,
  title: 'Weekly outreach',
  message: '',
  isHighlighted: false,
  isAllResolved: false,
  isFetching: false,
});

export const useWeeklyOutreachNotification = (): PopupNotification => {
  const dispatch = useAppDispatch();

  const courseId = useAppSelector(selectCourseId);
  const pivotDate = useAppSelector(selectCoursePivotDate);
  const reactedWeeklyOutreach = useAppSelector(selectCourseWeeklyOutreachReaction);
  const lastSnapshot = useAppSelector(selectLastSnapshot);
  const lastSnapshotCategorizationIndex = useAppSelector(
    selectLastSnapshotCategorizationIndex
  );

  const {
    isFetching: isSnapshotsFetching,
    isUninitialized: isSnapshotsUninitialized,
    error: snapshotsError,
  } = useCourseSnapshots();

  /**
   * Store the lastSnapshot as reacted on the first visit of the site
   * It will prevent appearing of the notification from the start
   */
  useEffect(() => {
    if (!lastSnapshot) return;

    const { id: lastSnapshotId, week: lastSnapshotWeek } = lastSnapshot;
    dispatch(
      saveInitialWeeklyOutreachReaction({
        courseId,
        reaction: {
          snapshotId: lastSnapshotId,
          snapshotWeekNumber: lastSnapshotWeek,
        },
      })
    );
  }, [courseId, dispatch, lastSnapshot]);

  // HACK
  // ALLOWS NOT SEE NOTIFICATIONS ABOUT THE NEW WEEK WHEN GOING BACK IN TIME WITH DATEPICKER
  useEffect(() => {
    if (!lastSnapshot || !reactedWeeklyOutreach) return;

    const { id: lastSnapshotId, week: lastSnapshotWeek } = lastSnapshot;
    const reactedSnapshotId = reactedWeeklyOutreach.snapshotId;
    const reactedSnapshotWeekNumber = reactedWeeklyOutreach.snapshotWeekNumber;

    if (reactedSnapshotId !== lastSnapshotId && reactedSnapshotWeekNumber > lastSnapshotWeek) {
      dispatch(
        saveWeeklyOutreachReaction({
          courseId,
          reaction: {
            snapshotId: lastSnapshotId,
            snapshotWeekNumber: lastSnapshotWeek,
          },
        })
      );
    }
  }, [courseId, dispatch, lastSnapshot, reactedWeeklyOutreach]);

  const notification = useMemo<NotificationData | null>(() => {
    const notificationData = { ...getBaseWeeklyOutreachNotificationData(courseId) };

    if (isSnapshotsFetching || isSnapshotsUninitialized) {
      notificationData.isFetching = true;
      return notificationData;
    }
    if (snapshotsError) {
      log.warn('Last snapshot data for the notification is not available');
      return null;
    }
    if (!lastSnapshot || !lastSnapshotCategorizationIndex) {
      return null;
    }

    const { id: lastSnapshotId, week: lastSnapshotWeek } = lastSnapshot;
    const { offTrackAssessmentsAmount } = lastSnapshotCategorizationIndex;

    const monthDateString = toMonthDateString(pivotDate);
    const reactedSnapshotId = reactedWeeklyOutreach?.snapshotId;
    const reactedSnapshotWeekNumber = reactedWeeklyOutreach?.snapshotWeekNumber || -1;

    if (reactedSnapshotId !== lastSnapshotId && reactedSnapshotWeekNumber < lastSnapshotWeek) {
      notificationData.message = `The new snapshot for the ${formatOrdinals(
        lastSnapshotWeek
      )} week available`;
      notificationData.isHighlighted = true;
      return notificationData;
    }

    if (offTrackAssessmentsAmount) {
      notificationData.message = `${pluralize(
        'student',
        offTrackAssessmentsAmount,
        true
      )} are off-path as of ${monthDateString}`;
      return notificationData;
    }

    notificationData.message = `No students are off-path as of ${monthDateString}`;
    notificationData.isAllResolved = true;
    return notificationData;
  }, [
    courseId,
    isSnapshotsFetching,
    isSnapshotsUninitialized,
    lastSnapshot,
    lastSnapshotCategorizationIndex,
    pivotDate,
    reactedWeeklyOutreach?.snapshotId,
    reactedWeeklyOutreach?.snapshotWeekNumber,
    snapshotsError,
  ]);

  const handleReset = useCallback(() => {
    if (!lastSnapshot) return;

    const { id: lastSnapshotId, week: lastSnapshotWeek } = lastSnapshot;
    dispatch(
      saveWeeklyOutreachReaction({
        courseId,
        reaction: {
          snapshotId: lastSnapshotId,
          snapshotWeekNumber: lastSnapshotWeek,
        },
      })
    );
  }, [courseId, dispatch, lastSnapshot]);

  return [notification, handleReset];
};
