import { FC, ReactElement } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export interface SlidersGroupProps {
  label?: string;
  sliders: ReactElement[];
  className?: string;
}

const SlidersGroup: FC<SlidersGroupProps> = ({ label, sliders, className }) => {
  return (
    <div className={clsx(styles.SlidersGroup, className)}>
      {label && <h3 className={styles.SlidersGroup__Label}>{label}</h3>}
      <ul className={styles.SlidersGroup__List}>
        {sliders.map((slider) => (
          <li key={slider.key} className={styles.SlidersGroup__Item}>
            {slider}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SlidersGroup;
