import { useMemo } from 'react';
import log from 'loglevel';
import { DecisionOption } from '@soomo/lib/types/WebtextManifest';
import { NewWorkColumns } from './NewWorkChapterTable';
import {
  selectUnreviewedAnswers,
  selectUnreviewedAnswersIds,
} from '../../../../../redux/selectors/answers';
import { useAppSelector } from '../../../../../redux/hooks/app/useAppSelector';
import {
  selectCourseDecisions,
  selectPagesElementsEntities,
  selectPagesEntities,
  selectStudentsUsersEntities,
} from '../../../../../redux/selectors/course';
import { Answer, Page, PageElementNameType, PageElementsNames } from '../../../../../types';
import { generateA2RedirectionLink } from '../../../../../utils/a2_links_generator';
import { extractUserQuestionCyoKey } from '../../../../../redux/services/answers';
import {
  selectApplicationApi,
  selectCourseId,
} from '../../../../../redux/selectors/application';
import { ApplicationApi } from '../../../../../redux/slices/application';
import { EnrollmentUser } from '../../../../../types/enrollment';

interface QuestionRowPayload {
  courseId: number;
  appApi: ApplicationApi;
  page: Page;
  question: any;
  decisionData: { decisionKey: string; decisionOption: DecisionOption };
  answersData: {
    answersIds: number[];
    answers: Answer[];
  };
  normalizedEnrollmentsUsers: Record<string, EnrollmentUser>;
}

const createQuestionRowData = ({
  courseId,
  appApi,
  page,
  question,
  decisionData,
  answersData,
  normalizedEnrollmentsUsers,
}: QuestionRowPayload): NewWorkColumns => {
  const {
    id: pageId,
    page_name,
    page_human_name,
    page_nav_label,
    page_number,
    page_type,
  } = page;
  const {
    decisionKey,
    decisionOption: { key: optionKey, name: optionName },
  } = decisionData;
  const { answersIds, answers } = answersData;
  const {
    id: questionId,
    type: questionType,
    humanName: questionHumanName,
    config: questionConfig,
  } = question;

  const pageColumnsData = {
    id: pageId,
    name: page_name,
    humanName: page_human_name,
    navLabel: page_nav_label,
    number: page_number,
    type: page_type,
  };

  const answersUsersIds = answers.map((answer) => answer.user_id);
  const [firstAnswerUserId] = answersUsersIds;
  const firstAnswerUser = normalizedEnrollmentsUsers[firstAnswerUserId];
  const firstUserQuestionCyoKey = extractUserQuestionCyoKey(question, firstAnswerUser);

  let questionBody;
  if (
    PageElementNameType[questionType as PageElementsNames] ===
    PageElementNameType.interactive_template
  ) {
    questionBody = questionConfig.title;
  } else {
    questionBody = firstUserQuestionCyoKey
      ? question.dictionary.body[firstUserQuestionCyoKey]
      : question.body;
  }

  const questionColumnsData = {
    id: questionId,
    type: questionType,
    body: questionBody,
    humanName: questionHumanName,
    completionAchieved: 0.1337, // TODO Add fetching of completion rates for questions
    answersIds,
    usersIds: answersUsersIds,
  };

  const decisionColumnData = {
    decisionKey,
    optionKey,
    optionName,
  };

  const a2RedirectionLink = !firstAnswerUser
    ? '#'
    : generateA2RedirectionLink({
        apiHost: appApi.host,
        courseId,
        user: firstAnswerUser,
        page,
        question,
      });

  const reviewButtonColumnData = {
    a2RedirectionLink,
  };

  return {
    reviewButton: reviewButtonColumnData,
    page: pageColumnsData,
    question: questionColumnsData,
    decision: decisionColumnData,
  };
};

export const useNewWorkRows = (chapterFamilyId: string): NewWorkColumns[] => {
  const courseId = useAppSelector(selectCourseId);
  const answersTableIndex = useAppSelector((state) => state.answers.answersTableIndex);
  const appApi = useAppSelector(selectApplicationApi);

  const normalizedPages = useAppSelector(selectPagesEntities);
  const normalizedPagesElements = useAppSelector(selectPagesElementsEntities);
  const normalizedCourseDecisions = useAppSelector(selectCourseDecisions);
  const normalizedEnrollmentsUsers = useAppSelector(selectStudentsUsersEntities);

  const unreviewedAnswersIds = useAppSelector(selectUnreviewedAnswersIds);
  const unreviewedAnswers = useAppSelector(selectUnreviewedAnswers);

  return useMemo(() => {
    const chapterPages = answersTableIndex.chapters[chapterFamilyId].pages;
    const chapterPagesFamilyIds = Object.keys(chapterPages);

    return chapterPagesFamilyIds.reduce((tableRowsAcc, pageFamilyId) => {
      const rowPage = normalizedPages[pageFamilyId];
      if (!rowPage) {
        log.warn(`No page for ${pageFamilyId} family id was found!`);
        return tableRowsAcc;
      }

      const pageDecisions = chapterPages[pageFamilyId].decisions;
      const pageDecisionsKeys = Object.keys(pageDecisions);

      const decisionsRows = pageDecisionsKeys.reduce((decisionsRowsAcc, decisionKey) => {
        let rowDecision = normalizedCourseDecisions[decisionKey];

        // Special case when the page doesn't have any cyoKey dependent questions
        rowDecision = rowDecision || { options: [{ key: 'none', name: 'none' }] };

        const decisionOptions = pageDecisions[decisionKey].options;
        const decisionOptionsKeys = Object.keys(decisionOptions);

        const optionsRows = decisionOptionsKeys.reduce((optionsRowsAcc, decisionOptionKey) => {
          const rowOption = rowDecision.options.find(
            (option) => option.key === decisionOptionKey
          );
          if (!rowOption) {
            log.warn(`No decision for ${decisionOptionKey} option was found!`);
            return optionsRowsAcc;
          }

          const optionQuestionsTypes = decisionOptions[decisionOptionKey].questionsTypes;
          const optionQuestionsTypesKeys = Object.keys(optionQuestionsTypes);

          const questionsTypesRows = optionQuestionsTypesKeys.reduce(
            (questionsTypesRowsAcc, optionQuestionTypeKey) => {
              const rowQuestionType = optionQuestionsTypes[optionQuestionTypeKey];
              if (!rowQuestionType) {
                log.warn(`No question type for ${optionQuestionTypeKey} was found!`);
                return questionsTypesRowsAcc;
              }

              const questionTypeQuestions = rowQuestionType.questions;
              const questionTypeQuestionsFamilyIds = Object.keys(questionTypeQuestions);

              const questionsRows = questionTypeQuestionsFamilyIds.reduce(
                (questionsRowsAcc, questionFamilyId) => {
                  const rowQuestion = normalizedPagesElements[questionFamilyId];
                  if (!rowQuestion) {
                    log.warn(`No question for ${questionFamilyId} family id was found!`);
                    return questionsRowsAcc;
                  }

                  const questionAnswersIds =
                    questionTypeQuestions[questionFamilyId].answersIds;

                  // TODO Replace with the answers ids removal from the index after reviewing
                  const unreviewedQuestionAnswersIds = unreviewedAnswersIds.filter(
                    (answerId) => questionAnswersIds.includes(answerId)
                  );
                  const unreviewedQuestionAnswers = unreviewedAnswers.filter((answer) =>
                    unreviewedQuestionAnswersIds.includes(answer.id)
                  );
                  if (!unreviewedQuestionAnswers.length) {
                    // log.warn(`No answers for the question ${questionFamilyId} was found!`);
                    return questionsRowsAcc;
                  }

                  const questionRowData = createQuestionRowData({
                    courseId,
                    appApi,
                    page: rowPage,
                    decisionData: { decisionKey, decisionOption: rowOption },
                    question: rowQuestion,
                    answersData: {
                      answersIds: unreviewedQuestionAnswersIds,
                      answers: unreviewedQuestionAnswers,
                    },
                    normalizedEnrollmentsUsers,
                  });
                  questionsRowsAcc.push(questionRowData);
                  return questionsRowsAcc;
                },
                [] as NewWorkColumns[]
              );

              questionsTypesRowsAcc.push(...questionsRows);
              return questionsTypesRowsAcc;
            },
            [] as NewWorkColumns[]
          );

          optionsRowsAcc.push(...questionsTypesRows);
          return optionsRowsAcc;
        }, [] as NewWorkColumns[]);

        tableRowsAcc.push(...optionsRows);
        return decisionsRowsAcc;
      }, [] as NewWorkColumns[]);

      tableRowsAcc.push(...decisionsRows);
      return tableRowsAcc;
    }, [] as NewWorkColumns[]);
  }, [
    answersTableIndex.chapters,
    appApi,
    chapterFamilyId,
    courseId,
    normalizedCourseDecisions,
    normalizedEnrollmentsUsers,
    normalizedPages,
    normalizedPagesElements,
    unreviewedAnswers,
    unreviewedAnswersIds,
  ]);
};
