import { FC, ReactElement } from 'react';
import { BiErrorCircle } from 'react-icons/bi';
import clsx from 'clsx';
import styles from './styles.module.scss';

export type ErrorMessageSizes = 'small' | 'medium' | 'large' | 'huge';

interface ErrorMessageProps {
  iconSize?: ErrorMessageSizes;
  textSize?: ErrorMessageSizes;
  layout?: 'vertical' | 'horizontal';
  title?: ReactElement | string;
  message: ReactElement | string;
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
  iconSize = 'medium',
  textSize = 'medium',
  layout = 'horizontal',
  title,
  message,
  className,
}) => {
  return (
    <div
      className={clsx(
        className,
        styles.ErrorMessage,
        layout === 'horizontal' && styles.ErrorMessage_horizontal,
        layout === 'vertical' && styles.ErrorMessage_vertical
      )}
    >
      <span
        className={clsx(
          iconSize === 'small' && styles.ErrorMessage__Icon_small,
          iconSize === 'medium' && styles.ErrorMessage__Icon_medium,
          iconSize === 'large' && styles.ErrorMessage__Icon_large,
          iconSize === 'huge' && styles.ErrorMessage__Icon_huge
        )}
      >
        <BiErrorCircle />
      </span>
      <div
        className={clsx(
          styles.ErrorMessage__Content,
          textSize === 'small' && styles.ErrorMessage__Content_small,
          textSize === 'medium' && styles.ErrorMessage__Content_medium,
          textSize === 'large' && styles.ErrorMessage__Content_large,
          textSize === 'huge' && styles.ErrorMessage__Content_huge
        )}
      >
        {title && <p className={styles.ErrorMessage__Title}>{title}</p>}
        <p className={styles.ErrorMessage__Message}>{message}</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
