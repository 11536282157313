import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import { selectAccessToken, selectUserId } from '../../redux/selectors/application';
import ApplicationRoute, { ApplicationRouteProps } from '../ApplicationRoute';

type ProtectedApplicationRouteProps = ApplicationRouteProps;

const ProtectedApplicationRoute: FC<ProtectedApplicationRouteProps> = ({ ...routeProps }) => {
  const userId = useAppSelector(selectUserId);
  const accessToken = useAppSelector(selectAccessToken);
  if (!userId || !accessToken) {
    return <Redirect to="/error/token-missing" />;
  }
  return <ApplicationRoute {...routeProps} />;
};

export default ProtectedApplicationRoute;
