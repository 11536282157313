import { FC, ReactElement } from 'react';
import Tile, { TileLoadingProps } from '../../../../components/Board/Tile';
import AssessmentsGraph from './AssessmentsGraph';
import { formatDatesRange } from '../../../../utils/timespans_formatter';
import ActionButtonLink from '../../../../components/Buttons/ActionButtonLink';
import ActionsGroup from '../../../../components/Buttons/ActionsGroup';
import { getSnapshotsTimespanDates } from '../../../../redux/services/risks_assessments';
import { saveUiAction } from '../../../../redux/slices/ui_actions';
import { useAppDispatch } from '../../../../redux/hooks/app/useAppDispatch';
import { useAppSelector } from '../../../../redux/hooks/app/useAppSelector';
import {
  selectLastSnapshotWeek,
  selectSnapshotsInRange,
} from '../../../../redux/selectors/risks_snapshots';
import { selectCourseAcademicTerm } from '../../../../redux/selectors/course';
import { selectAssessmentsGraphUi } from '../../../../redux/selectors/risks_ui';
import { RisksSnapshot } from '../../../../types';
import { useAssessmentsGraphData } from './useAssessmentsGraphData';
import { selectCourseId } from '../../../../redux/selectors/application';
import TileParagraph from '../../../../components/Board/Tile/TileParagraph';
import Pill from '../../../../components/Pill';
import styles from './styles.module.scss';

type WeeklyOutreachTileProps = TileLoadingProps;

const WeeklyOutreachTile: FC<WeeklyOutreachTileProps> = ({ isLoading, isFetching, error }) => {
  const dispatch = useAppDispatch();

  const courseId = useAppSelector(selectCourseId);
  const assessmentsGraphUi = useAppSelector(selectAssessmentsGraphUi);
  const academicTerm = useAppSelector(selectCourseAcademicTerm);
  const snapshots = useAppSelector(selectSnapshotsInRange);
  const lastSnapshotWeek = useAppSelector(selectLastSnapshotWeek);

  const barsStacksData = useAssessmentsGraphData();

  const onReachOutClick = () => dispatch(saveUiAction('OUTREACH_SECTION__REACH_OUT__CLICKED'));

  const renderSnapshotsDatesRange = (
    rangedSnapshots: RisksSnapshot[]
  ): ReactElement | null => {
    const { startedAt, endedAt } = getSnapshotsTimespanDates(rangedSnapshots);
    if (!startedAt || !endedAt) return null;

    const timespanString = formatDatesRange(startedAt, endedAt);
    return (
      <div className={styles.WeeklyOutreachTile__Timespan}>
        <span className={styles.WeeklyOutreachTile__TimespanTitle}>Outreach for:</span>
        &nbsp; &nbsp;
        <Pill>
          <span className={styles.WeeklyOutreachTile__TimespanWeeks}>
            {lastSnapshotWeek} weeks
          </span>
          &nbsp;
          <span className={styles.WeeklyOutreachTile__TimespanDates}>({timespanString})</span>
        </Pill>
      </div>
    );
  };

  return (
    <Tile isLoading={isLoading} isFetching={isFetching} error={error}>
      {/*
       * Don't display graph if:
       *  1. the first week assessments haven't been received
       *  2. pivot week cannot be found for the provided pivot date
       */}
      {snapshots.length >= 1 && lastSnapshotWeek !== null && academicTerm ? (
        <>
          {renderSnapshotsDatesRange(snapshots)}
          <AssessmentsGraph
            barsStacksData={barsStacksData}
            assessmentsGraphUi={assessmentsGraphUi}
          />
          <ActionsGroup align="center" className={styles.WeeklyOutreachTile__ReachOut}>
            <ActionButtonLink
              to={`/courses/${courseId}/outreach`}
              variant="primary"
              size="huge"
              weight="bold"
              onClick={onReachOutClick}
            >
              Reach out now
            </ActionButtonLink>
          </ActionsGroup>
        </>
      ) : (
        <TileParagraph>
          After the first week of the term, you’ll see a report here, helping identify students
          who most need to hear from you.
        </TileParagraph>
      )}
    </Tile>
  );
};

export default WeeklyOutreachTile;
