import { AppThunk } from '../../store';
import { updateSnapshotsRange } from '../../slices/risks_snapshots';
import { getAcademicTermFirstLastClassDates } from '../../services/academic_terms';
import { updatePivotDate } from '../../slices/application';
import { selectCourseAcademicTerm } from '../../selectors/course';
import { Course } from '../../../types';
import { selectCoursePivotDate } from '../../selectors/application';

export const assembleAcademicTermPivotDateThunk =
  (course: Course): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    let pivotDate = selectCoursePivotDate(state);

    const { academic_term } = course;
    const { firstClassDate, lastClassDate } =
      getAcademicTermFirstLastClassDates(academic_term);

    if (!pivotDate) {
      pivotDate = firstClassDate.toISOString();
    }

    dispatch(updatePivotDate({ pivotDate }));
    dispatch(
      updateSnapshotsRange({
        pivotDate,
        firstClassDate: firstClassDate.toISOString(),
        lastClassDate: lastClassDate.toISOString(),
      })
    );
  };

export const updatePivotDateThunk =
  ({ pivotDate }: { pivotDate: string }): AppThunk =>
  (dispatch, getState) => {
    dispatch(updatePivotDate({ pivotDate }));

    const state = getState();
    const academicTerm = selectCourseAcademicTerm(state);
    if (!academicTerm) return;

    const { firstClassDate, lastClassDate } = getAcademicTermFirstLastClassDates(academicTerm);
    dispatch(
      updateSnapshotsRange({
        pivotDate,
        firstClassDate: firstClassDate.toISOString(),
        lastClassDate: lastClassDate.toISOString(),
      })
    );
  };
