import { EntityState } from '@reduxjs/toolkit';
import {
  AcademicTerm,
  Course,
  CourseCompletionRates,
  Manifest,
  School,
  ShortCourse,
} from '../../../types';
import { todoBoardApi } from '../index';
import { API } from '../api_routes';
import { transformWebtextManifest } from './webtext_manifest_cache_helper';
import { Enrollment } from '../../../types/enrollment';
import { transformCourse } from './course_cache_helper';
import { transformCurrentUserCourses } from './current_user_courses_cache_helper';
import { transformEnrollments } from './enrollments_cache_helper';
import { assembleAcademicTermPivotDateThunk } from '../../thunks/application';

export const coursesApi = todoBoardApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUserCourses: build.query<EntityState<ShortCourse>, void>({
      query: () => API.CURRENT_USER_COURSES,
      transformResponse: transformCurrentUserCourses,
    }),
    getCourseById: build.query<Course, number>({
      query: (courseId) => API.COURSE(courseId),
      transformResponse: transformCourse,
      async onQueryStarted(courseId: number, { queryFulfilled, dispatch }): Promise<void> {
        const { data: course } = await queryFulfilled;
        dispatch(assembleAcademicTermPivotDateThunk(course));
      },
    }),
    getWebtextManifestByCourseId: build.query<Manifest, number>({
      query: (courseId) => ({
        url: API.WEBTEXT_MANIFEST,
        params: { course_id: courseId },
      }),
      transformResponse: transformWebtextManifest,
    }),
    getEnrollmentsByCourseId: build.query<EntityState<Enrollment>, number>({
      query: (courseId) => ({
        url: API.ENROLLMENTS,
        params: { course_id: courseId },
      }),
      transformResponse: transformEnrollments,
    }),
    getCompletionsByCourseId: build.query<CourseCompletionRates, number>({
      query: (courseId) => ({
        url: API.COMPLETION,
        params: { course_id: courseId },
      }),
    }),
    getSchoolById: build.query<School, number>({
      query: (schoolId) => ({
        url: API.SCHOOL(schoolId),
      }),
      transformResponse: (response: unknown) => (response as { school: School }).school,
    }),
    getAcademicTermById: build.query<AcademicTerm, number>({
      query: (academicTermId) => ({
        url: API.ACADEMIC_TERM(academicTermId),
      }),
      transformResponse: (response: unknown) =>
        (response as { academicTerm: AcademicTerm }).academicTerm,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserCoursesQuery,
  useGetCourseByIdQuery,
  useGetWebtextManifestByCourseIdQuery,
  useGetCompletionsByCourseIdQuery,
  useGetEnrollmentsByCourseIdQuery,
  useGetSchoolByIdQuery,
  useGetAcademicTermByIdQuery,
} = coursesApi;
