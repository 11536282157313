import { FC, useCallback, useMemo } from 'react';
import { AiFillPushpin, AiOutlinePushpin } from 'react-icons/ai';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import PivotDatePicker from './PivotDataPicker';
import { RiskCategoryKeys } from '../../types';
import {
  RiskThresholds,
  updateRiskCategoryThresholds,
} from '../../redux/slices/risks_snapshots';
import RisksThresholdsSliders from './RisksThresholdsSliders';
import ErrorMessage from '../ErrorMessage';
import { useCourse } from '../../redux/hooks/courses/useCourse';
import { useAppDispatch } from '../../redux/hooks/app/useAppDispatch';
import {
  AnswersStateThresholds,
  changeAnswersStateThresholds,
} from '../../redux/slices/answers';
import AnswersStateSliders from './AnswersStateSliders';
import { useAnswers } from '../../redux/hooks/answers/useAnswers';
import IconCheckbox from '../Inputs/IconCheckbox';
import { toggleUiAction } from '../../redux/slices/ui_actions';
import { resetNewWorkReaction } from '../../redux/slices/notifications';
import UiActionsToggleModal from './UiActionsToggleModal';
import { selectUiActionByKey } from '../../redux/selectors/ui_actions';
import MessagesResetModal from './MessagesResetModal';
import { selectCoursePivotDate } from '../../redux/selectors/application';
import { useCourseSnapshots } from '../../redux/hooks/risks_snapshots/useCourseSnapshots';
import { getAcademicTermTimespanDates } from '../../redux/services/academic_terms';
import { selectCourseAcademicTerm } from '../../redux/selectors/course';
import { updatePivotDateThunk } from '../../redux/thunks/application';
import ContentSkeleton from '../ContentSkeleton';
import Dimmer from '../Dimmer';

const ControlsBar: FC = () => {
  const dispatch = useAppDispatch();

  const pivotDate = useAppSelector(selectCoursePivotDate);
  const academicTerm = useAppSelector(selectCourseAcademicTerm);

  const risksThresholds = useAppSelector((state) => state.risksSnapshots.risksThresholds);
  const answersStateThresholds = useAppSelector(
    (state) => state.answers.answersStateThresholds
  );

  const { data: course } = useCourse();
  const {
    isLoading: isSnapshotsLoading,
    isUninitialized: isSnapshotsUninitialized,
    error: snapshotsError,
  } = useCourseSnapshots();
  const { isLoading: isAnswersLoading, isUninitialized: isAnswersUninitialized } =
    useAnswers();

  // const {
  //   availableCoursesManifest,
  //   isFetching: isProtoManifestFetching,
  //   error: protoManifestError,
  // } = useProtoManifest();

  const isControlsBarSticky = useAppSelector((state) =>
    selectUiActionByKey(state, 'CONTROLS_BAR__STICKY__CLICKED')
  );
  const handleStickyToggle = useCallback(
    () => dispatch(toggleUiAction('CONTROLS_BAR__STICKY__CLICKED')),
    [dispatch]
  );

  const handlePivotDateChange = useCallback(
    ({ newPivotDate }: { newPivotDate: Date }) => {
      dispatch(updatePivotDateThunk({ pivotDate: newPivotDate.toISOString() }));

      if (!course?.id) return;
      dispatch(resetNewWorkReaction({ courseId: course.id }));
    },
    [course?.id, dispatch]
  );

  const handleRiskThresholdsChange = useCallback(
    ({
      riskCategory,
      riskThresholds,
    }: {
      riskCategory: RiskCategoryKeys;
      riskThresholds: RiskThresholds;
    }) =>
      dispatch(
        updateRiskCategoryThresholds({
          riskCategory,
          riskThresholds,
        })
      ),
    [dispatch]
  );

  const handleAnswersThresholdsChange = useCallback(
    (thresholds: AnswersStateThresholds) => {
      dispatch(changeAnswersStateThresholds({ answersStateThresholds: thresholds }));

      if (!course) return;
      dispatch(resetNewWorkReaction({ courseId: course.id }));
    },
    [course, dispatch]
  );

  // const handleManifestChange = useCallback(
  //   ({ manifestUrl }: { manifestUrl: string }) => {
  //     dispatch(changeManifestUrl({ manifestUrl }));
  //   },
  //   [dispatch]
  // );
  //
  // const handleCourseChange = useCallback(
  //   ({ courseId: newCourseId }: { courseId: number }) => {
  //     // TODO Move to thunk
  //     dispatch(changeCourseId({ courseId: newCourseId }));
  //     dispatch(coursesApi.util.resetApiState());
  //     dispatch(resetRisks());
  //     dispatch(risksSnapshotsApi.util.resetApiState());
  //     dispatch(resetAnswers());
  //     dispatch(answersApi.util.resetApiState());
  //   },
  //   [dispatch]
  // );

  /**
   * Course selection element should receive its data and render separately,
   * because if the error for other loaders appears, it still needs to be displayed
   */
  // const courseSelectionElements = useMemo(() => {
  //   const selectedCourse = !courseError && !categorizedSnapshotsError ? course : undefined;
  //   return (
  //     <div className={styles.ControlsBar__VerticalControls}>
  //       {availableCoursesManifest && (
  //         <CourseSelectionModal
  //           course={selectedCourse}
  //           protoManifest={availableCoursesManifest}
  //           onCourseChange={handleCourseChange}
  //         />
  //       )}
  //       <ManifestFormModal onManifestChange={handleManifestChange} />
  //     </div>
  //   );
  // }, [
  //   categorizedSnapshotsError,
  //   course,
  //   courseError,
  //   // handleCourseChange,
  //   // handleManifestChange,
  //   availableCoursesManifest,
  // ]);

  const controlsElements = useMemo(() => {
    if (isSnapshotsLoading || isAnswersLoading) {
      return <Dimmer loading />;
    }

    // TODO Not show error which covers the whole controls bar
    let errorMessage;
    if (snapshotsError) {
      errorMessage = 'Cannot load categorized snapshots!';
    }
    // if (protoManifestError) {
    //   errorMessage = 'Cannot load prototype manifest!';
    // }
    if (errorMessage) {
      return (
        <div>
          <ErrorMessage
            message={errorMessage || ''}
            className={styles.ControlsBar__ErrorMessage}
          />
          {/* {courseSelectionElements} */}
        </div>
      );
    }

    if (isAnswersUninitialized || isSnapshotsUninitialized) {
      return <ContentSkeleton />;
    }

    const { dates: academicTermTimespanDates } = academicTerm
      ? getAcademicTermTimespanDates(academicTerm)
      : { dates: [] };
    return (
      <form className={styles.ControlsBar__Form}>
        <PivotDatePicker
          pivotDate={new Date(pivotDate)}
          academicTermTimespanDates={academicTermTimespanDates}
          onPivotDataChange={handlePivotDateChange}
        />
        <RisksThresholdsSliders
          risksThresholds={risksThresholds}
          onRiskThresholdsChange={handleRiskThresholdsChange}
        />
        <div className={styles.ControlsBar__VerticalControls}>
          <AnswersStateSliders
            answersStateThresholds={answersStateThresholds}
            onAnswersThresholdsChange={handleAnswersThresholdsChange}
          />
          <div className={styles.ControlsBar__HorizontalControls}>
            <UiActionsToggleModal />
            <MessagesResetModal />
          </div>
        </div>
        {/* {courseSelectionElements} */}
      </form>
    );
  }, [
    academicTerm,
    answersStateThresholds,
    handleAnswersThresholdsChange,
    handlePivotDateChange,
    handleRiskThresholdsChange,
    isAnswersLoading,
    isAnswersUninitialized,
    isSnapshotsLoading,
    isSnapshotsUninitialized,
    pivotDate,
    risksThresholds,
    snapshotsError,
  ]);

  return (
    <div
      className={clsx(styles.ControlsBar, isControlsBarSticky && styles.ControlsBar_sticky)}
    >
      <div className={styles.ControlsBar__Title}>
        <IconCheckbox
          value={isControlsBarSticky}
          toggledIcon={<AiFillPushpin />}
          untoggledIcon={<AiOutlinePushpin />}
          onToggle={handleStickyToggle}
        />
        <span>TESTING CONTROLS</span>
      </div>
      {controlsElements}
    </div>
  );
};
export default ControlsBar;
