import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateCourseId } from '../application';

export interface NotificationsState {
  coursesIndex: NotificationsCoursesIndex;
}

export interface NotificationsCoursesIndex {
  [courseId: number]: {
    /**
     * Show notification if the current lastSnapshot
     * has different snapshot id and its week number is larger than the previous lastSnapshot
     * @see WeeklyOutreachReaction
     */
    reactedWeeklyOutreach?: WeeklyOutreachReaction;
    /**
     * Show notification if the application
     * has unseen answers, which are not listed in the reacted answers ids
     * @see NewWorkReaction
     */
    reactedNewWork?: NewWorkReaction;
  };
}

export interface WeeklyOutreachReaction {
  snapshotId: number;
  snapshotWeekNumber: number;
}

export interface NewWorkReaction {
  answersIds: number[];
}

const initialState = {
  coursesIndex: {},
} as NotificationsState;

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    /**
     * When the user just lands on the site for the first time
     * we can store current lastSnapshot as the reacted snapshot.
     * It will allow to not considered landed snapshot as the new one
     * and not show notification from the start of the experience.
     */
    saveInitialWeeklyOutreachReaction: (
      state,
      action: PayloadAction<{ courseId: number; reaction: WeeklyOutreachReaction }>
    ) => {
      const { courseId, reaction } = action.payload;
      const { coursesIndex } = state;
      const courseReactedWeeklyOutreach = coursesIndex[courseId].reactedWeeklyOutreach;
      coursesIndex[courseId].reactedWeeklyOutreach = courseReactedWeeklyOutreach || reaction;
    },
    saveWeeklyOutreachReaction: (
      state,
      action: PayloadAction<{ courseId: number; reaction: WeeklyOutreachReaction }>
    ) => {
      const { courseId, reaction } = action.payload;
      state.coursesIndex[courseId].reactedWeeklyOutreach = reaction;
    },
    resetWeeklyOutreachReaction: (state, action: PayloadAction<{ courseId: number }>) => {
      const { courseId } = action.payload;
      state.coursesIndex[courseId].reactedWeeklyOutreach = undefined;
    },
    saveNewWorkReaction: (
      state,
      action: PayloadAction<{ courseId: number; reaction: NewWorkReaction }>
    ) => {
      const { courseId, reaction } = action.payload;
      const newAnswersIds = reaction.answersIds;
      if (!newAnswersIds.length) return;

      const { coursesIndex } = state;
      const courseReactedNewWork = coursesIndex[courseId].reactedNewWork;
      const previousAnswersIds = courseReactedNewWork?.answersIds || [];
      const uniqueAnswersIds = [...new Set([...previousAnswersIds, ...newAnswersIds])];
      coursesIndex[courseId].reactedNewWork = { answersIds: uniqueAnswersIds };
    },
    resetNewWorkReaction: (state, action: PayloadAction<{ courseId: number }>) => {
      const { courseId } = action.payload;
      state.coursesIndex[courseId].reactedNewWork = undefined;
    },
    resetReactions: (state, action: PayloadAction<{ courseId: number }>) => {
      const { courseId } = action.payload;
      state.coursesIndex[courseId] = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCourseId, (state, action: PayloadAction<{ courseId: number }>) => {
      const { courseId } = action.payload;
      state.coursesIndex[courseId] = state.coursesIndex[courseId] || {};
    });
  },
});

export const {
  saveInitialWeeklyOutreachReaction,
  saveWeeklyOutreachReaction,
  resetWeeklyOutreachReaction,
  saveNewWorkReaction,
  resetNewWorkReaction,
  resetReactions,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
