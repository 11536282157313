import { QuestionsTypes } from './webtext_manifest';

export interface Answer {
  id: number;
  course_id: number;
  chapter_family_id: string;
  page_family_id: string;
  question_family_id: string;
  question_type: QuestionsTypes;
  created_at: string;
  updated_at: string | null;
  first_saved_at: string;
  last_saved_at: string;
  correct: boolean;
  completed: boolean;
  state: AnswerStateKeys;
  state_received_at: Date;
  user_id: number;
}

export enum AnswerState {
  'seen',
  'unseen',
  'reviewed',
}
export type AnswerStateKeys = keyof typeof AnswerState;
