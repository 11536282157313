import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'debounce';
import { BindingCallback1 } from '../../../types';
import { AnswersStateThresholds } from '../../../redux/slices/answers';
import LabeledSlider from '../../Inputs/LabeledSlider';
import SlidersGroup from '../../Inputs/SlidersGroup';
import styles from './styles.module.scss';

interface AnswersStateSlidersProps {
  answersStateThresholds: AnswersStateThresholds;
  onAnswersThresholdsChange: BindingCallback1<AnswersStateThresholds>;
}

const AnswersStateSliders: FC<AnswersStateSlidersProps> = ({
  answersStateThresholds,
  onAnswersThresholdsChange,
}) => {
  const [seenThreshold, setSeenThreshold] = useState(answersStateThresholds.seenInDays);
  const [reviewedThreshold, setReviewedThreshold] = useState(
    answersStateThresholds.reviewedInDays
  );

  useEffect(() => {
    if (answersStateThresholds.seenInDays) {
      setSeenThreshold(answersStateThresholds.seenInDays);
    }
  }, [answersStateThresholds.seenInDays]);

  useEffect(() => {
    if (answersStateThresholds.reviewedInDays) {
      setReviewedThreshold(answersStateThresholds.reviewedInDays);
    }
  }, [answersStateThresholds.reviewedInDays]);

  const debouncedOnChange = useMemo(
    () => debounce(onAnswersThresholdsChange, 300),
    [onAnswersThresholdsChange]
  );

  const handleSeenThresholdChange = useCallback(
    (newSeenThreshold: number) => {
      const synchronizedReviewedThreshold =
        newSeenThreshold > reviewedThreshold ? newSeenThreshold : reviewedThreshold;
      setSeenThreshold(newSeenThreshold);
      setReviewedThreshold(synchronizedReviewedThreshold);

      debouncedOnChange({
        seenInDays: newSeenThreshold,
        reviewedInDays: synchronizedReviewedThreshold,
      });
    },
    [debouncedOnChange, reviewedThreshold]
  );

  const handleReviewedThresholdChange = useCallback(
    (newReviewedThreshold: number) => {
      const synchronizedSeenThreshold =
        newReviewedThreshold < seenThreshold ? newReviewedThreshold : seenThreshold;
      setReviewedThreshold(newReviewedThreshold);
      setSeenThreshold(synchronizedSeenThreshold);

      debouncedOnChange({
        seenInDays: synchronizedSeenThreshold,
        reviewedInDays: newReviewedThreshold,
      });
    },
    [debouncedOnChange, seenThreshold]
  );

  const sliders = useMemo(
    () => [
      <LabeledSlider
        key="seen"
        label="seen:"
        unit="days"
        value={seenThreshold}
        min={0}
        max={40}
        step={1}
        explanation={(days) => `Answers will become seen in [${days}] days from the creation`}
        onChange={(value) => handleSeenThresholdChange(value)}
      />,
      <LabeledSlider
        key="reviewed"
        label="reviewed:"
        unit="days"
        value={reviewedThreshold}
        min={0}
        max={40}
        step={1}
        explanation={(days) =>
          `Answers will become reviewed in [${days}] days from the creation`
        }
        onChange={(value) => handleReviewedThresholdChange(value)}
      />,
    ],
    [
      handleReviewedThresholdChange,
      handleSeenThresholdChange,
      reviewedThreshold,
      seenThreshold,
    ]
  );

  return (
    <div className={styles.AnswersStateSliders}>
      <h4>Answers state thresholds</h4>
      <SlidersGroup sliders={sliders} />
    </div>
  );
};
export default AnswersStateSliders;
