import { extractUserQuestionCyoKey } from '../redux/services/answers';
import { API } from '../redux/queries/api_routes';
import { EnrollmentUser } from '../types/enrollment';
import { Page } from '../types';

export const generateA2RedirectionLink = ({
  apiHost,
  courseId,
  page,
  question,
  user,
}: {
  apiHost: string;
  courseId: number;
  page: Page;
  question: any;
  user: EnrollmentUser;
}): string => {
  const cyoKey = extractUserQuestionCyoKey(question, user);
  const questionId = cyoKey ? `${question.id}:${cyoKey}` : question.id;

  const a2AuthPath = API.ANALYTICS_JWT_AUTH(courseId);
  const a2Link = new URL(a2AuthPath, apiHost);
  const a2LinkParams = new URLSearchParams({
    scope: page.id,
    element: questionId,
  });
  a2Link.search = a2LinkParams.toString();

  return a2Link.toString();
};
