import { FC } from 'react';
import { GiHamburgerMenu } from 'react-icons/all';
import ActionButton from '../../Buttons/ActionButton';
import styles from './styles.module.scss';

const Menu: FC = () => {
  return (
    <ActionButton className={styles.Menu__Trigger}>
      <GiHamburgerMenu />
    </ActionButton>
  );
};

export default Menu;
