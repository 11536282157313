import { FC } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Tile from '../../../../components/Board/Tile';
import TileHeader from '../../../../components/Board/Tile/TileHeader';
import styles from './styles.module.scss';

const CorrectionNeededEmptyTile: FC = () => (
  <Tile className={styles.CorrectionNeededEmptyTile}>
    <TileHeader>
      All students are doing fine! No need for the course correction for now.
      <AiOutlineCheckCircle />
    </TileHeader>
  </Tile>
);

export default CorrectionNeededEmptyTile;
