import { FC } from 'react';
import clsx from 'clsx';
import Loader from 'react-loader-spinner';
import styles from './styles.module.scss';

interface DimmerProps {
  loading?: boolean;
  className?: string;
}

const Dimmer: FC<DimmerProps> = ({ loading, className, children }) => (
  <div className={clsx(className, styles.Dimmer)}>
    {loading ? <Loader type="Oval" /> : children}
  </div>
);

export default Dimmer;
