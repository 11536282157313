import { FC } from 'react';
import clsx from 'clsx';
import Tile, { TileProps } from '../Tile';
import styles from './styles.module.scss';

export type TilesProps = TileProps;

const Tiles: FC<TileProps> = ({
  isLoading,
  isFetching,
  error,
  errorClassName,
  className,
  children,
}) => {
  return (
    <Tile
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      errorClassName={errorClassName}
      className={clsx(
        className,
        !isLoading && isFetching && styles.Tiles_fetching,
        !isLoading && !isFetching && !error && styles.Tiles_wrapping
      )}
    >
      {children}
    </Tile>
  );
};

export default Tiles;
