import { FC, Fragment, ReactElement, useMemo, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { UsersMessages } from '../../../../../redux/selectors/messages';
import { Message } from '../../../../../types/message';
import { sortDatetimeDecrease } from '../../../../../utils/dates_comparer';
import ActionButton from '../../../../Buttons/ActionButton';
import { toTimeMonthDateYearString } from '../../../../../utils/timespans_formatter';
import { BindingCallback1 } from '../../../../../types';
import styles from './styles.module.scss';
import { EnrollmentUser } from '../../../../../types/enrollment';

interface MessagesTableProps {
  usersMessagesEntities: UsersMessages;
  usersEntities: Record<number, EnrollmentUser>;
  onResetClick: BindingCallback1<{ messageId: number }>;
}

const MessagesTable: FC<MessagesTableProps> = ({
  usersMessagesEntities,
  usersEntities,
  onResetClick,
}) => {
  const [openedUsersIds, setOpenedUsersIds] = useState<number[]>([]);

  const toggleOpenedMessages = (userId: number): void =>
    setOpenedUsersIds((prevOpenedIds) => {
      if (prevOpenedIds.includes(userId)) {
        return prevOpenedIds.filter((openedId) => openedId !== userId);
      }
      return [...prevOpenedIds, userId];
    });

  const usersMessagesSentAtDecrease = useMemo(
    () =>
      Object.entries(usersMessagesEntities).reduce((messagesDecrease, [userId, messages]) => {
        messagesDecrease[+userId] = [...messages].sort((messageA, messageB) =>
          sortDatetimeDecrease(messageA.sentAt, messageB.sentAt)
        );
        return messagesDecrease;
      }, {} as UsersMessages),
    [usersMessagesEntities]
  );

  const renderMessage = (message: Message): ReactElement => {
    const { id, subject, body, sentAt } = message;

    return (
      <div className={styles.MessagesTable__Message}>
        <div className={styles.MessagesTable__MessageText}>
          <p>
            <span>Subject:</span> {subject}
          </p>
          <p>
            <span>Body:</span> {body}
          </p>
        </div>
        <div className={styles.MessagesTable__MessageMeta}>
          <p>
            <span>ID: </span>
            {id}
          </p>
          <p>
            <span>Sent at:</span> {toTimeMonthDateYearString(sentAt)}
          </p>
        </div>
        <ActionButton className={styles.MessagesTable__MessageReset}>
          <BiTrash onClick={() => onResetClick({ messageId: id })} />
        </ActionButton>
      </div>
    );
  };

  const renderRow = (userId: number, messages: Message[]): ReactElement => {
    const [lastMessage, ...restMessages] = messages;

    const user = usersEntities[lastMessage.userId];
    const { first_name: userFirstName, last_name: userLastName } = user || {};

    return (
      <>
        <td>
          <h4>
            {userFirstName && userLastName
              ? `${userFirstName} ${userLastName}`
              : 'Unknown student'}
          </h4>
        </td>
        <td>
          <div className={styles.MessagesTable__Messages}>
            {renderMessage(lastMessage)}
            {openedUsersIds.includes(userId) && (
              <>
                {restMessages.map((message) => (
                  <Fragment key={message.id}>{renderMessage(message)}</Fragment>
                ))}
              </>
            )}
          </div>
          <div className={styles.MessagesTable__ShowToggle}>
            {restMessages.length > 0 && (
              <>
                {!openedUsersIds.includes(userId) ? (
                  <ActionButton onClick={() => toggleOpenedMessages(userId)}>
                    Show all
                  </ActionButton>
                ) : (
                  <ActionButton onClick={() => toggleOpenedMessages(userId)}>
                    Hide
                  </ActionButton>
                )}
              </>
            )}
          </div>
        </td>
      </>
    );
  };

  return (
    <table className={styles.MessagesTable}>
      <thead>
        <tr>
          <th>STUDENT</th>
          <th>MESSAGES</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(usersMessagesSentAtDecrease).map(([userId, messages]) => (
          <tr key={userId}>{renderRow(+userId, messages)}</tr>
        ))}
      </tbody>
    </table>
  );
};
export default MessagesTable;
