import { useMemo } from 'react';
import { Assessment, MeasurementDimensionKeys } from '../../../../../types';
import { AssessmentsColumns, DimensionsValuesIndex } from './AssessmentsTable';
import { selectUsersLastMessageEntities } from '../../../../../redux/selectors/messages';
import { useAppSelector } from '../../../../../redux/hooks/app/useAppSelector';
import { selectEnrollmentsEntities } from '../../../../../redux/selectors/course';

const measurementsDimensionsOrder: MeasurementDimensionKeys[] = [
  'completion-achieved',
  'score-achieved',
  'time-spent',
  'last-signin',
  'last-contact',
];

export const useAssessmentsTableData = (
  riskAssessments: Assessment[]
): AssessmentsColumns[] => {
  const usersLastMessageEntities = useAppSelector(selectUsersLastMessageEntities);
  const enrollmentsEntities = useAppSelector(selectEnrollmentsEntities);

  return useMemo(
    () =>
      riskAssessments.map((assessment) => {
        const { user, measurements } = assessment;
        const enrollmentUser = enrollmentsEntities[user.id]?.user;

        const dimensionsValues = measurementsDimensionsOrder.reduce(
          (valuesAcc, orderedDimension) => {
            const snapshotDimensionValue = measurements.find(
              (measurement) => measurement.dimension === orderedDimension
            )?.value;

            let liveDimensionValue;
            switch (orderedDimension) {
              case 'completion-achieved': {
                liveDimensionValue = enrollmentUser?.course_completion;
                break;
              }
              case 'score-achieved': {
                liveDimensionValue = enrollmentUser?.course_score;
                break;
              }
              case 'time-spent': {
                liveDimensionValue = enrollmentUser?.total_time_spent;
                break;
              }
              case 'last-signin': {
                liveDimensionValue = enrollmentUser?.last_signin;
                break;
              }
              case 'last-contact': {
                const userMessage = usersLastMessageEntities[user.id];
                liveDimensionValue = userMessage?.sentAt;
              }
            }

            valuesAcc[orderedDimension] = {
              snapshot: snapshotDimensionValue?.toString(),
              live: liveDimensionValue?.toString(),
            };
            return valuesAcc;
          },
          {} as DimensionsValuesIndex
        );
        return { user, dimensionsValues };
      }),
    [enrollmentsEntities, riskAssessments, usersLastMessageEntities]
  );
};
