import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// TODO Convert to the UI reducer

export enum UiActions {
  WELCOME_SECTION__DISMISSED = 'WELCOME_SECTION__DISMISSED',
  WEBTEXT_SECTION__ACCESSED = 'WEBTEXT_SECTION__ACCESSED',
  OUTREACH_SECTION__REACH_OUT__CLICKED = 'OUTREACH_SECTION__REACH_OUT__CLICKED',
  NEW_WORK_SECTION__ANSWERS__SEEN = 'NEW_WORK_SECTION__ANSWERS__SEEN',
  NEW_WORK_SECTION__REVIEW_WORKS__CLICKED = 'NEW_WORK_SECTION__REVIEW_WORKS__CLICKED',
  CONTROLS_BAR__STICKY__CLICKED = 'CONTROLS_BAR__STICKY__CLICKED',
}
export type UiActionsKeys = keyof typeof UiActions;

/**
 * Saves ui actions, which were triggered during the flow
 */
export type UiActionsState = Partial<Record<UiActionsKeys, boolean>>;

const initialState = {} as UiActionsState;

export const uiActionsSlice = createSlice({
  name: 'uiActions',
  initialState,
  reducers: {
    saveUiAction: (state, action: PayloadAction<UiActionsKeys>) => {
      const actionKey = action.payload;
      state[actionKey] = true;
    },
    toggleUiAction: (state, action: PayloadAction<UiActionsKeys>) => {
      const actionKey = action.payload;
      if (state[actionKey]) {
        delete state[actionKey];
      } else {
        state[actionKey] = true;
      }
    },
    removeUiAction: (state, action: PayloadAction<UiActionsKeys>) => {
      const actionKey = action.payload;
      delete state[actionKey];
    },
  },
});

export const { saveUiAction, toggleUiAction, removeUiAction } = uiActionsSlice.actions;
export default uiActionsSlice.reducer;
