import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ApplicationApi, ApplicationCoursesIndex } from '../../slices/application';

export const selectCourseId = (state: RootState): number => state.application.courseId;
export const selectUserId = (state: RootState): number => state.application.userId;
export const selectAccessToken = (state: RootState): string => state.application.accessToken;
export const selectApplicationApi = (state: RootState): ApplicationApi =>
  state.application.api;

export const selectCoursePivotDate = createSelector<
  RootState,
  number,
  ApplicationCoursesIndex,
  string
>(
  [selectCourseId, (state) => state.application.coursesIndex],
  (courseId, coursesIndex) => coursesIndex[courseId].pivotDate
);

export const selectCoursePivotDateObject = createSelector<RootState, string, Date>(
  [selectCoursePivotDate],
  (pivotDate) => new Date(pivotDate)
);
