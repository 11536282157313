import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useScroll, UseScrollProps } from '../../hooks/useScroll';
import Board from '../../components/Board';
import WelcomeSection from './WelcomeSection';
import WebtextSection from './WebtextSection';
import BoardHeader from '../../components/Board/BoardHeader';
import WeeklyOutreachSection from './WeeklyOutreachSection';
import NewWorkSection from './NewWorkSection';
import { BindingAction } from '../../types';

const scrollConfig: UseScrollProps = {
  scrollIntoViewOptions: { block: 'start' },
  offsetOptions: { yOffset: -60 },
};

enum DashboardRoute {
  weeklyOutreach = '#weekly-outreach',
  newWork = '#new-work',
}

const Dashboard: FC = () => {
  const [weeklyOutreachRef, scrollToWeeklyOutreach] = useScroll(scrollConfig);
  const [newWorksRef, scrollToNewWorks] = useScroll(scrollConfig);

  const { hash: locationHash } = useLocation();
  const history = useHistory();

  /**
   * Scroll smoothly when on the dashboard screen,
   * but show content immediately if we navigated from other page
   */
  useEffect(() => {
    if (!locationHash) return;

    let scroller: BindingAction | undefined;
    switch (locationHash) {
      case DashboardRoute.weeklyOutreach: {
        scroller = scrollToWeeklyOutreach;
        break;
      }
      case DashboardRoute.newWork: {
        scroller = scrollToNewWorks;
        break;
      }
    }
    history.replace({ hash: '' });
    if (!scroller) return;

    setTimeout(() => scroller?.(), 10);
  }, [history, locationHash, scrollToNewWorks, scrollToWeeklyOutreach]);

  return (
    <Board>
      <BoardHeader header="Dashboard" />
      <WelcomeSection />
      <WebtextSection />
      <WeeklyOutreachSection ref={weeklyOutreachRef} />
      <NewWorkSection ref={newWorksRef} />
    </Board>
  );
};

export default Dashboard;
