const isObject = (obj: unknown): boolean => typeof obj === 'object' && obj !== null;

const isDate = (obj: unknown): boolean =>
  Object.prototype.toString.call(obj) === '[object Date]';

const isRegex = (obj: unknown): boolean =>
  Object.prototype.toString.call(obj) === '[object RegExp]';

export const toCamelCase = (str: string): string =>
  // Explanation - https://stackoverflow.com/a/50907273/10963661
  str
    .toLowerCase()
    .trim()
    .split(/[.\-_\s]/g)
    .reduce((string, word) => string + word[0].toUpperCase() + word.slice(1));

export const camelizeObject = (json: unknown): unknown => {
  /**
   * Primitives plus objects, which cannot be camelized
   */
  if (!json || !isObject(json) || isDate(json) || isRegex(json)) {
    return json;
  }

  /**
   * Arrays
   */
  if (Array.isArray(json)) {
    return json.map((item: unknown) => camelizeObject(item));
  }

  return Object.entries(json as Record<string, unknown>).reduce(
    (accumulator, [key, value]) => {
      const newKey = key.includes('_') ? toCamelCase(key) : key;
      accumulator[newKey] = camelizeObject(value);
      return accumulator;
    },
    {} as Record<string, unknown>
  );
};
