import { createSelector, EntityId, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { RisksSnapshot } from '../../../types';
import {
  risksSnapshotsAdapter,
  risksSnapshotsInitialState,
} from '../../entities_adapters/risks_snapshots';
import { selectIndexedSnapshotsIdsInRange } from './index';
import { SnapshotsEntitiesState } from '../../slices/risks_snapshots';

export const selectSnapshotsData = (state: RootState): EntityState<RisksSnapshot> =>
  state.risksSnapshots.snapshotsEntities;

export const {
  selectAll: selectAllSnapshots,
  selectById: selectSnapshotById,
  selectIds: selectSnapshotsIds,
} = risksSnapshotsAdapter.getSelectors(
  (state: RootState) => selectSnapshotsData(state) || risksSnapshotsInitialState
);

export const selectSnapshotsInRange = createSelector<
  RootState,
  RisksSnapshot[],
  EntityId[],
  RisksSnapshot[]
>([selectAllSnapshots, selectIndexedSnapshotsIdsInRange], (snapshots, snapshotsInRangeIds) =>
  snapshots.filter((snapshot) => snapshotsInRangeIds.includes(snapshot.id))
);

export type SnapshotsRequestState = Pick<
  SnapshotsEntitiesState,
  'isLoading' | 'isFetching' | 'isUninitialized' | 'error'
>;

export const selectSnapshotsRequestState = createSelector<
  RootState,
  SnapshotsEntitiesState,
  SnapshotsRequestState
>([(state) => state.risksSnapshots.snapshotsEntities], (snapshotsEntities) => {
  const { isLoading, isFetching, isUninitialized, error } = snapshotsEntities;
  return {
    isLoading,
    isFetching,
    isUninitialized,
    error,
  };
});
