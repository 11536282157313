import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FiCheck, FiChevronRight } from 'react-icons/all';
import Section from '../../../Board/Section';
import { NotificationData } from '../index';
import { BindingAction } from '../../../../types';
import styles from './styles.module.scss';

interface NotificationProps {
  notificationData: NotificationData;
  onNotificationReset: BindingAction;
  showSeparator?: boolean;
}

const Notification: FC<NotificationProps> = ({
  notificationData,
  onNotificationReset,
  showSeparator = true,
}) => {
  const { link, title, message, isHighlighted, isAllResolved } = notificationData;

  const notificationTile = useMemo(() => {
    return (
      <div className={styles.Notification__Tile}>
        <div>
          <h3 className={styles.Notification__Header}>{title}</h3>
          <p className={styles.Notification__Message}>{message}</p>
        </div>
        {isAllResolved ? <FiCheck /> : <FiChevronRight />}
        {isHighlighted && <span className={styles.Notification__Badge} />}
      </div>
    );
  }, [isAllResolved, isHighlighted, message, title]);

  return (
    <Link to={link} className={styles.Notification__Link} onClick={onNotificationReset}>
      <Section
        tile={notificationTile}
        showSeparator={showSeparator}
        separatorClassName={styles.Notification__Separator}
      />
    </Link>
  );
};

export default Notification;
