import { FC } from 'react';
import Note from '../../../../components/Board/Note';
import NoteHeader from '../../../../components/Board/Note/NoteHeader';
import NoteParagraph from '../../../../components/Board/Note/NoteParagraph';

const WeeklyOutreachNote: FC = () => {
  return (
    <Note>
      <NoteHeader>Saving time</NoteHeader>
      <NoteParagraph>
        The Weekly Outreach report categorizes students who are demonstrating off-path activity
        patterns so you can easily message them quickly
      </NoteParagraph>
      <NoteParagraph>
        <strong>Reach Out Now</strong> to complete this task
      </NoteParagraph>
    </Note>
  );
};

export default WeeklyOutreachNote;
