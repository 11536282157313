import { FC } from 'react';
import Tile from '../../../../components/Board/Tile';
import TileParagraph from '../../../../components/Board/Tile/TileParagraph';
import styles from './styles.module.scss';

const WelcomeTile: FC = () => {
  return (
    <Tile>
      <TileParagraph>
        You have now left your LMS and entered the Soomo Learning Environment.
      </TileParagraph>
      <TileParagraph>
        This environment is optimized for faculty and students engaged in the task of formal
        coursework. For students, the content and interactions in their webtext replace their
        traditional textbook.
      </TileParagraph>
      <TileParagraph>
        For faculty, the environment helps by tracking all student activity, handling
        repetitive tasks (like grading multiple choice) and highlighting the things instructors
        are often most interested in like:
      </TileParagraph>
      <TileParagraph>
        For faculty, the environment helps by tracking all student activity, handling
        repetitive tasks (like grading multiple choice) and highlighting the things instructors
        are often most interested in like:
      </TileParagraph>
      <ul className={styles.WelcomeTile__Interests}>
        <li>
          who most needs my help right now? <strong>(Weekly Outreach)</strong>
        </li>
        <li>
          what’s new since I last logged in? <strong>(New Work)</strong>
        </li>
        <li>
          where are my students struggling <strong>(Frequently Missed Questions)</strong>
        </li>
      </ul>
    </Tile>
  );
};

export default WelcomeTile;
