import {
  AcademicTerm,
  AcademicTermDateLabel,
  AcademicTermDateLabelKeys,
} from '../../../types';
import {
  getDatesInRange,
  getWeeksBetweenDatesInclusively,
} from '../../../utils/dates_ranges_helper';

export const getAcademicTermDate = (
  academicTerm: AcademicTerm,
  datePoint: AcademicTermDateLabelKeys
): Date => {
  const { date_value } = academicTerm.dates.find(
    (date) => date.date_label === AcademicTermDateLabel[datePoint]
  )!;
  return new Date(date_value);
};

export const getAcademicTermFirstLastClassDates = (
  academicTerm: AcademicTerm
): {
  firstClassDate: Date;
  lastClassDate: Date;
} => {
  const firstClassDate = getAcademicTermDate(
    academicTerm,
    AcademicTermDateLabel.first_day_of_class
  );
  const lastClassDate = getAcademicTermDate(
    academicTerm,
    AcademicTermDateLabel.last_day_of_class
  );
  return {
    firstClassDate,
    lastClassDate,
  };
};

export const getAcademicTermClassWeeks = (academicTerm: AcademicTerm): number => {
  const { firstClassDate, lastClassDate } = getAcademicTermFirstLastClassDates(academicTerm);
  return getWeeksBetweenDatesInclusively(firstClassDate, lastClassDate);
};

export const getAcademicTermTimespanDates = (
  academicTerm: AcademicTerm
): {
  firstClassDate: Date;
  lastClassDate: Date;
  dates: Date[];
} => {
  const { firstClassDate, lastClassDate } = getAcademicTermFirstLastClassDates(academicTerm);
  const datesInRange = getDatesInRange(firstClassDate, lastClassDate);
  return {
    firstClassDate,
    lastClassDate,
    dates: datesInRange,
  };
};
