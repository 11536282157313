import { forwardRef, PropsWithChildren, ReactElement } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export interface SectionProps {
  header?: ReactElement;
  tile: ReactElement;
  note?: ReactElement;
  showSeparator?: boolean;
  className?: string;
  separatorClassName?: string;
}

const Section = forwardRef<HTMLElement, PropsWithChildren<SectionProps>>(
  ({ header, tile, note, showSeparator, className, separatorClassName }, ref) => {
    return (
      <section className={clsx(className, styles.Section)} ref={ref}>
        {header}
        <div className={clsx(styles.Section__Tile, note && styles.Section__Tile_withNote)}>
          {tile}
        </div>
        {note && <div className={clsx(styles.Section__Note)}>{note}</div>}
        {showSeparator && (
          <hr className={clsx(separatorClassName, styles.Section__Separator)} />
        )}
      </section>
    );
  }
);

export default Section;
