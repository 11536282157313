import { forwardRef, useMemo, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import NewWorkTiles from './NewWorkTiles';
import NewWorkNote from './NewWorkNote';
import { useAppSelector } from '../../../redux/hooks/app/useAppSelector';
import { selectUiActionByKey } from '../../../redux/selectors/ui_actions';
import { selectUnreviewedAnswers } from '../../../redux/selectors/answers';
import { selectLastSnapshotWeek } from '../../../redux/selectors/risks_snapshots';
import SectionHeader from '../../../components/Board/Section/SectionHeader';
import Section from '../../../components/Board/Section';
import { useAnswers } from '../../../redux/hooks/answers/useAnswers';

const NewWorkSection = forwardRef<HTMLElement>((_, ref) => {
  const [isHidden, setHidden] = useState(false);

  const lastSnapshotWeek = useAppSelector(selectLastSnapshotWeek);
  const isReviewWorksClicked = useAppSelector((state) =>
    selectUiActionByKey(state, 'NEW_WORK_SECTION__REVIEW_WORKS__CLICKED')
  );
  const unreviewedAnswers = useAppSelector(selectUnreviewedAnswers);

  const {
    isLoading: isAnswersLoading,
    isFetching: isAnswersFetching,
    isUninitialized: isAnswersUninitialized,
    error: answersError,
  } = useAnswers();

  const noteElement = useMemo(() => {
    return !isReviewWorksClicked && lastSnapshotWeek && unreviewedAnswers.length > 0 ? (
      <NewWorkNote />
    ) : undefined;
  }, [isReviewWorksClicked, lastSnapshotWeek, unreviewedAnswers.length]);

  return (
    <>
      {!isHidden && (
        <Section
          header={
            <SectionHeader onClose={answersError ? () => setHidden(true) : undefined}>
              New students&apos; work
            </SectionHeader>
          }
          tile={
            <NewWorkTiles
              isLoading={isAnswersLoading || isAnswersUninitialized}
              isFetching={isAnswersFetching}
              error={answersError}
            />
          }
          note={noteElement}
          ref={ref}
        />
      )}
    </>
  );
});

export default NewWorkSection;
