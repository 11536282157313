import log from 'loglevel';
import { Answer } from '../../../types';
import { AppThunk, RootState } from '../../store';
import { selectEnrollmentUserById, selectPageElementByFamilyId } from '../../selectors/course';
import {
  addAnswersToIndex,
  addAnswerToIndex,
  AnswersTableIndexPayload,
} from '../../slices/answers';

const assembleAnswersTableIndexPayload = (
  state: RootState,
  answer: Answer | undefined
): AnswersTableIndexPayload | null => {
  if (!answer) return null;

  const { question_family_id, user_id } = answer;
  const question = selectPageElementByFamilyId(state, question_family_id);
  const user = selectEnrollmentUserById(state, user_id);
  if (!question || !user) {
    log.warn(
      `No question ${question?.id} was found for the ${question_family_id} or 
      the user ${user?.id} for the ${user_id}`
    );

    return null;
  }
  return { answer, question, user };
};

export const assembleAnswersIndexThunk =
  (answers: (Answer | undefined)[]): AppThunk =>
  (dispatch, getState) => {
    const isTableIndexPayload = (
      payload: AnswersTableIndexPayload | null
    ): payload is AnswersTableIndexPayload => !!payload;

    const state = getState();
    const tableIndexPayloads = answers
      .map((answer) => assembleAnswersTableIndexPayload(state, answer))
      .filter(isTableIndexPayload);
    dispatch(addAnswersToIndex({ tableIndexPayloads }));
  };

export const assembleAnswerIndexThunk =
  (answer: Answer): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const tableIndexPayload = assembleAnswersTableIndexPayload(state, answer);
    if (tableIndexPayload) {
      dispatch(addAnswerToIndex({ tableIndexPayload }));
    }
  };
