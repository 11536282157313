import { EntityState } from '@reduxjs/toolkit';
import { todoBoardApi } from '../index';
import { API } from '../api_routes';
import { Answer } from '../../../types';
import { transformAnswer, transformAnswers, upsertAnswer } from './answers_cache_helper';
import { RootState } from '../../store';
import { assembleAnswersIndexThunk, assembleAnswerIndexThunk } from '../../thunks/answers';

export const answersApi = todoBoardApi.injectEndpoints({
  endpoints: (build) => ({
    getAnswersByCourseId: build.query<EntityState<Answer>, number>({
      query: (courseId) => API.ANSWERS(courseId),
      transformResponse: transformAnswers,
      async onQueryStarted(courseId: number, { queryFulfilled, dispatch }): Promise<void> {
        const { data: answers } = await queryFulfilled;
        dispatch(assembleAnswersIndexThunk(Object.values(answers.entities)));
      },
    }),
    getAnswerById: build.query<Answer, number>({
      query: (answerId) => API.ANSWER(answerId),
      transformResponse: transformAnswer,
      async onQueryStarted(
        answerId: number,
        { queryFulfilled, dispatch, getState }
      ): Promise<void> {
        const { data: answer } = await queryFulfilled;

        const state = getState() as unknown as RootState;
        const { courseId } = state.application;
        dispatch(upsertAnswer(courseId, answer));
        dispatch(assembleAnswerIndexThunk(answer));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnswersByCourseIdQuery, useLazyGetAnswerByIdQuery } = answersApi;
