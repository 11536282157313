import { FC, useCallback } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
  ContentType as GraphContentType,
  Props as LegendProps,
} from 'recharts/types/component/DefaultLegendContent';
import { Props as TooltipProps } from 'recharts/types/component/DefaultTooltipContent';
import styles from './styles.module.scss';
import { RiskCategoryKeys, WeeklySnapshot } from '../../../../../types';
import { formatDatesRange } from '../../../../../utils/timespans_formatter';
import { AssessmentsGraphUi } from '../../../../../redux/slices/risks_ui';
import { getDateInWeeks } from '../../../../../utils/dates_ranges_helper';
import { getDateWithoutTime } from '../../../../../utils/dates_comparer';
import { CategorizedRisksAssessmentsAmounts } from '../../../../../redux/slices/risks_snapshots';

interface AssessmentsGraphProps {
  barsStacksData: AssessmentsGraphBarsStack[];
  assessmentsGraphUi: AssessmentsGraphUi;
}

export type AssessmentsGraphBarsStack = WeeklySnapshot &
  Partial<CategorizedRisksAssessmentsAmounts>;

const AssessmentsGraph: FC<AssessmentsGraphProps> = ({
  barsStacksData,
  assessmentsGraphUi,
}) => {
  const renderTooltip = useCallback(
    (props: TooltipProps<string, string>): GraphContentType | null => {
      const { label: weekNumber, payload } = props;
      if (!payload || !payload.length) return null;

      // Don't react on the future bars academic term class bars
      if (payload.length === 1 && payload[0].dataKey === 'never-signed-in') return null;

      const { snapshotEndedAt } = payload[0].payload;
      const snapshotTakenAt = snapshotEndedAt
        ? getDateWithoutTime(snapshotEndedAt)
        : undefined;
      const dateWeekAgo = snapshotEndedAt ? getDateInWeeks(snapshotEndedAt, -1) : undefined;

      return (
        <div className={styles.AssessmentsGraph__Tooltip}>
          <span className={styles.AssessmentsGraph__TooltipWeek}> Week {weekNumber}</span>
          {dateWeekAgo && snapshotTakenAt && (
            <span className={styles.AssessmentsGraph__TooltipRange}>
              {formatDatesRange(dateWeekAgo, snapshotTakenAt)}
            </span>
          )}
          <ul className={styles.AssessmentsGraph__TooltipLegend}>
            {payload?.map((entry) => (
              <li key={entry.color} className={styles.AssessmentsGraph__TooltipLegendElement}>
                <span style={{ backgroundColor: entry.color }} />
                {assessmentsGraphUi[entry.dataKey as RiskCategoryKeys]?.label}&nbsp;{' '}
                {entry.value}
              </li>
            ))}
          </ul>
        </div>
      );
    },
    [assessmentsGraphUi]
  );

  const renderLegend = useCallback(
    (props: LegendProps): GraphContentType | null => {
      const { payload } = props;
      if (!payload || !payload.length) return null;
      return (
        <ul className={styles.AssessmentsGraph__Legend}>
          {payload?.map((entry) => (
            <li key={entry.value} className={styles.AssessmentsGraph__LegendElement}>
              <span style={{ backgroundColor: entry.color }} />
              {assessmentsGraphUi[entry.value as RiskCategoryKeys]?.label}
            </li>
          ))}
        </ul>
      );
    },
    [assessmentsGraphUi]
  );

  return (
    <ResponsiveContainer width="97%" height={500} debounce={1}>
      <BarChart
        data={barsStacksData}
        margin={{
          left: 5,
          top: 15,
          bottom: 15,
        }}
      >
        <Tooltip cursor={false} content={renderTooltip} />
        <XAxis
          dataKey="week"
          axisLine={false}
          tickLine={false}
          tickMargin={12}
          tick={{ fontSize: 14 }}
          tickFormatter={(value) => `Week ${value}`}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={12}
          tickCount={7}
          tick={{ fontSize: 14 }}
          domain={[0, 'dataMax']}
        />
        <Legend
          verticalAlign="bottom"
          content={renderLegend}
          wrapperStyle={{ bottom: '0px' }}
        />
        {Object.entries(assessmentsGraphUi).map(([uiKey, uiProps], index) => (
          <Bar
            key={uiKey}
            dataKey={uiKey}
            stackId="weekAssessments"
            fill={uiProps.color}
            radius={4}
            background={
              // bg color should be applied only to the first bar stack element
              // to be shown behind the uiKeys
              index === 0 && {
                fill: 'white',
              }
            }
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AssessmentsGraph;
