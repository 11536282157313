import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
// import localForage from 'localforage';
import storage from 'redux-persist/lib/storage';
import { todoBoardApi } from './queries';
import risksSnapshotsReducer from './slices/risks_snapshots';
import messagesReducer from './slices/messages';
import risksUiReducer from './slices/risks_ui';
import answersReducer from './slices/answers';
import uiActionsReducer from './slices/ui_actions';
import notificationsReducer from './slices/notifications';
import applicationReducer, {
  loadApplicationReducer,
  saveApplicationReducer,
} from './slices/application';
import { rtkQueryErrorLogger } from './middlewares/rtk_query_error_logger';
// import protoManifestsReducer from './slices/proto_manifests';
// import { prototypeApi } from './queries/prototype';

const reducers = combineReducers({
  application: applicationReducer,
  answers: answersReducer,
  risksSnapshots: risksSnapshotsReducer,
  risksUi: risksUiReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  uiActions: uiActionsReducer,
  [todoBoardApi.reducerPath]: todoBoardApi.reducer,
  // protoManifests: protoManifestsReducer,
  // [prototypeApi.reducerPath]: prototypeApi.reducer,
});

// localForage.config({
//   name: 'Todo Board storage',
//   storeName: 'todo_board',
//   version: 1.0,
// });

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    // storage: localForage,
    storage,
    whitelist: [
      'application',
      'messages',
      'notifications',
      'uiActions',
      // 'protoManifests',
    ],
    transforms: [saveApplicationReducer, loadApplicationReducer],
  },
  reducers
);

export const store = configureStore({
  reducer: persistedReducer,

  // Always use devTools for the TodoBoard to be able to track state bugs on staging
  // devTools: IS_DEVELOPMENT,
  devTools: true,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    todoBoardApi.middleware,
    // prototypeApi.middleware,
    rtkQueryErrorLogger,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback
// as the 2nd arg for customization
// setupListeners(store.dispatch)
