import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toCamelCase } from '../../../utils/objects_camelizer';
import { EnrollmentUser } from '../../../types/enrollment';
import { Answer, QuestionTypeConfigName } from '../../../types';

export interface AnswersState {
  answersStateThresholds: AnswersStateThresholds;
  indexedAnswersIds: number[];
  answersTableIndex: AnswersTableIndex;
}

/**
 * Contains depiction of the hierarchy for the answers in the new work table.
 * Also includes pages separation depending on the decisions made.
 * It will be populated on the new answers entries appearing.
 */
type DecisionKey = string | 'none';
type OptionKey = string | 'none';
export interface AnswersTableIndex {
  chapters: {
    [chapterFamilyId: string]: {
      familyId: string;
      pages: {
        [pageFamilyId: string]: {
          familyId: string;
          decisions: {
            [decisionKey: DecisionKey]: {
              decision: string;
              options: {
                [optionKey: OptionKey]: {
                  option: string;
                  questionsTypes: {
                    [questionTypeKey: string]: {
                      questionType: string;
                      questions: {
                        [questionFamilyId: string]: {
                          answersIds: number[];
                        };
                      };
                    };
                  };
                };
              };
            };
          };
        };
      };
    };
  };
}

export interface AnswersTableIndexPayload {
  answer: Answer;
  question: any;
  user: EnrollmentUser;
}

/**
 * Amount of days after creation of the answer to be considered as the seen or reviewed
 */
export type AnswersStateThresholds = {
  seenInDays: number;
  reviewedInDays: number;
};

const initialState = {
  indexedAnswersIds: [],
  answersTableIndex: { chapters: {} },
  answersStateThresholds: {
    seenInDays: 5,
    reviewedInDays: 7,
  },
} as AnswersState;

const addAnswerToIndexHandler = (
  state: AnswersState,
  answer: Answer,
  question: any,
  user: EnrollmentUser
): void => {
  const { answersTableIndex, indexedAnswersIds } = state;

  const {
    id: answerId,
    chapter_family_id,
    page_family_id,
    question_type,
    question_family_id,
  } = answer;

  const isAnswerIndexed = indexedAnswersIds.includes(answerId);
  const isQuestionTypeAllowed = QuestionTypeConfigName[question_type];
  if (isAnswerIndexed || !isQuestionTypeAllowed) return;

  const indexChapter = answersTableIndex.chapters[chapter_family_id] || {
    familyId: chapter_family_id,
    pages: {},
  };
  answersTableIndex.chapters[chapter_family_id] = indexChapter;

  const indexPage = indexChapter.pages[page_family_id] || {
    familyId: page_family_id,
    decisions: {},
  };
  answersTableIndex.chapters[chapter_family_id].pages[page_family_id] = indexPage;

  // We rely only on the first dependency of the element. Synthetic limitation
  const questionDependsOn = question.dependsOn?.[0];

  const decisionKey = toCamelCase(questionDependsOn || 'none');
  const indexDecision = indexPage.decisions[decisionKey] || {
    decision: decisionKey,
    options: {},
  };
  answersTableIndex.chapters[chapter_family_id].pages[page_family_id].decisions[decisionKey] =
    indexDecision;

  const userDecisionOption = user.decisions[decisionKey] || 'none';
  const indexDecisionOption = indexDecision.options[userDecisionOption] || {
    option: userDecisionOption,
    questionsTypes: {},
  };
  answersTableIndex.chapters[chapter_family_id].pages[page_family_id].decisions[
    decisionKey
  ].options[userDecisionOption] = indexDecisionOption;

  const indexQuestionType = indexDecisionOption.questionsTypes[question_type] || {
    question_type,
    questions: {},
  };
  answersTableIndex.chapters[chapter_family_id].pages[page_family_id].decisions[
    decisionKey
  ].options[userDecisionOption].questionsTypes[question_type] = indexQuestionType;

  const indexQuestion = indexQuestionType.questions[question_family_id] || { answersIds: [] };
  indexQuestion.answersIds.push(answerId);

  answersTableIndex.chapters[chapter_family_id].pages[page_family_id].decisions[
    decisionKey
  ].options[userDecisionOption].questionsTypes[question_type].questions[question_family_id] =
    indexQuestion;
};

export const answersSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addAnswersToIndex: (
      state,
      action: PayloadAction<{ tableIndexPayloads: AnswersTableIndexPayload[] }>
    ) => {
      action.payload.tableIndexPayloads.forEach((indexPayload) => {
        const { answer, question, user } = indexPayload;
        addAnswerToIndexHandler(state, answer, question, user);
      });
    },
    addAnswerToIndex: (
      state,
      action: PayloadAction<{ tableIndexPayload: AnswersTableIndexPayload }>
    ) => {
      const { answer, question, user } = action.payload.tableIndexPayload;
      addAnswerToIndexHandler(state, answer, question, user);
    },
    changeAnswersStateThresholds: (
      state,
      action: PayloadAction<{
        answersStateThresholds: AnswersStateThresholds;
      }>
    ) => {
      const { answersStateThresholds } = action.payload;
      state.answersStateThresholds = answersStateThresholds;
    },
  },
});

export const { addAnswersToIndex, addAnswerToIndex, changeAnswersStateThresholds } =
  answersSlice.actions;
export default answersSlice.reducer;
