import { createSlice } from '@reduxjs/toolkit';
import { RiskCategoryKeys } from '../../../types';

export type RiskUiState = {
  [riskCategory in RiskCategoryKeys]: RiskUi;
};

export interface RiskUi {
  assessmentsGraph?: AssessmentsGraphUiElements;
  outreachBoard?: OutreachBoardUiElements;
}

export interface SharedUiElements {
  color: string;
  label: string;
}

export type AssessmentsGraphUiElements = SharedUiElements;
export type AssessmentsGraphUi = {
  [riskCategory in RiskCategoryKeys]: AssessmentsGraphUiElements;
};

export interface OutreachBoardUiElements extends SharedUiElements {
  messageSubject: string;
  interventionLink: string;
}
export type OutreachBoardUi = {
  [riskCategory in RiskCategoryKeys]: OutreachBoardUiElements;
};

const initialState = {
  'on-track': {
    assessmentsGraph: {
      color: 'hsl(197, 83%, 45%)',
      label: 'On Track',
    },
  },
  'low-score': {
    assessmentsGraph: {
      color: 'hsl(176, 74%, 46%)',
      label: 'Low Score',
    },
    outreachBoard: {
      color: 'hsl(176, 74%, 46%)',
      label: 'have low score',
      messageSubject: 'You have a low score',
      interventionLink: 'https://www.soomolearning.com/',
    },
  },
  'low-completion': {
    assessmentsGraph: {
      color: 'hsl(47, 94%, 49%)',
      label: 'Low Completion',
    },
    outreachBoard: {
      color: 'hsl(47, 94%, 49%)',
      label: 'have low completion',
      messageSubject: 'You have a low completion rate',
      interventionLink: 'https://www.soomolearning.com/',
    },
  },
  'never-signed-in': {
    assessmentsGraph: {
      color: 'hsl(220, 7%, 92%)',
      label: 'Not Started',
    },
    outreachBoard: {
      color: 'hsl(220, 7%, 92%)',
      label: 'have yet to start',
      messageSubject: "You haven't started course",
      interventionLink: 'https://www.soomolearning.com/',
    },
  },
  'no-sign-in': {
    assessmentsGraph: {
      color: 'hsl(351, 96%, 59%)',
      label: 'Stopped',
    },
    outreachBoard: {
      color: 'hsl(351, 96%, 59%)',
      label: "haven't signed-in in a week or more",
      messageSubject: "You hasn't signed in in a week or more",
      interventionLink: 'https://www.soomolearning.com/',
    },
  },
} as RiskUiState;

export const risksUiSlice = createSlice({
  name: 'risksUi',
  initialState,
  reducers: {},
});

export default risksUiSlice.reducer;
