import { FC } from 'react';
import { Route } from 'react-router-dom';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { PublicRouteProps } from '../PublicRoute';
import TodoApp from '../../containers/TodoApp';

export type ApplicationRouteProps = PublicRouteProps;

// TODO Remove not protected application route
const ApplicationRoute: FC<ApplicationRouteProps> = ({
  component: Component,
  title,
  ...routeProps
}) => {
  useScrollToTop();
  return (
    <Route
      {...routeProps}
      render={(renderRouteProps) => {
        if (title) document.title = title;
        return (
          <TodoApp {...renderRouteProps}>
            <Component {...renderRouteProps} />
          </TodoApp>
        );
      }}
    />
  );
};

export default ApplicationRoute;
