import {
  Manifest as LibraryManifest,
  PageElement as LibraryPageElement,
  Page as LibraryPage,
  Chapter as LibraryChapter,
} from '@soomo/lib/types/WebtextManifest';

/**
 * TodoBoard specific Manifest type
 *
 * It's required to easily include additional data (e.g. human name)
 * into manifest elements on the query response transformation stage
 * (look for it in `webtext_manifest_cache_helper.ts`)
 */

export interface Manifest extends LibraryManifest {
  chapters: {
    [chapterId: string]: Chapter;
  };
  elements: {
    [elementId: string]: PageElement;
  };
  pages: {
    [pageId: string]: Page;
  };
}

export interface Chapter extends LibraryChapter {
  chapter_human_name: string;
}

export interface PageElement extends LibraryPageElement {
  human_name: string;
}

export interface Page extends LibraryPage {
  page_human_name: string;
  page_nav_label: string;
}

export enum PageElementNameType {
  'sa_question' = 'NG::Soomo::Question',
  'response_board' = 'NG::Soomo::QuestionSet',
  'mc_question' = 'NG::Soomo::MC::Question',
  'poll_question' = 'NG::Soomo::Poll::Question',
  'decision' = ' NG::Soomo::Decision',
  'interactive_template' = 'NG::Soomo::Builder',
  'self_assessment' = 'NG::Soomo::Popup::Quiz',
  'external_tool' = 'NG::Soomo::ExternalTool',
  'text' = 'NG::Soomo::Text',
  'figure' = 'NG::Soomo::Figure',
  'chart' = 'NG::Soomo::Chart',
  'artifact_link' = 'NG::Artifacts::Base',
  'nesting_instruction' = 'NG::Soomo::NestingInstruction',
  'question_deck' = 'NG::Soomo::QuestionDeck',
  'voice_recording' = 'NG::Soomo::VoiceRecording',
  'page_progress' = 'NG::Soomo::PageProgress',
}
export type PageElementsNames = keyof typeof PageElementNameType;

export enum PageElementTypeConfigName {
  'NG::Soomo::Text' = 'HTMLText',
  'NG::Soomo::MC::Question' = 'MCQuestion',
  'NG::Soomo::Figure' = 'Figure',
  'NG::Soomo::Poll::Question' = 'Poll',
  'NG::Soomo::Poll::Choice' = 'PollChoice',
  'NG::Soomo::Question' = 'SAQuestion',
  'NG::Soomo::QuestionSet' = 'YourTurn',
  'NG::Soomo::Builder' = 'Builder',
  'NG::Artifacts::Article' = 'Artifact',
  'NG::Artifacts::Audio' = 'Artifact',
  'NG::Artifacts::Video' = 'Artifact',
  'NG::Artifacts::Download' = 'Artifact',
  'NG::Artifacts::PdfDownload' = 'Artifact',
  'NG::Artifacts::DocxDownload' = 'Artifact',
  'NG::Soomo::Chart' = 'Chart',
  'NG::Soomo::Popup::Quiz' = 'PopupQuiz',
  'NG::Soomo::ExternalTool' = 'ExternalTool',
  'NG::Soomo::NestingInstruction' = 'NestingInstruction',
  'NG::Soomo::QuestionDeck' = 'QuestionDeck',
  'NG::Soomo::Decision' = 'Decision',
  'NG::Soomo::VoiceRecording' = 'VoiceRecording',
  'NG::Soomo::PageProgress' = 'PageProgress',
}
export type PageElementsTypes = keyof typeof PageElementTypeConfigName;

export enum QuestionTypeConfigName {
  // Considered as the reviewable answers for now
  'NG::Soomo::Question' = 'SAQuestion',
  'NG::Soomo::QuestionSet' = 'YourTurn',
  'NG::Soomo::Builder' = 'Builder',

  // 'NG::Soomo::MC::Question' = 'MCQuestion', We will present them in the future
  // 'NG::Soomo::Poll::Question' = 'Poll',
  // 'NG::Soomo::Popup::Quiz' = 'PopupQuiz',
  // 'NG::Soomo::Decision' = 'Decision',
  // 'NG::Soomo::VoiceRecording' = 'VoiceRecording', Possibly will be included
  // 'NG::Soomo::ExternalTool' = 'ExternalTool',
}
export type QuestionsTypes = keyof typeof QuestionTypeConfigName;
