import { Hooks } from 'react-table';
import { ReactElement } from 'react';
import TableSelectCheckbox from '../components/TableSelectCheckbox';

export const createSelectionColumn = ({
  hooks,
  toggledIcon,
  indeterminateIcon,
  untoggledIcon,
  className,
}: {
  hooks: Hooks<any>;
  toggledIcon: ReactElement;
  indeterminateIcon: ReactElement;
  untoggledIcon: ReactElement;
  className?: string;
}): void => {
  hooks.visibleColumns.push((otherColumns) => [
    {
      id: 'selection',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <TableSelectCheckbox
          {...getToggleAllRowsSelectedProps()}
          toggledIcon={toggledIcon}
          indeterminateIcon={indeterminateIcon}
          untoggledIcon={untoggledIcon}
          className={className}
        />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }: any) => (
        <TableSelectCheckbox
          {...row.getToggleRowSelectedProps()}
          toggledIcon={toggledIcon}
          indeterminateIcon={indeterminateIcon}
          untoggledIcon={untoggledIcon}
          className={className}
        />
      ),
    },
    ...otherColumns,
  ]);
};
