import { FC, useMemo } from 'react';
import pluralize from 'pluralize';
import RiskThresholdsSliders from './components/RiskThresholdsSliders';
import { RiskCategoryKeys, BindingCallback1, BindingFunction1 } from '../../../types';
import { RisksThresholds, RiskThresholds } from '../../../redux/slices/risks_snapshots';
import SlidersGroup from '../../Inputs/SlidersGroup';

interface RisksThresholdsSlidersProps {
  risksThresholds: RisksThresholds;
  onRiskThresholdsChange: BindingCallback1<{
    riskCategory: RiskCategoryKeys;
    riskThresholds: RiskThresholds;
  }>;
}

interface RisksThresholdsSlidersConfig {
  category: RiskCategoryKeys;
  unit?: string;
  min?: number;
  max?: number;
  step?: number;
  explanationLow?: BindingFunction1<number, string>;
  explanationHigh?: BindingFunction1<number, string>;
}

const risksThresholdsSlidersConfig: RisksThresholdsSlidersConfig[] = [
  {
    category: 'low-score',
    step: 0.01,
    unit: 'z-score',
    explanationLow: (zScore: number) =>
      `Low score cut off will be [${zScore}] standard deviation lower than class mean`,
  },
  {
    category: 'low-completion',
    step: 0.01,
    unit: 'z-score',
    explanationLow: (zScore: number) =>
      `Low completion cut off will be [${zScore}] standard deviation lower than class mean`,
  },
  {
    category: 'no-sign-in',
    min: 1,
    max: 40,
    step: 1,
    unit: 'days',
    explanationLow: (days: number) =>
      `User will be assigned with 'no-sign-in' risk after [${days}] after the last-sign-in date`,
  },
];
pluralize.addUncountableRule('z-score');

const RisksThresholdsSliders: FC<RisksThresholdsSlidersProps> = ({
  risksThresholds,
  onRiskThresholdsChange,
}) => {
  const sliders = useMemo(() => {
    return risksThresholdsSlidersConfig.map((sliderConfig) => {
      const { category, min, max, step, unit, explanationLow } = sliderConfig;
      const lowThreshold = risksThresholds[category]?.low;
      const highThreshold = risksThresholds[category]?.high;

      return (
        <RiskThresholdsSliders
          key={category}
          label={category}
          unit={unit}
          explanationLow={explanationLow}
          lowValue={lowThreshold}
          highValue={highThreshold}
          min={min}
          max={max}
          step={step}
          onChange={(riskThresholds) =>
            onRiskThresholdsChange({ riskCategory: category, riskThresholds })
          }
        />
      );
    });
  }, [onRiskThresholdsChange, risksThresholds]);

  return <SlidersGroup sliders={sliders} />;
};
export default RisksThresholdsSliders;
