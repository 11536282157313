import { EntityState, Update } from '@reduxjs/toolkit';
import { todoBoardApi } from '../index';
import { RisksSnapshotDatesIndex } from '../../../types';
import { API } from '../api_routes';
import { transformSnapshotsDatesIndices } from './snapshots_cache_helper';
import { RootState } from '../../store';
import { createWeeklySnapshotsIndex } from '../../services/risks_assessments';
import { risksSnapshotsDatesIndicesAdapter } from '../../entities_adapters/risks_snapshots';
import { selectCourseAcademicTerm } from '../../selectors/course';

export const risksSnapshotsApi = todoBoardApi.injectEndpoints({
  endpoints: (build) => ({
    getSnapshotsDatesIndicesByCourseId: build.query<
      EntityState<RisksSnapshotDatesIndex>,
      number
    >({
      query: (courseId) => API.RISKS_SNAPSHOTS_DATES_INDICES(courseId),
      transformResponse: transformSnapshotsDatesIndices,
      async onQueryStarted(
        courseId: number,
        { queryFulfilled, getState, updateCachedData }
      ): Promise<void> {
        const state = getState() as unknown as RootState;
        const academicTerm = selectCourseAcademicTerm(state);
        if (!academicTerm) return;

        const {
          data: { ids: snapshotsIds, entities: snapshotsIndicesEntities },
        } = await queryFulfilled;
        const snapshotsIndices = Object.values(snapshotsIndicesEntities).filter(
          (index): index is RisksSnapshotDatesIndex => !!index
        );

        const weeklySnapshotsIndex = createWeeklySnapshotsIndex(
          academicTerm,
          snapshotsIndices
        );

        /**
         * Update existing cache entries with the calculated week property
         */
        const weeklySnapshotsUpdate: Update<RisksSnapshotDatesIndex>[] = Object.values(
          weeklySnapshotsIndex
        ).map(({ id, week }) => ({ id, changes: { week } }));
        const uniqueWeeklySnapshotsIds = weeklySnapshotsUpdate.map(({ id }) => id);

        /**
         * We can have multiple snapshots which covers the same week.
         * In `createWeeklySnapshotsIndex` we create records only for the unique snapshots.
         *
         * All not unique snapshots ids should be removed from cache
         * to not load redundant snapshots objects
         */
        const notUniqueSnapshotsIds = snapshotsIds.filter(
          (snapshotId) => !uniqueWeeklySnapshotsIds.includes(snapshotId)
        );

        updateCachedData((draft) => {
          risksSnapshotsDatesIndicesAdapter.removeMany(draft, notUniqueSnapshotsIds);
          risksSnapshotsDatesIndicesAdapter.updateMany(draft, weeklySnapshotsUpdate);
        });
      },
    }),
  }),
  overrideExisting: false,
});

const { useGetSnapshotsDatesIndicesByCourseIdQuery } = risksSnapshotsApi;
export { useGetSnapshotsDatesIndicesByCourseIdQuery };
