import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  NewWorkReaction,
  NotificationsCoursesIndex,
  WeeklyOutreachReaction,
} from '../../slices/notifications';
import { selectCourseId } from '../application';

export const selectCourseWeeklyOutreachReaction = createSelector<
  RootState,
  number,
  NotificationsCoursesIndex,
  WeeklyOutreachReaction | undefined
>(
  [selectCourseId, (state) => state.notifications.coursesIndex],
  (courseId, coursesIndex) => coursesIndex[courseId].reactedWeeklyOutreach
);

export const selectCourseNewWorkReaction = createSelector<
  RootState,
  number,
  NotificationsCoursesIndex,
  NewWorkReaction | undefined
>(
  [selectCourseId, (state) => state.notifications.coursesIndex],
  (courseId, coursesIndex) => coursesIndex[courseId].reactedNewWork
);
