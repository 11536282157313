import { FC } from 'react';
import clsx from 'clsx';
import { FcTodoList } from 'react-icons/fc';
import styles from './styles.module.scss';
import { useAppSelector } from '../../redux/hooks/app/useAppSelector';
import { selectUiActionByKey } from '../../redux/selectors/ui_actions';
import { selectApplicationApi } from '../../redux/selectors/application';
import { IS_DEVELOPMENT } from '../../constants';
import Notifications from './Notifications';
import User from './User';
import Menu from './Menu';
import { useCourse } from '../../redux/hooks/courses/useCourse';

const Header: FC = () => {
  const { data: course } = useCourse();
  const courseName = course?.institutional_course?.name || course?.name;

  const appApi = useAppSelector(selectApplicationApi);
  const isControlsBarSticky = useAppSelector((state) =>
    selectUiActionByKey(state, 'CONTROLS_BAR__STICKY__CLICKED')
  );

  return (
    <header className={clsx(styles.Header, isControlsBarSticky && styles.Header_withControls)}>
      <div className={styles.Header__LabelContainer}>
        <FcTodoList className={styles.Header__Icon} />
        <span className={styles.Header__Label}>Todo Board</span>
        <span className={styles.Header__Slash}>/</span>
        <a
          href={`${appApi.host}/courses/${course?.id}`}
          target="_blank"
          className={styles.Header__CourseName}
          rel="noreferrer"
        >
          {courseName}
        </a>
        {course?.number && (
          <span className={styles.Header__CourseNumber}>({course.number})</span>
        )}
        {IS_DEVELOPMENT && (
          <span className={styles.Header__DevInfo}>
            {appApi.host} - {course?.id}
          </span>
        )}
      </div>
      <div className={styles.Header__ControlsContainer}>
        <Notifications />
        <User />
        <Menu />
      </div>
    </header>
  );
};

export default Header;
