import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { UiActionsKeys } from '../../slices/ui_actions';

export const selectUiActionByKey = createSelector<
  RootState,
  UiActionsKeys,
  Partial<Record<UiActionsKeys, boolean>>,
  UiActionsKeys,
  boolean
>(
  [(state) => state.uiActions, (state, actionKey) => actionKey],
  (uiActions, actionKey) => uiActions[actionKey] ?? false
);
