import { FC } from 'react';
import Note from '../../../../components/Board/Note';
import NoteHeader from '../../../../components/Board/Note/NoteHeader';
import NoteParagraph from '../../../../components/Board/Note/NoteParagraph';

const WebtextNote: FC = () => (
  <Note>
    <NoteHeader>Access your webtext</NoteHeader>
    <NoteParagraph>
      For students, the Soomo Learning Environment will at rst seem like an online textbook.
      You can see all the content they’re seeing at any time by clicking on the Table of
      Contents icon in the upper left.
    </NoteParagraph>
    <NoteParagraph>
      <strong>Click in there now to complete this task.</strong>
    </NoteParagraph>
  </Note>
);

export default WebtextNote;
