import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AssessmentsGraphUi,
  AssessmentsGraphUiElements,
  RiskUi,
  RiskUiState,
} from '../../slices/risks_ui';
import { RiskCategoryKeys } from '../../../types';
import { selectUiByRisk } from './select_ui_by_risk';

export const selectAssessmentsGraphUi = createSelector<
  RootState,
  RiskUiState,
  AssessmentsGraphUi
>([(state) => state.risksUi], (risksUi) =>
  Object.entries(risksUi).reduce((assessmentsUi, [riskCategory, riskElements]) => {
    const { assessmentsGraph: assessmentsGraphElements } = riskElements;
    if (!assessmentsGraphElements) return assessmentsUi;

    assessmentsUi[riskCategory as RiskCategoryKeys] = assessmentsGraphElements;
    return assessmentsUi;
  }, {} as AssessmentsGraphUi)
);

export const selectAssessmentsGraphUiByRisk = createSelector<
  RootState,
  RiskCategoryKeys,
  RiskUi,
  AssessmentsGraphUiElements | undefined
>([selectUiByRisk], ({ assessmentsGraph }) => assessmentsGraph);

export const makeSelectAssessmentsGraphUiByRisk = () => selectAssessmentsGraphUiByRisk;
