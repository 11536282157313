import { TocConfig } from '@soomo/lib/types/WebtextManifest';
import {
  Manifest,
  PageElementNameType,
  PageElementsNames,
  PageElementsTypes,
  PageElementTypeConfigName,
} from '../../../types';

const getChapterHumanName = (config: TocConfig, chapterType: string): string => {
  const defaultChapterName = config.chapters.human_name;
  const chapterTypeHumanName = config.chapters.types[chapterType]?.human_name;
  return chapterTypeHumanName || defaultChapterName;
};

const getPageHumanName = (config: TocConfig, pageType: string): string => {
  const defaultChapterName = 'Page';
  const chapterTypeHumanName = config.pages.types[pageType]?.human_name;
  return chapterTypeHumanName || defaultChapterName;
};

const getPageNavLabel = (config: TocConfig, pageType: string): string => {
  let label = (config.pages.types[pageType] || {}).nav_bar_label;
  if (!label && pageType === 'investigation') {
    label = config.assignments.human_name;
  }
  return label;
};

export const getElementHumanName = (
  config: TocConfig,
  pageType: string,
  elementType: string
): string => {
  const elementNgType = PageElementNameType[elementType as PageElementsNames];
  const elementConfigName = PageElementTypeConfigName[elementNgType as PageElementsTypes];

  const pageElementHumanName =
    config.pages.types[pageType]?.elements[elementConfigName]?.human_name;
  const elementHumanName = config.elements[elementConfigName]?.human_name;
  return pageElementHumanName || elementHumanName;
};

export const transformWebtextManifest = (response: unknown): Manifest => {
  const manifest = response as Manifest;

  const { toc, chapters, pages, elements } = manifest;
  const tocConfig = manifest.toc.config;

  toc.chapter_ids.forEach((chapterId) => {
    const chapter = chapters[chapterId];
    const { chapter_type } = chapter;
    chapter.chapter_human_name = getChapterHumanName(tocConfig, chapter_type);

    chapter.page_ids.forEach((pageId) => {
      const page = pages[pageId];
      const { page_type } = page;
      page.page_human_name = getPageHumanName(tocConfig, page_type);
      page.page_nav_label = getPageNavLabel(tocConfig, page_type);
      page.element_ids.forEach((elementId) => {
        const element = elements[elementId];
        const { type: elementType } = element;
        element.human_name = getElementHumanName(tocConfig, page_type, elementType);
      });
    });
  });

  return manifest;
};
