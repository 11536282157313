import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Message } from '../../../types/message';
import { messagesAdapter, messagesInitialState } from '../../entities_adapters/messages';
import { updateCourseId } from '../application';

export interface MessagesState {
  coursesIndex: MessagesCoursesIndex;
}

export interface MessagesCoursesIndex {
  [courseId: number]: {
    messagesEntities: EntityState<Message>;
  };
}

const initialState = {
  coursesIndex: {},
} as MessagesState;

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    saveMessage: (
      state,
      action: PayloadAction<{ courseId: number; message: Message | Omit<Message, 'id'> }>
    ) => {
      const { courseId, message } = action.payload;
      const { coursesIndex } = state;

      const { messagesEntities } = coursesIndex[courseId];
      const messagesIds = messagesEntities.ids.map((messageId) => +messageId);

      let messageId;
      if ('id' in message) {
        messageId = message.id;
      } else {
        messageId = messagesIds.length ? Math.max(...messagesIds) + 1 : 0;
      }
      const newMessage = { ...message, id: messageId };
      messagesAdapter.setOne(messagesEntities, newMessage);
    },
    removeMessage: (state, action: PayloadAction<{ courseId: number; messageId: number }>) => {
      const { courseId, messageId } = action.payload;
      const { coursesIndex } = state;
      const { messagesEntities } = coursesIndex[courseId];
      messagesAdapter.removeOne(messagesEntities, messageId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCourseId, (state, action: PayloadAction<{ courseId: number }>) => {
      const { courseId } = action.payload;
      state.coursesIndex[courseId] = state.coursesIndex[courseId] || {
        messagesEntities: messagesInitialState,
      };
    });
  },
});

export const { saveMessage, removeMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
