import { FC } from 'react';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import OutreachBoard from '../pages/OutreachBoard';
import JwtAuthGateway from '../pages/JwtAuthGateway';
import ProtectedApplicationRoute from './ProtectedApplicationRoute';
import Error from '../pages/Error';
import Dashboard from '../pages/Dashboard';

const ROUTE_POSTFIX = '| Soomo';

const Routing: FC = () => (
  <Switch>
    <PublicRoute
      path="/courses/:courseId/authentication"
      title={`Looking for the course ${ROUTE_POSTFIX}`}
      component={JwtAuthGateway}
    />
    <ProtectedApplicationRoute
      path="/courses/:courseId/outreach"
      title={`Outreach students ${ROUTE_POSTFIX}`}
      component={OutreachBoard}
    />
    <ProtectedApplicationRoute
      path="/courses/:courseId"
      title={`Todo Board ${ROUTE_POSTFIX}`}
      component={Dashboard}
    />
    <PublicRoute
      exact
      path="/error/:errorType"
      title={`Token is expired ${ROUTE_POSTFIX}`}
      component={Error}
    />
    <Route path="/*">
      <Redirect to="/error/course-id-missing" />
    </Route>
  </Switch>
);

export const history = createBrowserHistory();

export default Routing;
