import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import { FetchBaseQueryError, SkipToken, skipToken } from '@reduxjs/toolkit/query';
import { RisksSnapshot } from '../../../types';
import { AppDispatch, RootState } from '../../store';
import { appendRequestedSnapshotsIds } from '../../slices/risks_snapshots';
import { baseQueryWithAuthCheck } from '../../queries';
import { API } from '../../queries/api_routes';
import { selectSnapshotsIndicesEntities } from '../../selectors/risks_snapshots';
import { transformSnapshot } from '../../queries/risks_snapshots/snapshots_cache_helper';
import { Enrollment } from '../../../types/enrollment';
import { selectEnrollmentsEntities } from '../../selectors/course';

const getNotRequestedSnapshotsIds = (state: RootState, snapshotsIds: number[]): number[] => {
  const {
    risksSnapshots: {
      snapshotsEntities: { requestedSnapshotsIds },
    },
  } = state;
  return snapshotsIds.filter((snapshotId) => !requestedSnapshotsIds.includes(snapshotId));
};

export const getCourseSnapshotsByIdsThunk = createAsyncThunk<
  { snapshots: RisksSnapshot[]; enrollments: Dictionary<Enrollment> },
  { courseId: number; snapshotsIds: number[] } | SkipToken,
  { dispatch: AppDispatch; state: RootState; rejectValue: FetchBaseQueryError }
>(
  'riskSnapshots/fetchSnapshotsByIds',
  async (args, thunkAPI) => {
    if (args === skipToken) return { snapshots: [], enrollments: {} };

    const { dispatch, getState, rejectWithValue } = thunkAPI;
    const state = getState();

    const { courseId, snapshotsIds } = args;
    const notRequestedSnapshotsIds = getNotRequestedSnapshotsIds(state, snapshotsIds);

    dispatch(appendRequestedSnapshotsIds({ snapshotsIds: notRequestedSnapshotsIds }));
    const requests = notRequestedSnapshotsIds.map((snapshotId) =>
      baseQueryWithAuthCheck({ url: API.RISKS_SNAPSHOT(courseId, snapshotId) }, thunkAPI, {})
    );
    const results = await Promise.all(requests);

    const errors = results.filter((result) => !!result.error).map((result) => result.error);
    if (errors.length && errors[0]) throw rejectWithValue(errors[0]);

    const snapshotsIndicesEntities = selectSnapshotsIndicesEntities(state);
    const enrollmentsEntities = selectEnrollmentsEntities(state);

    const snapshots = results
      .map((result) => result.data)
      .map(transformSnapshot)
      .map((snapshot) => ({
        ...snapshot,
        week: snapshotsIndicesEntities[snapshot.id]?.week,
      })) as RisksSnapshot[];

    return { snapshots, enrollments: enrollmentsEntities };
  },
  {
    condition: (args, { getState }) => {
      if (args === skipToken) return false;

      const { snapshotsIds } = args;

      const state = getState();
      const {
        risksSnapshots: {
          snapshotsEntities: { isUninitialized },
        },
      } = state;

      /**
       * On the first week the array of required snapshots is empty,
       * so we can just initialize a query and it won't be an issue
       */
      if (isUninitialized && !snapshotsIds.length) return true;

      const notRequestedSnapshotsIds = getNotRequestedSnapshotsIds(state, snapshotsIds);
      return notRequestedSnapshotsIds.length > 0;
    },
  }
);
