import { Row } from 'react-table';
import { CellWithRowSpan, ColumnInstanceWithRowSpan } from '../../../types/react-table-config';

export const prepareSpannedRowCells = <
  D extends Record<string, unknown> = Record<string, unknown>
>(
  rows: Row<D>[],
  row: Row<D>
): void => {
  /*
   * This is to prevent spanning children cells across different parents,
   * for hierarchical data: https://cutt.ly/nQ2HWWc
   * Comment out assigment if parents should be distinguished
   */
  let parentBoundary: boolean;
  // eslint-disable-next-line prefer-const
  parentBoundary = false;

  // "Merge Cells" functionality - combine equal cells that are next to each-other.
  const allCells = row.allCells as CellWithRowSpan<D>[];
  allCells.forEach((cell, cellIndex) => {
    const column = cell.column as ColumnInstanceWithRowSpan<D>;

    // rowspan not enabled for this column, no extra actions required.
    if (!column.enableRowSpan) return;
    if (
      column.topCellValue !== cell.value || // we have a non-duplicate cell
      cell.value === '' || // or we have a blank cell
      parentBoundary || // or we crossed a parent boundary on this row already
      column.topCellValue === null // or we are on the first row
    ) {
      const rowIndex = rows.findIndex((savedRow) => savedRow.id === row.id);

      parentBoundary = true;

      // cell should not be rowspanned.
      column.topCellValue = cell.value;
      column.topCellIndex = rowIndex;
      cell.spannedRows = [row];
      cell.rowSpan = 1;
      cell.isRowSpanned = false; // render this cell as unique
    } else {
      // cell is rowspanned.
      cell.isRowSpanned = true; // skip rendering this cell

      // update the top cell
      const topIndexRow = rows[column.topCellIndex];
      const topIndexCells = topIndexRow.allCells as CellWithRowSpan<D>[];
      topIndexCells[cellIndex].rowSpan += 1;
      topIndexCells[cellIndex].spannedRows.push(row);
    }
  });
};
