import { forwardRef } from 'react';
import Section from '../../../components/Board/Section';
import SectionHeader from '../../../components/Board/Section/SectionHeader';
import CorrectionNeededTiles from './CorrectionNeededTiles';
import { useCourseSnapshots } from '../../../redux/hooks/risks_snapshots/useCourseSnapshots';

const CorrectionNeededSection = forwardRef<HTMLElement>((_, ref) => {
  const {
    isLoading: isSnapshotsLoading,
    isFetching: isSnapshotsFetching,
    isUninitialized: isSnapshotsUninitialized,
    error: snapshotsError,
  } = useCourseSnapshots();

  return (
    <Section
      header={<SectionHeader>Course Correction Needed</SectionHeader>}
      tile={
        <CorrectionNeededTiles
          isLoading={isSnapshotsLoading || isSnapshotsUninitialized}
          isFetching={isSnapshotsFetching}
          error={snapshotsError}
        />
      }
      ref={ref}
    />
  );
});

export default CorrectionNeededSection;
