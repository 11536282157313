import { FC, useMemo } from 'react';
import Tiles from '../../../../components/Board/Tiles';
import CorrectionNeededEmptyTile from '../CorrectionNeededEmptyTile';
import { useAppSelector } from '../../../../redux/hooks/app/useAppSelector';
import { selectLastSnapshotCategorizationIndex } from '../../../../redux/selectors/risks_snapshots';
import { selectRisksUi } from '../../../../redux/selectors/risks_ui';
import { orderedRiskCategories } from '../../../../types';
import CorrectionNeededRiskTile from './CorrectionNeededRiskTile';
import { TileLoadingProps } from '../../../../components/Board/Tile';

type CorrectionNeededTilesProps = TileLoadingProps;

const CorrectionNeededTiles: FC<CorrectionNeededTilesProps> = ({
  isLoading,
  isFetching,
  error,
}) => {
  const lastSnapshotCategorizationIndex = useAppSelector(
    selectLastSnapshotCategorizationIndex
  );
  const risksUi = useAppSelector(selectRisksUi);

  const risksTiles = useMemo(() => {
    if (!lastSnapshotCategorizationIndex) return null;

    /*
     * Don't display categories, which are:
     * 1. Not a risk category ('on-track' excluded)
     * 2. Not specified in the UI config
     * 3. Don't have assessments assigned
     */
    const risksCategories = Object.values(
      lastSnapshotCategorizationIndex.categorizedAssessments
    )
      .filter(
        ({ riskCategory, assessmentsUsersIds }) =>
          riskCategory !== 'on-track' &&
          risksUi[riskCategory] &&
          assessmentsUsersIds.length > 0
      )
      .sort(
        ({ riskCategory: riskCategoryA }, { riskCategory: riskCategoryB }) =>
          orderedRiskCategories.indexOf(riskCategoryA) -
          orderedRiskCategories.indexOf(riskCategoryB)
      )
      .map(({ riskCategory }) => riskCategory);

    return (
      <>
        {risksCategories.map((riskCategory) => (
          <CorrectionNeededRiskTile key={riskCategory} riskCategory={riskCategory} />
        ))}
      </>
    );
  }, [lastSnapshotCategorizationIndex, risksUi]);

  return (
    <Tiles isLoading={isLoading} isFetching={isFetching} error={error}>
      {!lastSnapshotCategorizationIndex?.offTrackAssessmentsAmount ? (
        <CorrectionNeededEmptyTile />
      ) : (
        risksTiles
      )}
    </Tiles>
  );
};

export default CorrectionNeededTiles;
