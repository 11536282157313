import { WeeklySnapshot } from '../../../types';
import { isDateLessEqual, StringDate } from '../../../utils/dates_comparer';
import {
  getDateInDays,
  getDateInWeeks,
  getDatesInRange,
} from '../../../utils/dates_ranges_helper';

export const getSnapshotsTimespanDates = (
  snapshots: WeeklySnapshot[]
): {
  startedAt?: Date;
  endedAt?: Date;
  dates: Date[];
} => {
  if (!snapshots.length) {
    return {
      startedAt: undefined,
      endedAt: undefined,
      dates: [],
    };
  }

  const firstSnapshot = snapshots[0];
  const lastSnapshot = snapshots[snapshots.length - 1];

  const startedAt = getDateInWeeks(firstSnapshot?.snapshot_ended_at, -1); // Date in the start of the first weekly term
  const endedAt = getDateInDays(lastSnapshot?.snapshot_ended_at, 6); // Date in the end of the week when the last snapshot receival was done

  const startedAtDate = startedAt ? new Date(startedAt) : undefined;
  const endedAtDate = endedAt ? new Date(endedAt) : undefined;
  const datesInRange =
    startedAtDate && endedAtDate ? getDatesInRange(startedAtDate, endedAtDate) : [];

  return {
    startedAt: startedAtDate,
    endedAt: endedAtDate,
    dates: datesInRange,
  };
};

/**
 * We consider only snapshots which were taken before pivotDate.
 * Because other snapshots are not prepared yet and not ready to be processed.
 */
export const getLastSnapshotWeek = <T extends WeeklySnapshot>(
  snapshots: T[],
  pivotDate: StringDate
): number | null => {
  const snapshotsTakenBeforePivot = snapshots.filter((snapshot) =>
    isDateLessEqual(snapshot.snapshot_ended_at, pivotDate)
  );
  const lastEndedSnapshot = snapshotsTakenBeforePivot.pop();
  return !lastEndedSnapshot ? null : lastEndedSnapshot.week;
};
