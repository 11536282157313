import { getDaysAmountBetweenDates } from './dates_ranges_helper';

// MAKING THE ASSUMPTION THAT THE WEEKLY TERM WILL LAST FOR 7 DAYS
export const WEEK_DAYS_AMOUNT = 7;

export type StringDate = string | Date;

export const convertToDate = (date: StringDate): Date =>
  typeof date === 'string' ? new Date(date) : date;

export const getDateWithoutTime = (date: StringDate): Date => {
  const targetDate = convertToDate(date);
  return new Date(
    targetDate.getUTCFullYear(),
    targetDate.getUTCMonth(),
    targetDate.getUTCDate()
  );
};

export const isDatesEqual = (dateA: StringDate, dateB: StringDate): boolean =>
  getDateWithoutTime(dateA).getTime() === getDateWithoutTime(dateB).getTime();

export const isDateGreater = (dateA: StringDate, dateB: StringDate): boolean =>
  getDateWithoutTime(dateA) > getDateWithoutTime(dateB);

export const isDateGreaterEqual = (dateA: StringDate, dateB: StringDate): boolean =>
  getDateWithoutTime(dateA) >= getDateWithoutTime(dateB);

export const isDateLess = (dateA: StringDate, dateB: StringDate): boolean =>
  getDateWithoutTime(dateA) < getDateWithoutTime(dateB);

export const isDateLessEqual = (dateA: StringDate, dateB: StringDate): boolean =>
  getDateWithoutTime(dateA) <= getDateWithoutTime(dateB);

export const isDateMoreThanDays = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  days = 1
): boolean => getDaysAmountBetweenDates(targetDate, comparisonDate) > days;

export const isDateMoreEqualThanDays = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  days = 1
): boolean => getDaysAmountBetweenDates(targetDate, comparisonDate) >= days;

export const isDateLessThanDays = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  days = 1
): boolean => getDaysAmountBetweenDates(targetDate, comparisonDate) < days;

export const isDateLessEqualThanDays = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  days = 1
): boolean => getDaysAmountBetweenDates(targetDate, comparisonDate) <= days;

export const isDateMoreThanWeeks = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  weeks = 1
): boolean => isDateMoreThanDays(targetDate, comparisonDate, weeks * WEEK_DAYS_AMOUNT);

export const isDateMoreEqualThanWeeks = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  weeks = 1
): boolean => isDateMoreEqualThanDays(targetDate, comparisonDate, weeks * WEEK_DAYS_AMOUNT);

export const isDateLessThanWeeks = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  weeks = 1
): boolean => isDateLessThanDays(targetDate, comparisonDate, weeks * WEEK_DAYS_AMOUNT);

export const isDateLessEqualThanWeeks = (
  targetDate: StringDate,
  comparisonDate: StringDate,
  weeks = 1
): boolean => isDateLessEqualThanDays(targetDate, comparisonDate, weeks * WEEK_DAYS_AMOUNT);

export const sortDateIncrease = (dateA: StringDate, dateB: StringDate): number => {
  if (isDateGreater(dateA, dateB)) return 1;
  if (isDateLess(dateA, dateB)) return -1;
  return 0;
};

export const sortDateDecrease = (dateA: StringDate, dateB: StringDate): number => {
  if (isDateGreater(dateA, dateB)) return -1;
  if (isDateLess(dateA, dateB)) return 1;
  return 0;
};

export const sortDatetimeIncrease = (dateA: StringDate, dateB: StringDate): number => {
  if (dateA > dateB) return 1;
  if (dateA < dateB) return -1;
  return 0;
};

export const sortDatetimeDecrease = (dateA: StringDate, dateB: StringDate): number => {
  if (dateA > dateB) return -1;
  if (dateA < dateB) return 1;
  return 0;
};
